import React, { useState } from "react";
import { toast } from "react-toastify";
import { AddDriveImage, AddSafetyTalk } from "../../api/apirequest";

const AddSafetyTalkForm = ({ setOpen }) => {
  const details = JSON.parse(localStorage.getItem("user"));
  const [formData, setFormData] = useState({
    safetyTalkID: 0,
    safetyTalkDescription: "",
    safetyMedia: "",
    dateAdded: new Date().toISOString(),
    addedBy: details?.data?.userID,
    AddedByName: "",
    statusID: 1,
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle file input change and convert it to Base64 string
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          safetyMedia: reader.result, // Extract Base64 string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");
    try {
      // Step 1: Submit the image to the drive
      const imageResult = await AddDriveImage({
        base64Image: formData.safetyMedia,
        fileName: new Date().toISOString(), // Use ISO format for file name
      });

      if (imageResult.ViewLink) {
        
        const { FileId } = imageResult;

        
        const safetyTalkResult = await AddSafetyTalk({
          ...formData,
          safetyMedia: FileId, 
        });

        if (safetyTalkResult && safetyTalkResult.success) {
          toast.success(
            safetyTalkResult.message || "Safety Talk successfully created!"
          );
          setOpen(false);
        } else {
          toast.error(
            safetyTalkResult.message || "An error occurred during submission."
          );
        }
      } else {
        toast.error(
          imageResult.message || "Failed to upload the image to the drive."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      // toast.error("There was an error processing your request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2>Add New Safety Talk</h2>

      <div className="form-group">
        <label htmlFor="safetyTalkDescription">Safety Talk Description</label>
        <textarea
          id="safetyTalkDescription"
          name="safetyTalkDescription"
          value={formData.safetyTalkDescription}
          onChange={handleInputChange}
          required
          rows="4"
          placeholder="Enter Safety Talk Description"
        ></textarea>
      </div>

      <div className="form-group">
        <label htmlFor="safetyMedia">Safety Media (PDF)</label>
        <input
          type="file"
          id="safetyMedia"
          name="safetyMedia"
          accept="application/pdf"
          onChange={handleFileChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="dateAdded">Date Added</label>
        <input
          type="datetime-local"
          id="dateAdded"
          name="dateAdded"
          value={formData.dateAdded.slice(0, 16)}
          onChange={handleInputChange}
          required
        />
      </div>

      <button
        style={{ backgroundColor: loading ? "grey" : "" }}
        onClick={() => handleSubmit()}
        type="submit"
      >
        {loading ? "Add Safety Talk" : "submiting form"}
      </button>

      <style jsx>{`
        .container {
          width: 100%;
          max-width: 600px;
          margin: 2rem auto;
          padding: 2rem;
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }

        h2 {
          font-size: 1.5rem;
          text-align: center;
          margin-bottom: 1.5rem;
        }

        .form-group {
          margin-bottom: 1.5rem;
        }

        label {
          font-weight: 600;
          display: block;
          margin-bottom: 0.5rem;
        }

        input,
        textarea,
        select {
          width: 100%;
          padding: 0.8rem;
          font-size: 1rem;
          border: 1px solid #ddd;
          border-radius: 4px;
          box-sizing: border-box;
        }

        textarea {
          resize: vertical;
        }

        button {
          background-color: #3182ce;
          color: white;
          padding: 0.8rem 2rem;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 1.1rem;
          transition: background-color 0.2s ease;
        }

        button:hover {
          background-color: #2b6cb0;
        }

        button:active {
          background-color: #2c5282;
        }
      `}</style>
    </div>
  );
};

export default AddSafetyTalkForm;
