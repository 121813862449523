// Constants
const EARTH_RADIUS_KM = 6371; // Earth's radius in kilometers

// Convert degrees to radians
const toRadians = (degrees) => {
  return degrees * (Math.PI / 180);
};

// Calculate distance between two points using Haversine formula
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
           Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
           Math.sin(dLon / 2) * Math.sin(dLon / 2);
  
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = EARTH_RADIUS_KM * c;
  
  return distance;
};

// Check if current location is within radius of target
const isWithinLocation = async (targetLat, targetLong, radiusKm = 0.1) => {
  try {
    // Request location permission and get current position
    const position = await new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by this browser.'));
        return;
      }

      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      });
    });

    const { latitude, longitude } = position.coords;

     
    // Calculate distance between current position and target
    const distance = calculateDistance(latitude, longitude, targetLat, targetLong);
    
    // Check if within specified radius
    return {
      isWithin: distance <= radiusKm,
      distance: distance,
      currentLocation: {
        latitude,
        longitude
      }
    };
    
  } catch (error) {
    throw new Error(`Location check failed: ${error.message}`);
  }
};

// Example usage:
/*
try {
  const targetLocation = {
    latitude: -1.2921,    // Your target latitude
    longitude: 36.8219    // Your target longitude
  };
  
  const result = await isWithinLocation(
    targetLocation.latitude, 
    targetLocation.longitude,
    0.1  // 100 meters radius (0.1 km)
  );

  if (result.isWithin) {
    console.log(`Within location! Distance: ${result.distance.toFixed(3)} km`);
  } else {
    console.log(`Outside location. Distance: ${result.distance.toFixed(3)} km`);
  }
  
  console.log('Current location:', result.currentLocation);
  
} catch (error) {
  console.error('Error checking location:', error);
}
*/

export { calculateDistance, isWithinLocation };

