const apiUrl = "https://customer.crato.co.ke/api/";

const apiRequest = async (url, method, body = null, headers = {}) => {
  const response = await fetch(`${apiUrl}${url}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: body ? JSON.stringify(body) : null,
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "An error occurred");
  }

  return data;
};

const fetchWithCache = async (url, method, body = null, headers = {}) => {
  const cacheKey = url + JSON.stringify(body);
  let data = null;
  let isFetching = true;

  // Check for cached data
  const cachedData = localStorage.getItem(cacheKey);
  if (cachedData) {
    data = JSON.parse(cachedData);
    // Even if we have cached data, we still fetch new data, but return cached data immediately
    isFetching = true;
  }
  

  try {
    const result = await apiRequest(url, method, body, headers);
    localStorage.setItem(cacheKey, JSON.stringify(result));
    data = result;
  } catch (error) {
    console.error("Fetch error:", error);
    // If there's an error, we'll rely on cached data if available, or data will remain null
  } finally {
    isFetching = false;
  } 

  return { data, isFetching };
};

// Function to GET data
export const getUsers = async () => {
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Users/GetSystemUsers`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getVehicles = async () => {
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Maintenence/GetVehicles`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getVehicleChecklist = async (startDate, enddate, vehicleID) => {
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Maintenence/GetChecklist?startDate=${startDate}&endDate=${enddate}&vehicleID=${vehicleID}`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getChecklistItems = async () => {
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Maintenence/GetCheckItem`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getVehicleRepairs = async (values) => {
  const { vehicleID, assignedTo, startdate, enddate } = values;
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Maintenence/GetRepairs?vehicleID=${vehicleID}&assignedTo=${assignedTo}&startDate=${startdate}&endDate=${enddate}`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getCheckListReport = async (startdate, enddate) => {
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Maintenence/GetDailyVehicleChecklist?StartDate=${startdate}&EndDate=${enddate}`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getVehicleRepairImage = async (repairID) => {
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Maintenence/GetRepairImageIDs?repairID=${repairID}`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getAccidentRecords = async (values) => {
  const { startDate, enddate, UserID } = values;
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Accident/GetAccidentRecodes?startDate=${startDate}&endDate=${enddate}&userID=${UserID}`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getAccidentImages = async (AccidentID) => {
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Accident/GetAccidentImages?AccidentID=${AccidentID}`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getSafetyTalkRecords = async (values) => {
  const { startDate, endDate } = values;
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Safety/GetSafetyTalk?StartDate=${startDate}&EndDate=${endDate}`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getSafetyTalkImages = async (SafetID) => {
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Safety/GetSafetyTalkMedia?SafetTalkID=${SafetID}`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getCustomers = async () => {
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Orders/GetCustomers`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getOrders = async (values) => {
  const { startDate, endDate, UserID } = values;
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Orders/GetOrders?UserID=${UserID}&startDate=${startDate}&endDate=${endDate}`,
      "GET"
    );

    return result;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const getVehicleServiceStatus = async (values) => {
  try {
    const { data: result, isFetching } = await fetchWithCache(
      `Maintenence/GetVehicleServiceStatus`,
      "GET"
    );

    return { result, isFetching };
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};



export const getAllocations = async (values) => {
  try {
    const { startDate, enddate, UserID } = values;
    const { data: result, isFetching } = await fetchWithCache(
      `Orders/GetAllocations?UserID=${UserID}&startDate=${startDate}&endDate=${enddate}`,
      "GET"
    );

    return { result, isFetching };
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};
