import React from "react";

function LandingPage() {
//   const navigate = useNavigate(); // Hook for navigation

  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      color: "#333",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    header: {
      backgroundColor: "#004080",
      color: "white",
      padding: "20px 40px",
      textAlign: "center",
    },
    headerTitle: {
      fontSize: "2.5rem",
      margin: 0,
    },
    headerSubtitle: {
      fontSize: "1.2rem",
      marginTop: "10px",
    },
    heroSection: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "#f4f4f4",
      padding: "40px 20px",
    },
    heroTitle: {
      fontSize: "2rem",
      marginBottom: "20px",
    },
    heroDescription: {
      fontSize: "1rem",
      maxWidth: "800px",
      textAlign: "center",
      marginBottom: "30px",
    },
    ctaButton: {
      padding: "10px 20px",
      fontSize: "1rem",
      backgroundColor: "#004080",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    featuresSection: {
      padding: "40px 20px",
      backgroundColor: "white",
    },
    featuresTitle: {
      fontSize: "1.8rem",
      textAlign: "center",
      marginBottom: "30px",
    },
    featureGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
      gap: "20px",
    },
    featureCard: {
      backgroundColor: "#f4f4f4",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    footer: {
      backgroundColor: "#004080",
      color: "white",
      textAlign: "center",
      padding: "20px 0",
    },
  };

//   const handleGetStarted = () => {
//     navigate("/login"); // Redirect to the login page
//   };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.headerTitle}>Welcome to Waveline Commercial</h1>
      </header>

      <section style={styles.heroSection}>
        <button
          style={styles.ctaButton}
          type="button"
        //   onClick={() => handleGetStarted()}
        >
          Get Started
        </button>
      </section>

      <footer style={styles.footer}>
        <p>&copy; {new Date().getFullYear()} Waveline. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default LandingPage;
