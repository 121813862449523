const apiUrl = "https://customer.crato.co.ke/api/"; // Replace with your actual API URL

// Helper function for API requests
const apiRequest = async (url, method, body = null, headers = {}) => {
  const response = await fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: body ? JSON.stringify(body) : null,
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "An error occurred");
  }

  return data;
};

// Login function
export const login = async (username, password) => {
  try {
    const loginRequest = {
      username,
      password,
    };

    const result = await apiRequest(
      `${apiUrl}Users/UserLogin`,
      "POST",
      loginRequest
    );

    return result;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};

export const AddUser = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Users/AddSystemUser`, "POST", values);

    return result;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};


export const AddUserVehicleAssignment = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Maintenence/AddVechleAssignemnt`, "POST", values);

    return result;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};


export const AddVehicleChecklist = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Maintenence/AddCheckList`, "POST", values);

    return result;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};


export const AddChecklistExtensionRequest = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}GoogleDriveService/UploadFileToDrive`, "POST", values);

    return result;
  } catch (error) {
    console.error("Update repairs error:", error);
    throw error;
  }
};


export const AddVehicleRepairRequest = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Maintenence/AddRepair`, "POST", values);

    return result;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};


export const UpdateVehicleRepair = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Maintenence/UpdateRepair`, "POST", values);

    return result;
  } catch (error) {
    console.error("Update repairs error:", error);
    throw error;
  }
};


export const AddAccidentRecord = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Accident/AddAccident`, "POST", values);

    return result;
  } catch (error) {
    console.error("Update repairs error:", error);
    throw error;
  }
};


export const AddSafetyTalk = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Safety/AddSafetyTalk`, "POST", values);

    return result;
  } catch (error) {
    console.error("Update repairs error:", error);
    throw error;
  }
};

export const AddSafetyTalkAknowledgement = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Safety/SafetyTalkAcknowledgment`, "POST", values);

    return result;
  } catch (error) {
    console.error("Update repairs error:", error);
    throw error;
  }
};


export const AddCustomer = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Orders/AddCustomer`, "POST", values);

    return result;
  } catch (error) {
    console.error("Update repairs error:", error);
    throw error;
  }
};


export const AddOrder = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Orders/AddOrder`, "POST", values);

    return result;
  } catch (error) {
    console.error("Update repairs error:", error);
    throw error;
  }
};


export function sendHelpRequest(request) {
  return fetch(`https://graph.facebook.com/v21.0/111373551751141/messages`, {
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Bearer " +
        "EAASyPtQ8v4gBAPmgXV16ySDF8ZA19tHLbZCwD8Epg4wYXEYxEKLD11XjJXZBv5FnBuEiz3qXIzIlkzHhItQ14ZAVeJJezmrDaA4rZC3OaAnS1FZCsdKZAmURXOCP1dIz1AV6Mz80cfbnRSZCsDO8lMjDW2XT4TlNmjcaP8tnHgJ7fPXfzRbfDqh3",
    }),
    method: "post",
    body: JSON.stringify({
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: request.PhoneNumber,
      type: "template",
      template: {
        name: "esque_auth",
        language: {
          code: "en_US",
        },
        components: [
          
          {
            type: "body",
            parameters: [
              {
                type: "text",
                text: request.FullName,
              },
              
            ],
          },
        ],
      },
    }),
  });
}


export const AddDriveImage = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}GoogleDriveService/UploadFileToDrive`, "POST", values);

    return result;
  } catch (error) {
    console.error("Update repairs error:", error);
    throw error;
  }
};


export const AddAllocation = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Orders/AddAllocation/AddAllocation`, "POST", values);

    return result;
  } catch (error) {
    console.error("Adding allocation error:", error);
    throw error;
  }
};


export const UpdateLoadingPointCordinates = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Orders/UpdateLoadingPointCordinates`, "POST", values);

    return result;
  } catch (error) {
    console.error("Adding allocation error:", error);
    throw error;
  }
};


export const UpdateLoadingCheckIN = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Orders/UpdateLoadingArrival`, "POST", values);

    return result;
  } catch (error) {
    console.error("Adding allocation error:", error);
    throw error;
  }
};


export const UpdateLoadingCheckOUT = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Orders/UpdatePlantDeparture`, "POST", values);

    return result;
  } catch (error) {
    console.error("Adding allocation error:", error);
    throw error;
  }
};


export const UpdateOutletCheckIN = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Orders/UpdateOutletArrival`, "POST", values);

    return result;
  } catch (error) {
    console.error("Adding allocation error:", error);
    throw error;
  }
};


export const UpdateOutletCheckOUT = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Orders/UpdateOutletDeparture`, "POST", values);

    return result;
  } catch (error) {
    console.error("UpdateOutletDeparture error:", error);
    throw error;
  }
};


export const UpdateOffloadingCheckIN = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Orders/UpdateOffloadingArrival`, "POST", values);

    return result;
  } catch (error) {
    console.error("UpdateOffloadingArrival error:", error);
    throw error;
  }
};


export const UpdateOffloadingCheckOUT = async (values) => {
  try {
    const result = await apiRequest(`${apiUrl}Orders/UpdateFulfillOrder`, "POST", values);

    return result;
  } catch (error) {
    console.error("UpdateFulfillOrder  error:", error);
    throw error;
  }
};