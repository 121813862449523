import React, { useState } from "react";
import { getAllocations } from "../../api/apiGetrequest";
import TableHeader from "../../Components/DataTable/TableHeader";
import ShipmentFormModal from "./AddAllocation";

const today = new Date().toISOString().split("T")[0];

const DeliveryTable = () => {
  const [activeStatus, setActiveStatus] = useState("ALL");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [data, setData] = useState([]);

  const statusTypes = [
    { id: "ALL", text: "ALL" },
    { id: 1, text: "OPEN" },
    { id: 2, text: "LOADING" },
    { id: 3, text: "ENROUTE" },
    { id: 4, text: "OFFLOADING" },
    { id: 5, text: "RETURN" },
    { id: 6, text: "COMPLETE" },
  ];

  // Helper function to determine delivery status
  const getDeliveryStatus = (item) => {
    if (!item.LoadingArrivalTime) return { id: 1, text: "OPEN" };
    if (!item.LoadingArrivalTime && !item.PlantDepartureTime)
      return { id: 2, text: "LOADING" };
    if (item.PlantDepartureTime < !item.OutletArrivalTime)
      return { id: 3, text: "ENROUTE" };
    if (item.OutletArrivalTime < !item.OutletDepartureTime)
      return { id: 4, text: "OFFLOADING" };
    if (item.OutletDepartureTime < !item.OffloadingArrivalTime)
      return { id: 5, text: "RETURN" };
    // if (outletDeparture < !offloadingArrival) return { id: 5, text: "RETURN" };
    return { id: 6, text: "COMPLETE" };
  };

  // Filter data based on active status
  const filteredData = data?.filter((item) => {
    if (activeStatus === "ALL") return true;
    const status = getDeliveryStatus(item);
    return status.id === activeStatus;
  });

  // Calculate totals
  const calculateTotals = () => {
    return filteredData.reduce((acc, item) => {
      const distanceToOutlet = calculateDistance(
        item.DepartureMileage,
        item.OutletArrivalMileage
      );
      return {
        claim: (acc.claim || 0) + item.Claim,
        casesFull: (acc.casesFull || 0) + item.CasesFull,
        casesEmpty: (acc.casesEmpty || 0) + item.EmptyReturn,
        fullsReturned: (acc.fullsReturned || 0) + item.FullReturn,
        distanceToOutlet: (acc.distanceToOutlet || 0) + distanceToOutlet,
        fuelIssued: (acc.fuelIssued || 0) + item.FuelIssuedLtrs,
      };
    }, {});
  };

  // Helper function to calculate time difference in hh:mm format
  const calculateTimeDifference = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diff = Math.abs(end - start);
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  // Calculate distance between two mileage points
  const calculateDistance = (start, end) => {
    return Math.abs(end - start);
  };

  const fetchData = async () => {
    try {
      const { result } = await getAllocations({
        UserID: 0,
        startDate: startDate,
        enddate: endDate,
      });
      setData(result);
    } catch (error) {
      console.error("Error fetching accidents:", error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const styles = {
    container: { maxWidth: "1640px", margin: "0 auto", padding: "20px" },
    dateFilter: {
      display: "flex",
      gap: "10px",
      marginBottom: "20px",
      justifyContent: "flex-start",
      float: "right",
    },
    dateInput: {
      padding: "8px",
      borderRadius: "4px",
      border: "1px solid #ddd",
      fontSize: "14px",
    },
    tabContainer: {
      display: "flex",
      gap: "8px",
      marginBottom: "20px",
      overflowX: "auto",
      padding: "4px",
      float: "left",
    },
    tab: {
      padding: "8px 16px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontWeight: "bold",
      textTransform: "uppercase",
      backgroundColor: "#f4f4f4",
      color: "#666",
      whiteSpace: "nowrap",
    },
    activeTab: {
      backgroundColor: "#1565c0",
      color: "white",
    },
    tableWrapper: {
      width: "100%",
      overflowX: "auto",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      fontSize: "14px",
      textTransform: "uppercase",
      minWidth: "1200px", // Ensures horizontal scroll on smaller screens
    },
    th: {
      backgroundColor: "#f4f4f4",
      padding: "12px 8px",
      border: "1px solid #ddd",
      textAlign: "left",
      fontWeight: "bold",
      whiteSpace: "nowrap",
    },
    td: {
      padding: "8px",
      border: "1px solid #ddd",
      textAlign: "left",
      whiteSpace: "nowrap",
    },
    totalsRow: {
      backgroundColor: "#f8f9fa",
      fontWeight: "bold",
      borderTop: "2px solid #1565c0",
    },
    status: {
      padding: "4px 8px",
      borderRadius: "4px",
      fontWeight: "bold",
      display: "inline-block",
      whiteSpace: "nowrap",
    },
    statusColors: {
      1: { backgroundColor: "#e3f2fd", color: "#1565c0" },
      2: { backgroundColor: "#fff3e0", color: "#e65100" },
      3: { backgroundColor: "#e8f5e9", color: "#2e7d32" },
      4: { backgroundColor: "#fff3e0", color: "#e65100" },
      5: { backgroundColor: "#e8f5e9", color: "#2e7d32" },
      6: { backgroundColor: "#e0e0e0", color: "#424242" },
    },
  };

  const totals = calculateTotals();

  return (
    <div style={styles.container}>
      <TableHeader
        title=""
        showAddButton={true}
        showBackButton={false}
        onAdd={() => setIsModalOpen(true)}
        // onBack={() => goBack()}
        showPrintButton={false}
        showExcelButton={false}
        showPdfButton={false}
      />
      <div style={styles.tabContainer}>
        {statusTypes.map((status) => (
          <button
            key={status.id}
            style={{
              ...styles.tab,
              ...(activeStatus === status.id ? styles.activeTab : {}),
            }}
            onClick={() => setActiveStatus(status.id)}
          >
            {status.text}
          </button>
        ))}
      </div>
      <div style={styles.dateFilter}>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          placeholder="Start Date"
          style={styles.dateInput}
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          placeholder="End Date"
          style={styles.dateInput}
        />
      </div>

      <div style={styles.tableWrapper}>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>STATUS</th>
              <th style={styles.th}>DRIVER</th>
              <th style={styles.th}>CLAIM</th>
              <th style={styles.th}>DATE</th>
              <th style={styles.th}>CUSTOMER</th>
              <th style={styles.th}>DEPARTURE</th>
              <th style={styles.th}>ARRIVAL </th>
              <th style={styles.th}>FULL</th>
              <th style={styles.th}>EMPTY</th>
              <th style={styles.th}>RETURNED</th>
              <th style={styles.th}>DEPARTURE</th>
              <th style={styles.th}>RE-ARRIVAL</th>
              <th style={styles.th}>DIST TO (KM)</th>
              <th style={styles.th}>TIME TO </th>
              <th style={styles.th}>DIST FROM (KM)</th>
              <th style={styles.th}>TIME FROM</th>
              <th style={styles.th}>TRAVEL TIME </th>
              <th style={styles.th}>TAT IN-MARKET</th>
              <th style={styles.th}>FUEL RATIO (KM/L)</th>
              <th style={styles.th}>TAT IN-PLANT</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              const distanceToOutlet = calculateDistance(
                item.DepartureMileage,
                item.OutletArrivalMileage
              );
              const travelTimeToOutlet = calculateTimeDifference(
                item.PlantDepartureTime,
                item.OutletArrivalTime
              );
              const timeTakenAtOutlet = calculateTimeDifference(
                item.OutletArrivalTime,
                item.OutletDepartureTime
              );
              const travelTimeFromOutlet = calculateTimeDifference(
                item.OutletDepartureTime,
                item.OffloadingArrivalTime
              );
              const turnaroundTimeMarket = calculateTimeDifference(
                item.OutletArrivalTime,
                item.OutletDepartureTime
              );
              const turnaroundTimePlant = calculateTimeDifference(
                item.LoadingArrivalTime,
                item.PlantDepartureTime
              );
              const fuelRatio = (
                distanceToOutlet / item.FuelIssuedLtrs
              ).toFixed(2);
              const status = getDeliveryStatus(item);

              return (
                <tr key={index}>
                  <td style={styles.td}>
                    <span
                      style={{
                        ...styles.status,
                        ...styles.statusColors[status.id],
                      }}
                    >
                      {status.text}
                    </span>
                    <br />
                    <strong>{item.ShipmentNo}</strong>
                  </td>
                  <td style={styles.td}>
                    {item.DriverName.toUpperCase()}
                    <br />
                    <strong>{item.VregNO.toUpperCase()}</strong>
                  </td>
                  <td style={styles.td}>{item.Claim.toLocaleString()}</td>

                  <td style={styles.td}>
                    {new Date(item.TripDate).toLocaleDateString().toUpperCase()}
                  </td>

                  <td style={styles.td}>
                    {/* {item.OutletName.toUpperCase()} <br />{" "} */}
                    {item.CustomerName.toUpperCase()}
                  </td>
                  <td style={styles.td}>
                    {new Date(item.PlantDepartureTime)
                      .toLocaleTimeString()
                      .toUpperCase()}
                  </td>
                  <td style={styles.td}>
                    {new Date(item.OutletArrivalTime)
                      ?.toLocaleTimeString()
                      ?.toUpperCase()}
                  </td>
                  <td style={styles.td}>{item.CasesFull?.toLocaleString()}</td>
                  <td style={styles.td}>
                    {item.EmptyReturn?.toLocaleString()}
                  </td>
                  <td style={styles.td}>{item.FullReturn?.toLocaleString()}</td>

                  <td style={styles.td}>
                    {new Date(item.OutletDepartureTime)
                      ?.toLocaleTimeString()
                      ?.toUpperCase()}
                  </td>
                  <td style={styles.td}>
                    {new Date(item.OffloadingArrivalTime)
                      ?.toLocaleTimeString()
                      ?.toUpperCase()}
                  </td>

                  <td style={styles.td}>
                    {distanceToOutlet?.toLocaleString()}
                  </td>
                  <td style={styles.td}>{travelTimeToOutlet}</td>
                  <td style={styles.td}>
                    {distanceToOutlet?.toLocaleString()}
                  </td>
                  <td style={styles.td}>{timeTakenAtOutlet}</td>
                  <td style={styles.td}>{travelTimeFromOutlet}</td>
                  <td style={styles.td}>{turnaroundTimeMarket}</td>
                  <td style={styles.td}>{fuelRatio}</td>
                  <td style={styles.td}>{turnaroundTimePlant}</td>
                </tr>
              );
            })}
            <tr style={{ ...styles.td, ...styles.totalsRow }}>
              <td style={styles.td}>TOTALS</td>
              <td style={styles.td}>{totals.claim?.toLocaleString() || "-"}</td>
              <td style={styles.td}>-</td>
              <td style={styles.td}>-</td>
              <td style={styles.td}>{filteredData.length}</td>
              <td style={styles.td}>-</td>
              <td style={styles.td}>-</td>
              <td style={styles.td}>-</td>
              <td style={styles.td}>-</td>
              <td style={styles.td}>-</td>
              <td style={styles.td}>-</td>
              <td style={styles.td}>-</td>
              <td style={styles.td}>
                {totals.casesFull?.toLocaleString() || "-"}
              </td>
              <td style={styles.td}>
                {totals.casesEmpty?.toLocaleString() || "-"}
              </td>
              <td style={styles.td}>{}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ShipmentFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        fetchOrders={fetchData}
      />
    </div>
  );
};

export default DeliveryTable;
