import React, { useState } from "react";

const MultiImageViewer = ({ images, open, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!open || !images || images.length === 0) return null;

  const goToNext = () => {
    setCurrentIndex((prev) => (prev + 1) % images.length);
  };

  const goToPrevious = () => {
    setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>
          &times;
        </button>

        <div style={styles.imageContainer}>
          {images.length > 1 && (
            <>
              <button onClick={goToPrevious} style={styles.navButton}>
                ❮
              </button>

              <button
                onClick={goToNext}
                style={{ ...styles.navButton, ...styles.rightNavButton }}
              >
                ❯
              </button>
            </>
          )}

          <iframe
            src={`https://drive.google.com/file/d/${images[currentIndex].ImageID}/preview`}
            title={images[currentIndex].Description}
            style={styles.iframe}
          ></iframe>
        </div>

        {images.length > 1 && (
          <div style={styles.dotsContainer}>
            {images.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                style={{
                  ...styles.dot,
                  backgroundColor:
                    index === currentIndex ? "#2563eb" : "#d1d5db",
                }}
              />
            ))}
          </div>
        )}

        <div style={styles.description}>
          <p>{images[currentIndex].Description}</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    position: "relative",
    backgroundColor: "#fff",
    padding: "24px",
    borderRadius: "8px",
    maxWidth: "800px",
    width: "90%",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  closeButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    background: "none",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
    padding: "8px",
    color: "#333",
  },
  imageContainer: {
    position: "relative",
    marginTop: "10px",
    width: "100%",
    height: "60vh",
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
    borderRadius: "8px",
  },
  navButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "20px",
    left: "8px",
    zIndex: 1,
  },
  rightNavButton: {
    left: "auto",
    right: "8px",
  },
  dotsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "8px",
    marginTop: "16px",
  },
  dot: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    border: "none",
    padding: 0,
    cursor: "pointer",
  },
  description: {
    marginTop: "16px",
    fontSize: "16px",
    color: "#333",
  },
};

export default MultiImageViewer;
