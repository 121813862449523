import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getSafetyTalkImages,
  getSafetyTalkRecords,
} from "../../api/apiGetrequest";
import { AddSafetyTalkAknowledgement } from "../../api/apirequest";
import TableHeader from "../../Components/DataTable/TableHeader";
import Base64ImageAllFileModal from "../../Components/ImageViewer/ImageViewerAllFiles";

const SafetyTalksPage = () => {
  const [safetyTalks, setSafetyTalks] = useState([]);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [selected, setSelected] = useState(null);
  const [addNew, setAddNew] = useState(false);
  const [acknowledgedTalks, setAcknowledgedTalks] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmations, setConfirmations] = useState({});

  const handleViewImages = (row) => {
    setSelected(row);
    setOpen(true);
  };

  const handleConfirmationChange = (talkId) => {
    setConfirmations((prev) => ({
      ...prev,
      [talkId]: !prev[talkId],
    }));
  };

  function formatDate(date) {
    return date.toISOString().split("T")[0];
  }

  const details = JSON.parse(localStorage.getItem("user"));

  const today = new Date();
  const last7Days = new Date(today);
  last7Days.setDate(today.getDate() - 14);
  const startdate = formatDate(last7Days);
  const enddate = formatDate(today);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getSafetyTalkRecords({
          UserID: details?.data?.userID,
          startDate: startdate,
          endDate: enddate,
        });
        setSafetyTalks(result);
      } catch (error) {
        console.error("Error fetching safety talks:", error);
      }
    };

    fetchData();
  }, [details?.data.UserID, enddate, startdate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getSafetyTalkImages(selected?.SafetyTalkID);
        setImages(result[0]?.SafetyMedia);
      } catch (error) {
        console.error("Error fetching safety talk images:", error);
      }
    };

    if (selected) {
      fetchData();
    }
  }, [selected]);

  const handleSubmit = async (row) => {
    if (!confirmations[row.SafetyTalkID]) {
      toast.error(
        "Please confirm that you have read and understood the safety talk"
      );
      return;
    }

    setLoading(true);
    setError("");
    try {
      const result = await AddSafetyTalkAknowledgement({
        safetyTalkAcknowledgmentID: 0,
        userID: details.data.userID,
        safetyTalkID: row.SafetyTalkID,
        dateAdded: new Date(),
        statusID: row.StatusID,
      });
      if (result && result.success) {
        toast.success(
          result.message || "Safety talk acknowledged successfully!"
        );
        setOpen(false);
        // Reset confirmation
        setConfirmations((prev) => ({
          ...prev,
          [row.SafetyTalkID]: false,
        }));
      } else {
        toast.error(
          result.message || "An error occurred during acknowledgment."
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <TableHeader
        title="SAFETY TALK RECORDS"
        showAddButton={false}
        onAdd={() => setAddNew(!addNew)}
        showBackButton={false}
        showPrintButton={false}
        showExcelButton={false}
        showPdfButton={false}
      />
      <div className="card-grid">
        {safetyTalks.map((talk) => {
          const isAcknowledged = talk.Acknowledgments.some(
            (ack) => ack.UserID === details.data.userID
          );
          const acknowledgmentCount = talk.Acknowledgments.length;

          return (
            <div key={talk.SafetyTalkID} className="card">
              <div className="card-header">
                <h2 className="card-title">{talk.SafetyTalkDescription}</h2>
              </div>
              <div className="card-content">
                <div className="info-row">
                  <span className="label">Date Added:</span>
                  <span className="value">
                    {new Date(talk.DateAdded).toLocaleDateString()}
                  </span>
                </div>
                <div className="info-row">
                  <span className="label">Added By:</span>
                  <span className="value">{talk.AddedByName}</span>
                </div>
                <div className="info-row">
                  <span className="label">Status:</span>
                  <span
                    className={`value status ${
                      talk.StatusID === 1 ? "status-active" : "status-inactive"
                    }`}
                  >
                    {talk.StatusID === 1 ? "Active" : "Inactive"}
                  </span>
                </div>
                <div className="info-row">
                  <span className="label">Acknowledged By:</span>
                  <span className="value">{acknowledgmentCount} People</span>
                </div>
                <div className="button-container">
                  <button
                    className="btn btn-outline"
                    onClick={() => handleViewImages(talk)}
                  >
                    <span className="icon">📷</span>
                    View Media
                  </button>
                  {!isAcknowledged && (
                    <div className="acknowledgment-container">
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={confirmations[talk.SafetyTalkID] || false}
                          onChange={() =>
                            handleConfirmationChange(talk.SafetyTalkID)
                          }
                          className="checkbox-input"
                        />
                        <span className="checkbox-label">
                          I have read and understood this safety talk
                        </span>
                      </label>
                      {confirmations[talk.SafetyTalkID] && (
                        <button
                          className={`btn btn-outline ${
                            isAcknowledged ? "btn-acknowledged" : ""
                          }`}
                          onClick={() => handleSubmit(talk)}
                          disabled={
                            isAcknowledged || !confirmations[talk.SafetyTalkID]
                          }
                        >
                          <span className="icon">✓</span>
                          Submit Acknowledgment
                        </button>
                      )}
                    </div>
                  )}
                  {isAcknowledged && (
                    <button className="btn btn-acknowledged" disabled>
                      <span className="icon">✓</span>
                      Acknowledged
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Base64ImageAllFileModal
        open={open}
        onClose={() => setOpen(false)}
        fileUrl={`https://drive.google.com/file/d/${images}/preview`}
      />

      <style jsx>{`
        .container {
          padding: 2px;
        }

        .card-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          gap: 20px;
          margin-top: 24px;
        }

        .card {
          background: white;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          transition: transform 0.2s ease;
        }

        .card:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        }

        .card-header {
          padding: 16px;
          border-bottom: 1px solid #eee;
        }

        .card-title {
          margin: 0;
          font-size: 18px;
          font-weight: bold;
          color: #333;
        }

        .card-content {
          padding: 16px;
        }

        .info-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
        }

        .label {
          color: #666;
          font-size: 14px;
        }

        .value {
          font-weight: 500;
        }

        .status {
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 14px;
        }

        .status-active {
          color: #22c55e;
          background: #f0fdf4;
        }

        .status-inactive {
          color: #ef4444;
          background: #fef2f2;
        }

        .button-container {
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-top: 16px;
        }

        .acknowledgment-container {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        .checkbox-container {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
        }

        .checkbox-input {
          width: 18px;
          height: 18px;
          cursor: pointer;
        }

        .checkbox-label {
          font-size: 14px;
          color: #333;
          user-select: none;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 16px;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s ease;
          border: none;
          width: 100%;
        }

        .btn-outline {
          background: white;
          border: 1px solid #ddd;
          color: #333;
        }

        .btn-outline:hover:not(:disabled) {
          background: #f9fafb;
        }

        .btn-acknowledged {
          background: #f0fdf4;
          border: 1px solid #22c55e;
          color: #22c55e;
          cursor: default;
        }

        .btn:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }

        .icon {
          margin-right: 8px;
        }

        @media (max-width: 768px) {
          .card-grid {
            grid-template-columns: 1fr;
          }
        }
      `}</style>
    </div>
  );
};

export default SafetyTalksPage;
