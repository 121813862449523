import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { getUsers } from "../../api/apiGetrequest";
import { AddVehicleRepairRequest } from "../../api/apirequest";

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    backgroundColor: "#f9fafb",
    minHeight: "100vh",
    paddingBottom: "2rem",
    position: "relative",
  },
  header: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    backgroundColor: "white",
    padding: "1rem",
    marginBottom: "1.5rem",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
  },
  headerTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#111827",
  },
  section: {
    marginBottom: "1.5rem",
  },
  sectionTitle: {
    fontSize: "1.25rem",
    fontWeight: "600",
    marginBottom: "0.75rem",
    padding: "0 1rem",
    color: "#1f2937",
  },
  itemCard: {
    backgroundColor: "white",
    padding: "1rem",
    margin: "0 1rem 0.75rem",
    borderRadius: "0.5rem",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
    borderLeft: "4px solid #ef4444",
    cursor: "pointer",
    transition: "all 0.2s ease",
  },
  itemCardOk: {
    backgroundColor: "#f0fdf4",
    borderLeft: "4px solid #22c55e",
    cursor: "default",
  },
  selectedCard: {
    borderColor: "#3b82f6",
    backgroundColor: "#f0f7ff",
  },
  itemHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  itemContent: {
    flexGrow: 1,
  },
  itemName: {
    fontWeight: "500",
    color: "#1f2937",
    marginBottom: "0.25rem",
  },
  itemComment: {
    fontSize: "0.875rem",
    color: "#4b5563",
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  checkbox: {
    marginRight: "0.5rem",
    width: "1.25rem",
    height: "1.25rem",
    cursor: "pointer",
  },
  checkIcon: {
    width: "24px",
    height: "24px",
    color: "#22c55e",
  },
  warningIcon: {
    width: "24px",
    height: "24px",
    color: "#ef4444",
  },
  button: {
    position: "fixed",
    bottom: "1rem",
    right: "1rem",
    backgroundColor: "#3b82f6",
    color: "white",
    padding: "0.75rem 1.5rem",
    borderRadius: "0.5rem",
    border: "none",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    zIndex: 20,
  },
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 50,
  },
  modalContent: {
    backgroundColor: "white",
    padding: "2rem",
    borderRadius: "0.5rem",
    width: "90%",
    maxWidth: "500px",
    maxHeight: "90vh",
    overflow: "auto",
  },
  formGroup: {
    marginBottom: "1rem",
  },
  label: {
    display: "block",
    marginBottom: "0.5rem",
    color: "#374151",
    fontSize: "0.875rem",
  },
  input: {
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #d1d5db",
    borderRadius: "0.25rem",
    fontSize: "1rem",
  },
  modalButtons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
    marginTop: "1.5rem",
  },
  modalButton: {
    padding: "0.5rem 1rem",
    borderRadius: "0.25rem",
    border: "none",
    cursor: "pointer",
    fontSize: "0.875rem",
    fontWeight: "500",
  },
  confirmButton: {
    backgroundColor: "#3b82f6",
    color: "white",
  },
  cancelButton: {
    backgroundColor: "#ef4444",
    color: "white",
  },
};

const InspectionChecklist = ({ data }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [showRepairModal, setShowRepairModal] = useState(false);
  const [assignedTo, setAssignedTo] = useState("");
  const [bookedDate, setBookedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mechanic, setMechanics] = useState([]);

  const detail = JSON.parse(data?.CheckListDetail);

  React.useEffect(() => {
    fetchMechanics();
  }, []);

  const fetchMechanics = async () => {
    try {
      const result = await getUsers();
      setMechanics(result?.filter((m) => m.Role === "mechanic"));
    } catch (error) {
      toast.error("Failed to fetch mechanics");
    }
  };

  const formatItemName = (name) => {
    return name
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleItemSelect = (category, item) => {
    const itemIdentifier = `${category}-${item.ItemName}`;
    if (selectedItems.includes(itemIdentifier)) {
      setSelectedItems(selectedItems.filter((i) => i !== itemIdentifier));
    } else {
      setSelectedItems([...selectedItems, itemIdentifier]);
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));

  const generateRepairRequest = async () => {
    try {
      // Generate the repair request object
      const selectedItemDetails = selectedItems.map((itemId) => {
        const [category, itemName] = itemId.split("-");
        const item = detail[category].find((i) => i.ItemName === itemName);
        return `${formatItemName(itemName)}: ${item.comment}`;
      });

      const repairRequest = {
        repairID: 0,
        vehicleID: data?.VehicleID,
        repairDescription: selectedItemDetails.join("\n"),
        repairImageID: "",
        repairSource: "CheckList",
        sourceID: data?.CheckListID,
        assignedTo: parseInt(assignedTo) || 0,
        mechNotes: "",
        bookedDate: bookedDate,
        dateAdded: new Date().toISOString(),
        dateClosed: new Date().toISOString(),
        statusID: 2,
        addedBy: user?.data?.userID,
      };

      // Post the request
      const response = await AddVehicleRepairRequest(repairRequest);

      if (response && response.success) {
        toast.success(response.message || "Repair request successfully added!");
      } else {
        toast.error(
          response.message ||
            "An error occurred while adding the repair request."
        );
      }
    } catch (error) {
      console.error("Error generating or posting repair request:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      // Clean up UI and state after operation
      setShowRepairModal(false);
      setSelectedItems([]);
    }
  };

  const handleSelectChange = (option) => {
    setAssignedTo(option?.value);
  };

  console.log(assignedTo);

  const CheckIcon = () => (
    <svg
      style={styles.checkIcon}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  );

  const WarningIcon = () => (
    <svg
      style={styles.warningIcon}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="12" y1="8" x2="12" y2="12" />
      <line x1="12" y1="16" x2="12.01" y2="16" />
    </svg>
  );

  const CategorySection = ({ title, items }) => (
    <div style={styles.section}>
      <h2 style={styles.sectionTitle}>
        {title.charAt(0).toUpperCase() + title.slice(1)}
      </h2>
      {items?.map((item, index) => {
        const itemIdentifier = `${title}-${item.ItemName}`;
        const isSelected = selectedItems.includes(itemIdentifier);

        return (
          <div
            key={index}
            style={{
              ...styles.itemCard,
              ...(item.status ? styles.itemCardOk : {}),
              ...(!item.status && isSelected ? styles.selectedCard : {}),
            }}
            onClick={() => !item.status && handleItemSelect(title, item)}
          >
            <div style={styles.itemHeader}>
              <div style={styles.itemContent}>
                <h3 style={styles.itemName}>{formatItemName(item.ItemName)}</h3>
                <p style={styles.itemComment}>{item.comment}</p>
              </div>
              <div style={styles.statusContainer}>
                {!item.status && (
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => handleItemSelect(title, item)}
                    style={styles.checkbox}
                  />
                )}
                {item.status ? <CheckIcon /> : <WarningIcon />}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.headerTitle}>Vehicle Inspection Report</h1>
      </div>

      {Object.entries(detail).map(([category, items]) => (
        <CategorySection key={category} title={category} items={items} />
      ))}

      {selectedItems.length > 0 && (
        <button style={styles.button} onClick={() => setShowRepairModal(true)}>
          Book Selected Items ({selectedItems.length})
        </button>
      )}

      {showRepairModal && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <h2 style={styles.sectionTitle}>Create Repair Request</h2>

            <div style={styles.formGroup}>
              <label style={styles.label}>Assign to Mechanic</label>
              <Select
                options={mechanic?.map((m) => {
                  return { value: m.UserID, label: m.FullName };
                })}
                value={assignedTo}
                onChange={handleSelectChange}
                placeholder="Select a mechanic"
              />
            </div>

            <div style={styles.formGroup}>
              <label style={styles.label}>Date Booked:</label>
              <input
                type="date"
                value={bookedDate}
                onChange={(e) => setBookedDate(e.target.value)}
                style={styles.input}
                // placeholder="Enter estimated cost"
              />
            </div>

            <div style={styles.modalButtons}>
              <button
                onClick={() => setShowRepairModal(false)}
                style={{ ...styles.modalButton, ...styles.cancelButton }}
              >
                Cancel
              </button>
              <button
                onClick={generateRepairRequest}
                style={{ ...styles.modalButton, ...styles.confirmButton }}
              >
                Book for Repair
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InspectionChecklist;
