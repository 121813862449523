import React, { useEffect, useState } from "react";
import { getVehicleChecklist } from "../../api/apiGetrequest";
import VehicleSafetyChecklistItems from "./CheckListItem";
import caution from "./images/exclamation-mark.png";
import warning from "./images/warning.jpg";

const VehicleSafetyChecklist = ({goBack}) => {
  const styles = {
    container: {
      minHeight: "100vh",
      backgroundColor: "#f3f4f6",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "system-ui, -apple-system, sans-serif",
    },
    contentWrapper: {
      width: "100%",
      maxWidth: "768px",
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
      // padding: "1.5rem",
      backgroundColor: "white",
      borderRadius: "1rem",
      boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1)",
    },
    statusCard: {
      padding: "1.5rem",
      borderRadius: "0.75rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      transition: "transform 0.2s ease",
    },
    statusCompleted: {
      backgroundColor: "#dcfce7",
      border: "1px solid #86efac",
    },
    statusPending: {
      backgroundColor: "#fef3c7",
      border: "1px solid #fde68a",
    },
    statusText: (isCompleted) => ({
      fontWeight: 600,
      fontSize: "1.125rem",
      color: isCompleted ? "#166534" : "#92400e",
    }),
    timerContainer: {
      backgroundColor: "#e0f2fe",
      padding: "1.5rem",
      borderRadius: "0.75rem",
      border: "1px solid #bae6fd",
      transition: "transform 0.2s ease",
    },
    timerTitle: {
      marginBottom: "1rem",
      color: "#1e3a8a",
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    timerText: (visible) => ({
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      color: "#dc2626",
      fontWeight: 600,
      fontSize: "1.125rem",
      visibility: visible ? "visible" : "hidden",
    }),
    button: {
      padding: "1rem 2rem",
      border: "none",
      borderRadius: "0.75rem",
      fontWeight: 600,
      fontSize: "1rem",
      cursor: "pointer",
      transition: "all 0.2s ease",
      textTransform: "uppercase",
      letterSpacing: "0.05em",
    },
    buttonPrimary: {
      backgroundColor: "#3b82f6",
      color: "white",
      boxShadow: "0 4px 6px -1px rgba(59, 130, 246, 0.2)",
      "&:hover": {
        backgroundColor: "#2563eb",
        transform: "translateY(-2px)",
      },
    },
    buttonDisabled: {
      backgroundColor: "#9ca3af",
      color: "white",
      cursor: "not-allowed",
      opacity: 0.75,
    },
    maintenanceContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "1.5rem",
      padding: "2rem",
      textAlign: "center",
    },
    maintenanceImage: {
      width: "150px",
      height: "150px",
      opacity: 0.8,
      transition: "opacity 0.2s ease",
    },
    errorText: {
      color: "#dc2626",
      fontWeight: 600,
      maxWidth: "300px",
      lineHeight: 1.5,
      textAlign: "center",
    },
  };

  const [maintenanceReports, setMaintenanceReports] = useState([]);

  const [checklistStatus, setChecklistStatus] = useState({
    initialized: false,
    // completed: false,
    completedTime: null,
    timeRemaining: null,
    isPastDeadline: false,
  });
  const [timerBlink, setTimerBlink] = useState(true);
  const [addCheckList, setAddChecklist] = useState(false);
  const details = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const checkDailyStatus = () => {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const deadline = new Date(today.setHours(10, 0, 0, 0)); // 10 PM today
      const savedStatus = localStorage.getItem("vehicleChecklistStatus");

      if (savedStatus) {
        try {
          const parsedStatus = JSON.parse(savedStatus);
          const completedDate = new Date(parsedStatus.completedTime);

          if (
            completedDate.getDate() === now.getDate() &&
            completedDate.getMonth() === now.getMonth() &&
            completedDate.getFullYear() === now.getFullYear()
          ) {
            setChecklistStatus({
              initialized: true,
              completed: true,
              completedTime: parsedStatus.completedTime,
              timeRemaining: null,
              isPastDeadline: false,
            });
            return;
          }
        } catch (error) {
          console.error("Error parsing checklist status:", error);
        }
      }

      // Checklist incomplete, check deadline
      if (now > deadline) {
        setChecklistStatus({
          initialized: true,
          completed: false,
          completedTime: null,
          timeRemaining: null,
          isPastDeadline: true,
        });
      } else {
        const remainingTime = deadline.getTime() - now.getTime();
        const hours = Math.floor(remainingTime / (1000 * 60 * 60));
        const minutes = Math.floor(
          (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        setChecklistStatus({
          initialized: true,
          completed: false,
          completedTime: null,
          timeRemaining: `${hours}h ${minutes}m ${seconds}s`,
          isPastDeadline: false,
        });
      }
    };

    checkDailyStatus();
    const intervalId = setInterval(checkDailyStatus, 1000);
    const blinkInterval = setInterval(() => {
      setTimerBlink((prev) => !prev);
    }, 1000);

    return () => {
      clearInterval(intervalId);
      clearInterval(blinkInterval);
    };
  }, []);

  const handleAddChecklist = () => {
    setAddChecklist(true);
  };

  function formatDate(date) {
    return date.toISOString().split("T")[0]; // Converts to "YYYY-MM-DD"
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getVehicleChecklist(
          formatDate(new Date()),
          formatDate(new Date()),
          details?.data?.vehicleID
        );
        setMaintenanceReports(result);

        if (result && result[0].StatusName === "Done") {
          // Update the checklistStatus state to "completed"

          setChecklistStatus((prev) => ({
            ...prev,
            completed: true,
          }));
          // Save the status to localStorage
          const completedTime = new Date().toISOString();
          const updatedStatus = {
            completedTime,
            completed: true,
          };
          localStorage.setItem(
            "vehicleChecklistStatus",
            JSON.stringify(updatedStatus)
          );
        } else {
          localStorage.removeItem("vehicleChecklistStatus");
        }
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    };

    fetchData();
  }, [details?.data?.vehicleID]);

  const renderInitialStatus = () => {
    if (!checklistStatus.initialized) return null;

    if (checklistStatus.completed && !addCheckList) {
      return (
        <>
          <div style={{ ...styles.statusCard, ...styles.statusCompleted }}>
            <span style={styles.statusText(true)}>
              👍 Checklist Completed Today
            </span>
          </div>
          <button
            style={{ ...styles.button, ...styles.buttonPrimary }}
            onClick={handleAddChecklist}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = "#2563eb";
              e.target.style.transform = "translateY(-2px)";
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = "#3b82f6";
              e.target.style.transform = "none";
            }}
          >
            Review Check List
          </button>
        </>
      );
    }

    let parsedCheckListDetail = {};
    try {
      const checkListDetail = maintenanceReports[0]?.CheckListDetail;
      parsedCheckListDetail = checkListDetail
        ? JSON.parse(checkListDetail)
        : null;
    } catch (error) {
      console.error("Failed to parse CheckListDetail:", error);
    }

    if (addCheckList) {
      return (
        <VehicleSafetyChecklistItems
          CheckListDetail={parsedCheckListDetail}
          goBack={() => setAddChecklist(null)}
        />
      );
    }

    if (checklistStatus.isPastDeadline) {
      return (
        <>
          <div style={{ ...styles.statusCard, ...styles.statusPending }}>
            <span style={styles.statusText(false)}>⚠️ Checklist Pending</span>
          </div>
          <div style={styles.maintenanceContainer}>
            <img
              src={warning}
              alt="Warning Icon"
              style={styles.maintenanceImage}
            />
            <div style={styles.errorText}>
              Your time has elapsed to fill the checklist today, please contact
              admin.
            </div>
           
          </div>
        </>
      );
    }

    if (!details.data?.vehicleRegNo) {
      return (
        <>
          <div style={{ ...styles.statusCard, ...styles.statusPending }}>
            <span style={styles.statusText(false)}>⚠️ Checklist Pending</span>
          </div>
          <div style={styles.maintenanceContainer}>
            <img
              src={caution}
              alt="Caution Icon"
              style={styles.maintenanceImage}
            />
            <div style={styles.errorText}>
              You don't have assigned vehicle. Please contact admin
            </div>
          </div>
        </>
      );
    }

    return (
      <div style={styles.timerContainer}>
        <h2 style={styles.timerTitle}>Daily Safety Checklist</h2>
        <div style={styles.timerText(timerBlink)}>
          ⏰ Time Remaining: {checklistStatus.timeRemaining}
        </div>
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        {renderInitialStatus()}
        {!addCheckList &&
          !checklistStatus.completed &&
          !checklistStatus.isPastDeadline &&
          details?.data?.vehicleRegNo && (
            <button
              style={{ ...styles.button, ...styles.buttonPrimary }}
              onClick={handleAddChecklist}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#2563eb";
                e.target.style.transform = "translateY(-2px)";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "#3b82f6";
                e.target.style.transform = "none";
              }}
            >
              Start Checklist
            </button>
          )}
        {checklistStatus.isPastDeadline && (
          <button
            style={{ ...styles.button, ...styles.buttonDisabled }}
            // disabled
          >
            RUQUEST TIME EXTENSION
          </button>
        )}
      </div>
    </div>
  );
};

export default VehicleSafetyChecklist;
