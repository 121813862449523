import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getChecklistItems } from "../../api/apiGetrequest";
import { AddVehicleChecklist } from "../../api/apirequest";
import TableHeader from "../../Components/DataTable/TableHeader";
// Styles object defined outside the component
const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    marginBottom: "80px",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  statusIndicator: {
    padding: "1rem",
    borderRadius: "0.5rem",
    textAlign: "center",
    marginBottom: "1.5rem",
    fontWeight: "bold",
  },
  sectionContainer: {
    backgroundColor: "white",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "0.5rem",
    padding: "1rem",
    marginBottom: "1rem",
  },
  sectionTitle: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    marginBottom: "0.75rem",
  },
  itemRow: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderBottom: "1px solid #e5e7eb",
  },
  itemHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "0.5rem",
  },
  itemLabel: {
    textTransform: "capitalize",
    flexGrow: 1,
  },
  buttonContainer: {
    display: "flex",
    gap: "0.5rem",
  },
  okayButton: {
    padding: "0.25rem 0.75rem",
    borderRadius: "9999px",
    cursor: "pointer",
  },
  notOkayButton: {
    padding: "0.25rem 0.75rem",
    borderRadius: "9999px",
    cursor: "pointer",
  },
  commentTextarea: {
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #d1d5db",
    borderRadius: "0.375rem",
    outline: "none",
  },
  errorText: {
    color: "#ef4444",
    fontSize: "0.875rem",
    marginTop: "0.25rem",
  },
  saveButton: {
    width: "100%",
    padding: "0.75rem",
    backgroundColor: "#3b82f6",
    color: "white",
    border: "none",
    borderRadius: "0.5rem",
    fontSize: "1rem",
    fontWeight: "bold",
    cursor: "pointer",
    marginTop: "1rem",
    transition: "background-color 0.3s ease",
  },
  saveButtonHover: {
    backgroundColor: "#2563eb",
  },
  mileageContainer: {
    backgroundColor: "white",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "0.5rem",
    padding: "1rem",
    marginBottom: "1rem",
  },
  mileageInput: {
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #d1d5db",
    borderRadius: "0.375rem",
    marginTop: "0.5rem",
    outline: "none",
  },
};

const transformChecklist = (checklist) => {
  const groups = {};
  const seen = new Set();

  checklist.forEach((item) => {
    // Create a unique identifier for each item
    const itemId = item.CheckItemID || item.ChecklistItemName;

    if (!seen.has(itemId)) {
      if (!groups[item.ItemGroup]) {
        groups[item.ItemGroup] = [];
      }

      groups[item.ItemGroup].push({
        ItemName: item.ChecklistItemName,
        CheckListItemID: item.CheckItemID,
        status: null, // Default value for status
        comment: "", // Default value for comment
      });

      seen.add(itemId);
    }
  });

  return groups;
};

const VehicleSafetyChecklistItems = ({ CheckListDetail, goBack }) => {
  const formattedChecklist = transformChecklist([]);
  const details = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [checklist, setChecklist] = useState([]);
  const [vehicleMileage, setVehicleMileage] = useState("");
  const [mileageError, setMileageError] = useState("");

  const fetchData = async () => {
    try {
      const result = await getChecklistItems();
      setChecklist(
        CheckListDetail ? CheckListDetail : transformChecklist(result)
      );
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [CheckListDetail]);

  // const validateComment = (comment) => {
  //   return comment && comment.trim().length >= 5;
  // };

  const validateMileage = (mileage) => {
    return mileage && !isNaN(mileage) && parseFloat(mileage) > 0;
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // Validate mileage
    if (!validateMileage(vehicleMileage)) {
      setMileageError("Please enter a valid vehicle mileage");
      return;
    }

    // Validate all comments for items marked as Not Okay
    // let hasInvalidComments = false;
    // Object.keys(checklist).forEach((section) => {
    //   checklist[section].forEach((item) => {
    //     if (item.status === false && !validateComment(item.comment)) {
    //       hasInvalidComments = true;
    //     }
    //   });
    // });

    // if (hasInvalidComments) {
    //   toast.error(
    //     "Please provide detailed comments (minimum 5 characters) for all issues"
    //   );
    //   return;
    // }

    setLoading(true);
    setError("");

    try {
      const result = await AddVehicleChecklist({
        checklistID: 0,
        vehicleID: details?.data?.vehicleID,
        addedBy: details?.data?.userID,
        dateAdded: new Date(),
        statusID: 1,
        checkListDetail: JSON.stringify(checklist),
        vehicleMileage: parseFloat(vehicleMileage),
      });

      if (result && result.success) {
        toast.success(result.message || "CheckList successfully saved!");
        goBack();
      } else {
        toast.error(
          result.message || "An error occurred during checklist submission."
        );
      }
    } catch (error) {
      console.error(error);
      toast.error("There was an error processing your request.");
    } finally {
      setLoading(false);
      fetchData();
    }
  };

  // State to track hover and active states for save button
  const [isHoveringSave, setIsHoveringSave] = useState(false);

  // Utility function to format camel case to readable text
  const formatLabel = (key) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .toLowerCase()
      .replace(/^./, (char) => char.toUpperCase());
  };

  useEffect(() => {
    setChecklist(formattedChecklist);
  }, []);

  // Update item status and handle comment requirement
  const updateItemStatus = (section, itemIndex, status) => {
    setChecklist((prev) => {
      const updatedChecklist = { ...prev };
      updatedChecklist[section] = [...prev[section]];
      updatedChecklist[section][itemIndex] = {
        ...updatedChecklist[section][itemIndex],
        status: status,
        comment:
          status === true
            ? ""
            : updatedChecklist[section][itemIndex].comment || "",
      };
      return updatedChecklist;
    });
  };
  // Update comment for a specific item
  const updateComment = (section, itemIndex, comment) => {
    setChecklist((prev) => {
      const updatedChecklist = { ...prev };
      updatedChecklist[section] = [...prev[section]];
      updatedChecklist[section][itemIndex] = {
        ...updatedChecklist[section][itemIndex],
        comment: comment,
      };
      return updatedChecklist;
    });
  };

  // Check if a section is completely reviewed
  const isSectionComplete = (section) => {
    return checklist[section]?.every((item) => item.status !== null);
  };

  // Check if a section has any issues
  const hasSectionIssues = (section) => {
    return checklist[section]?.some((item) => item.status === false);
  };

  // Calculate overall review status
  const calculateOverallStatus = () => {
    const allSectionsReviewed = Object.values(checklist).every((section) =>
      section.every((item) => item.status !== null)
    );

    const hasIssues = Object.values(checklist).some((section) =>
      section?.some((item) => item.status === false)
    );

    return {
      allReviewed: allSectionsReviewed,
      hasIssues,
    };
  };

  // Render a section of checklist items
  const renderSection = (sectionTitle, sectionKey, items) => {
    const determineSectionTitleColor = () => {
      if (!isSectionComplete(sectionKey)) return "#1f2937";
      return hasSectionIssues(sectionKey) ? "#dc2626" : "#16a34a";
    };

    return (
      <div
        style={{
          ...styles.sectionContainer,
          border: hasSectionIssues(sectionKey) ? "2px solid #ef4444" : "none",
        }}
      >
        <h2
          style={{
            ...styles.sectionTitle,
            color: determineSectionTitleColor(),
          }}
        >
          {sectionTitle}
        </h2>
        {items?.map((item, index) => (
          <div key={item.CheckListItemID} style={styles.itemRow}>
            <div style={styles.itemHeader}>
              <span style={styles.itemLabel}>{formatLabel(item.ItemName)}</span>
              <div style={styles.buttonContainer}>
                <button
                  onClick={() => updateItemStatus(sectionKey, index, true)}
                  disabled={CheckListDetail}
                  style={{
                    ...styles.okayButton,
                    backgroundColor:
                      item.status === true ? "#22c55e" : "#dcfce7",
                    color: item.status === true ? "white" : "#dcfce7",
                  }}
                >
                  ✓
                </button>
                <button
                  disabled={CheckListDetail}
                  onClick={() => updateItemStatus(sectionKey, index, false)}
                  style={{
                    ...styles.notOkayButton,
                    backgroundColor:
                      item.status === false ? "#ef4444" : "#fee2e2",
                    color: item.status === false ? "white" : "#fee2e2",
                  }}
                >
                  ✗
                </button>
              </div>
            </div>

            {item.status === false && (
              <div style={{ marginTop: "0.5rem" }}>
                <textarea
                  placeholder="Describe the issue (required)"
                  style={styles.commentTextarea}
                  rows={3}
                  value={item.comment || ""}
                  onChange={(e) =>
                    updateComment(sectionKey, index, e.target.value)
                  }
                  required
                />
                {(!item.comment || item.comment.trim() === "") && (
                  <p style={styles.errorText}>
                    ⚠️ Comment is required for items marked as Not Okay
                  </p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const { allReviewed, hasIssues } = calculateOverallStatus();

  // Determine status indicator styles
  const getStatusIndicatorStyle = () => {
    const baseStyle = { ...styles.statusIndicator };
    if (!allReviewed) {
      baseStyle.backgroundColor = "#fef3c7";
      baseStyle.color = "#92400e";
    } else if (hasIssues) {
      baseStyle.backgroundColor = "#fee2e2";
      baseStyle.color = "#991b1b";
    } else {
      baseStyle.backgroundColor = "#dcfce7";
      baseStyle.color = "#166534";
    }
    return baseStyle;
  };

  return (
    <div style={styles.container}>
      {CheckListDetail && (
        <TableHeader
          title=""
          showAddButton={false}
          showBackButton={true}
          onBack={() => goBack()}
          showPrintButton={false}
          showExcelButton={false}
          showPdfButton={false}
        />
      )}
      <h1 style={styles.title}>Vehicle Safety Checklist</h1>
      <div style={styles.mileageContainer}>
        <h2 style={styles.sectionTitle}>Vehicle Mileage</h2>
        <input
          type="number"
          value={vehicleMileage}
          onChange={(e) => {
            setVehicleMileage(e.target.value);
            setMileageError("");
          }}
          placeholder="Enter current vehicle mileage"
          style={styles.mileageInput}
          disabled={CheckListDetail}
        />
        {mileageError && <p style={styles.errorText}>{mileageError}</p>}
      </div>
      {/* Overall Status Indicator */}
      <div style={getStatusIndicatorStyle()}>
        {!allReviewed && "Complete all sections"}
        {allReviewed && hasIssues && "Issues found - Vehicle needs attention"}
        {allReviewed && !hasIssues && "Vehicle is safe and ready"}
      </div>

      {/* Checklist Sections */}
      {renderSection(
        "Mechanical Components",
        "mechanicalComponents",
        checklist.mechanicalComponents
      )}
      {renderSection("Lights", "lights", checklist.lights)}
      {renderSection(
        "Body and Documents",
        "bodyAndDocuments",
        checklist.bodyAndDocuments
      )}
      {renderSection(
        "Safety Equipment",
        "safetyEquipment",
        checklist.safetyEquipment
      )}
      {renderSection("Fluid Levels", "fluidLevels", checklist.fluidLevels)}
      {renderSection(
        "Accident Preparation",
        "accidentInformation",
        checklist.accidentInformation
      )}

      {/* Save Button */}
      {!CheckListDetail && (
        <button
          style={{
            ...styles.saveButton,
            ...(isHoveringSave ? styles.saveButtonHover : {}),
            backgroundColor:
              loading || !calculateOverallStatus().allReviewed
                ? "grey"
                : "#007BFF",
          }}
          onMouseEnter={() => setIsHoveringSave(true)}
          onMouseLeave={() => setIsHoveringSave(false)}
          // disabled={loading}
          onClick={handleSave}
          disabled={loading || !calculateOverallStatus().allReviewed}
        >
          Save Checklist
        </button>
      )}
      {/* <BottomNavigation/> */}
    </div>
  );
};

export default VehicleSafetyChecklistItems;
