import React, { createContext, useContext } from "react";

const DarkModeContext = createContext(false);

// Icons remain the same
const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="12" y1="5" x2="12" y2="19" />
    <line x1="5" y1="12" x2="19" y2="12" />
  </svg>
);

const ArrowLeftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="19" y1="12" x2="5" y2="12" />
    <polyline points="12 19 5 12 12 5" />
  </svg>
);

const PrintIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="6 9 6 2 18 2 18 9" />
    <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
    <rect x="6" y="14" width="12" height="8" />
  </svg>
);

const ExcelIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
    <path d="M14 2v6h6" />
    <path d="M8 13h8" />
    <path d="M8 17h8" />
    <path d="M8 9h2" />
  </svg>
);

const PdfIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
    <polyline points="14 2 14 8 20 8" />
    <path d="M9 13v4" />
    <path d="M15 13v4" />
    <path d="M9 13h6" />
  </svg>
);

const TableHeader = ({
  title = "Table Title",
  showAddButton = true,
  showBackButton = true,
  showPrintButton = true,
  showExcelButton = true,
  showPdfButton = true,
  buttonName = "Add New",
  onAdd = () => {},
  onBack = () => {},
  onPrint = () => {},
  onExcelExport = () => {},
  onPdfExport = () => {},
}) => {
  const isDarkMode = useContext(DarkModeContext);

  const styles = {
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 1.5rem",
      backgroundColor: isDarkMode ? "#1f2937" : "#f3f4f6",
      borderBottom: `1px solid ${isDarkMode ? "#374151" : "#e5e7eb"}`,
      color: isDarkMode ? "#ffffff" : "#111827",
    },
    leftSection: {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
    },
    rightSection: {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
    },
    title: {
      fontSize: "1.25rem",
      fontWeight: "600",
      margin: 0,
    },
    button: {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      padding: "0.5rem 1rem",
      borderRadius: "0.375rem",
      border: "none",
      cursor: "pointer",
      fontSize: "0.875rem",
      transition: "all 0.2s ease",
    },
    iconButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0.5rem",
      borderRadius: "0.375rem",
      border: "none",
      cursor: "pointer",
      backgroundColor: isDarkMode
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.05)",
      color: isDarkMode ? "#ffffff" : "#111827",
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: isDarkMode
          ? "rgba(255, 255, 255, 0.15)"
          : "rgba(0, 0, 0, 0.1)",
      },
    },
    addButton: {
      backgroundColor: isDarkMode ? "#2563eb" : "#3b82f6",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: isDarkMode ? "#1d4ed8" : "#2563eb",
      },
    },
    backButton: {
      backgroundColor: isDarkMode
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.05)",
      color: isDarkMode ? "#ffffff" : "#111827",
      "&:hover": {
        backgroundColor: isDarkMode
          ? "rgba(255, 255, 255, 0.15)"
          : "rgba(0, 0, 0, 0.1)",
      },
    },
  };

  return (
    <div style={styles.header}>
      <div style={styles.leftSection}>
        {showBackButton && (
          <button
            onClick={onBack}
            style={{ ...styles.button, ...styles.backButton }}
          >
            <ArrowLeftIcon />
            Back
          </button>
        )}
        <h2 style={styles.title}>{title}</h2>
      </div>

      <div style={styles.rightSection}>
        {showPrintButton && (
          <button onClick={onPrint} style={styles.iconButton} title="Print">
            <PrintIcon />
          </button>
        )}
        {showExcelButton && (
          <button
            onClick={onExcelExport}
            style={styles.iconButton}
            title="Export to Excel"
          >
            <ExcelIcon />
          </button>
        )}
        {showPdfButton && (
          <button
            onClick={onPdfExport}
            style={styles.iconButton}
            title="Export to PDF"
          >
            <PdfIcon />
          </button>
        )}
        {showAddButton && (
          <button
            onClick={onAdd}
            style={{ ...styles.button, ...styles.addButton }}
          >
            <PlusIcon />
            {buttonName ? buttonName : "Add New"}
          </button>
        )}
      </div>
    </div>
  );
};

export default TableHeader;
