import React from 'react';

const TruckLoader = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      minHeight: '200px',
      overflow: 'hidden'
    }}>
      <div style={{
        position: 'relative',
        width: '200px',
        height: '100px',
        animation: 'truckMove 3s infinite linear'
      }}>
        {/* Truck body */}
        <div style={{
          position: 'absolute',
          bottom: '20px',
          width: '130px',
          height: '50px',
          backgroundColor: '#3498db',
          borderRadius: '10px'
        }}>
          {/* Truck cabin */}
          <div style={{
            position: 'absolute',
            right: '-30px',
            bottom: '0',
            width: '40px',
            height: '35px',
            backgroundColor: '#2980b9',
            borderRadius: '5px'
          }} />
          {/* Window */}
          <div style={{
            position: 'absolute',
            right: '-25px',
            bottom: '20px',
            width: '25px',
            height: '12px',
            backgroundColor: '#fff',
            borderRadius: '3px'
          }} />
        </div>
        {/* Wheels */}
        <div style={{
          position: 'absolute',
          bottom: '10px',
          left: '20px',
          width: '20px',
          height: '20px',
          backgroundColor: '#333',
          borderRadius: '50%',
          animation: 'wheelSpin 1s infinite linear'
        }} />
        <div style={{
          position: 'absolute',
          bottom: '10px',
          right: '20px',
          width: '20px',
          height: '20px',
          backgroundColor: '#333',
          borderRadius: '50%',
          animation: 'wheelSpin 1s infinite linear'
        }} />
      </div>
      
      {/* Road */}
      <div style={{
        width: '200px',
        height: '3px',
        backgroundColor: '#666',
        marginTop: '10px'
      }} />
      
      <div style={{
        marginTop: '20px',
        color: '#666',
        fontFamily: 'Arial, sans-serif'
      }}>
        Loading...
      </div>

      <style>
        {`
          @keyframes truckMove {
            0% {
              transform: translateX(-120px);
            }
            100% {
              transform: translateX(120px);
            }
          }

          @keyframes wheelSpin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  );
};

export default TruckLoader;