import React, { useEffect, useState } from "react";
import {
  getSafetyTalkImages,
  getSafetyTalkRecords,
} from "../../api/apiGetrequest";
import TableHeader from "../../Components/DataTable/TableHeader";
import Base64ImageAllFileModal from "../../Components/ImageViewer/ImageViewerAllFiles";
import AddSafetyTalkForm from "./AddSafetyTalk";

const SafetyTalksPage = () => {
  const [safetyTalks, setSafetyTalks] = useState([]);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [selected, setSelected] = useState(null);
  const [addNew, setAddNew] = useState(false);

  const handleViewImages = (row) => {
    setSelected(row);
    setOpen(true);
  };

  function formatDate(date) {
    return date.toISOString().split("T")[0];
  }

  const details = JSON.parse(localStorage.getItem("user"));

  const today = new Date();
  const last7Days = new Date(today);
  last7Days.setDate(today.getDate() - 14);
  const startdate = formatDate(last7Days);
  const enddate = formatDate(today);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getSafetyTalkRecords({
          UserID: details?.data?.UserID || 0,
          startDate: startdate,
          endDate: enddate,
        });
        setSafetyTalks(result);
      } catch (error) {
        console.error("Error fetching safety talks:", error);
      }
    };

    fetchData();
  }, [details?.data?.UserID, enddate, startdate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getSafetyTalkImages(selected?.SafetyTalkID);
        setImages(result[0]?.SafetyMedia);
      } catch (error) {
        console.error("Error fetching safety talk images:", error);
      }
    };

    fetchData();
  }, [selected]);

  if (addNew) {
    return (
      <div>
        <TableHeader
          title="ADD SAFETY TALK RECORDS"
          showAddButton={false}
          showBackButton={true}
          onBack={() => setAddNew(!addNew)}
          showPrintButton={false}
          showExcelButton={false}
          showPdfButton={false}
        />
        <AddSafetyTalkForm />
      </div>
    );
  }

  return (
    <div style={styles.tableContainer}>
      <TableHeader
        title="SAFETY TALK RECORDS"
        showAddButton={true}
        onAdd={() => setAddNew(!addNew)}
        showBackButton={false}
        showPrintButton={true}
        showExcelButton={true}
        showPdfButton={true}
      />
      <div style={styles.tableContainer}>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.thTd}>SAFETY TALK DESCRIPTION</th>
              <th style={styles.thTd}>DATE ADDED</th>
              <th style={styles.thTd}>ADDED BY</th>
              <th style={styles.thTd}>STATUS</th>
              <th style={styles.thTd}>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {safetyTalks.map((talk) => (
              <tr key={talk.SafetyTalkID}>
                <td style={styles.thTd}>{talk.SafetyTalkDescription}</td>
                <td style={styles.thTd}>
                  {new Date(talk.DateAdded).toLocaleDateString()}
                </td>
                <td style={styles.thTd}>{talk.AddedByName}</td>
                <td
                  style={
                    talk.StatusID === 1
                      ? styles.activeStatus
                      : styles.inactiveStatus
                  }
                >
                  {talk.StatusID === 1 ? "Active" : "Inactive"}
                </td>
                <td style={styles.thTd}>
                  <button
                    className="view-button"
                    onClick={() => handleViewImages(talk)}
                  >
                    View Media
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Base64ImageAllFileModal
        open={open}
        onClose={() => setOpen(false)}
        fileUrl={`https://drive.google.com/file/d/${images}/preview`}
      />
    </div>
  );
};

const styles = {
  tableContainer: {
    //   margin: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  thTd: {
    padding: "8px",
    border: "1px solid #ddd",
    textAlign: "left", // Ensure all text is left-aligned
  },
  activeStatus: {
    color: "green",
    border: "1px solid #ddd", // Ensure border consistency
    padding: "8px", // Match padding with thTd
    textAlign: "center", // Center-align status text
  },
  inactiveStatus: {
    color: "red",
    border: "1px solid #ddd", // Ensure border consistency
    padding: "8px", // Match padding with thTd
    textAlign: "center", // Center-align status text
  },
  vehicleDropdown: {
    padding: "8px",
    border: "1px solid #ddd",
    width: "100%",
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  submitButton: {
    padding: "8px 16px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  cancelButton: {
    padding: "8px 16px",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft: "10px",
  },
  modalContent: {
    padding: "20px",
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
};

export default SafetyTalksPage;
