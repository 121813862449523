import React, { useEffect, useState } from "react";
import { getAccidentImages, getAccidentRecords } from "../../api/apiGetrequest";
import TableHeader from "../../Components/DataTable/TableHeader";
import MultiImageViewer from "../../Components/ImageViewer/MultiImageViewer";

const styles = {
  tableContainer: {
    // margin: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  thTd: {
    padding: "8px",
    border: "1px solid #ddd",
  },
  activeStatus: {
    color: "green",
  },
  inactiveStatus: {
    color: "red",
  },
  vehicleDropdown: {
    padding: "8px",
    border: "1px solid #ddd",
    width: "100%",
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  submitButton: {
    padding: "8px 16px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  cancelButton: {
    padding: "8px 16px",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft: "10px",
  },
  modalContent: {
    padding: "20px",
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
};

const AccidentsPage = () => {
  const [accidents, setAccidents] = useState([]);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [selected, setSelected] = useState(null);

  const handleViewImages = (row) => {
    setSelected(row);
    setOpen(true);
  };

  function formatDate(date) {
    return date.toISOString().split("T")[0];
  }

  const details = JSON.parse(localStorage.getItem("user"));

  const today = new Date();
  const last7Days = new Date(today);
  last7Days.setDate(today.getDate() - 14);
  const startdate = formatDate(last7Days);
  const enddate = formatDate(today);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAccidentRecords({
          UserID: details?.data?.UserID || 0,
          startDate: startdate,
          enddate: enddate,
        });
        setAccidents(result);
      } catch (error) {
        console.error("Error fetching accidents:", error);
      }
    };

    fetchData();
  }, [details?.data?.UserID, enddate, startdate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAccidentImages(selected?.AccidentID);
        setImages(result);
      } catch (error) {
        console.error("Error fetching accidents:", error);
      }
    };

    fetchData();
  }, [selected]);

  return (
    <div className="container" style={{ backgroundColor: "#f8f9fa", minHeight: "100vh" }}>
      <TableHeader
        title="Accident Records"
        showAddButton={false}
        showBackButton={false}
        showPrintButton={false}
        showExcelButton={false}
        showPdfButton={false}
      />
      <div className="table-wrapper" style={styles.tableContainer}>
        <table className="data-table" style={styles.table}>
          <thead>
            <tr>
              <th style={styles.thTd}>Vehicle Number</th>
              <th style={styles.thTd}>Description</th>
              <th style={styles.thTd}>Date</th>
              <th style={styles.thTd}>Location</th>
              <th style={styles.thTd}>Third Party Vehicle</th>
              <th style={styles.thTd}>Third Party Insurance</th>
              <th style={styles.thTd}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {accidents.map((accident) => (
              <tr key={accident.AccidentID}>
                <td style={styles.thTd}>{accident.VregNO}</td>
                <td style={styles.thTd}>{accident.AccidentDescription}</td>
                <td style={styles.thTd}>{new Date(accident.DateAdded).toLocaleDateString()}</td>
                <td style={styles.thTd}>{accident.AccidentLocation}</td>
                <td style={styles.thTd}>{accident.ThirdPartyVehicle}</td>
                <td style={styles.thTd}>{accident.ThirdPartyInsurance}</td>
                <td style={styles.thTd}>
                  <button
                    className="view-button"
                    onClick={() => handleViewImages(accident)}
                    style={{
                      backgroundColor: "#3182ce",
                      color: "white",
                      border: "none",
                      padding: "0.5rem 1rem",
                      borderRadius: "6px",
                      fontWeight: "500",
                      cursor: "pointer",
                      transition: "all 0.2s ease",
                    }}
                  >
                    View Images
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <MultiImageViewer open={open} onClose={() => setOpen(false)} images={images} />
    </div>
  );
};

export default AccidentsPage;
