import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getVehicleServiceStatus } from "../../api/apiGetrequest";
import { AddVehicleRepairRequest } from "../../api/apirequest";
import TruckLoader from "../../Components/ProgressBar/Loader";

// Placeholder for POST request to book service
const postServiceBooking = async (data) => {
  // This is where you would implement your POST request
  console.log("Booking service with data:", data);
  // Example:
  // const response = await fetch('/api/bookService', {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify(data),
  // });
  // return response.json();
};

const VehicleServiceCards = () => {
  const [activeFilter, setActiveFilter] = useState("All");
  const [vehicles, setVehicles] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [vehicleToBook, setVehicleToBook] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { result, isFetching } = await getVehicleServiceStatus();
        setVehicles(result || []);
        setIsFetching(isFetching);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    };

    fetchData();
  }, []);

  const filteredVehicles = vehicles.filter(
    (vehicle) =>
      activeFilter === "All" ||
      vehicle.ServiceStatus === activeFilter ||
      (activeFilter === "Booked" && vehicle.bookedDate)
  );

  const bookService = (vehicle) => {
    setVehicleToBook(vehicle);
    setSelectedDate("");
    setIsBookingModalOpen(true);
  };

  const user = JSON.parse(localStorage.getItem("user"));

  const confirmBooking = async () => {
    if (!vehicleToBook || !selectedDate) return;
    setLoading(true);

    const serviceData = {
      repairID: 0,
      vehicleID: vehicleToBook.VehicleID,
      repairDescription: "Routine Service",
      repairSource: "Service",
      sourceID: vehicleToBook.NextService,
      repairImageID: "",
      assignedTo: 0,
      mechNotes: "",
      repairCost: 0,
      dateAdded: new Date().toISOString(),
      bookedDate: selectedDate,
      dateClosed: null,
      statusID: 1,
      addedBy: user?.data?.userID,
    };

    try {
      // Post the request
      const response = await AddVehicleRepairRequest(serviceData);

      if (response && response.success) {
        toast.success(response.message || "Repair request successfully added!");
      } else {
        toast.error(
          response.message ||
            "An error occurred while adding the service booking."
        );
      }
    } catch (error) {
      console.error("Failed to book service:", error);
      toast.error("An unexpected error occurred. Please try again.");
      setLoading(false);
    } finally {
      setIsBookingModalOpen(false);
      setLoading(false);
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  if (isFetching) {
    return <TruckLoader />;
  }

  return (
    <div
      style={{
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        padding: "16px",
      }}
    >
      <style>
        {`
            .tabs {
              display: flex;
              gap: 8px;
              margin-bottom: 16px;
              overflow-x: auto;
              padding-bottom: 8px;
            }
            
            .tab {
              padding: 8px 16px;
              border-radius: 20px;
              border: none;
              cursor: pointer;
              font-weight: 500;
              white-space: nowrap;
              transition: all 0.2s;
            }
            
            .tab.active {
              background: #4a90e2;
              color: white;
            }
            
            .tab:not(.active) {
              background: #e0e0e0;
              color: #333;
            }
            
            .card {
              background: white;
              border-radius: 12px;
              padding: 16px;
              margin-bottom: 16px;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            }
            
            .card-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 12px;
            }
            
            .vehicle-number {
              font-size: 18px;
              font-weight: 600;
              color: #333;
            }
            
            .status-badge {
              padding: 6px 12px;
              border-radius: 20px;
              font-size: 14px;
              font-weight: 500;
            }
            
            .status-badge.due {
              background: #ffe4e4;
              color: #d32f2f;
            }

            .status-badge.booked {
              background: #ffe4e4;
              color:rgb(35, 20, 236);
            }
            
            .status-badge.okay {
              background: #e8f5e9;
              color: #2e7d32;
            }
            
            .info-row {
              display: flex;
              justify-content: space-between;
              margin-bottom: 8px;
              font-size: 14px;
            }
            
            .label {
              color: #666;
            }
            
            .value {
              font-weight: 500;
              color: #333;
            }

            .modal {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: rgba(0, 0, 0, 0.7);
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .modal-content {
              background: #fff;
              padding: 20px;
              border-radius: 8px;
              box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
              max-width: 400px;
              width: 90%;
              text-align: center;
            }

            .modal-content h3 {
              margin-bottom: 20px;
              color: #333;
            }

            .modal-content input[type="date"] {
              width: 100%;
              padding: 8px;
              margin-bottom: 20px;
              border: 1px solid #ddd;
              border-radius: 4px;
              font-size: 16px;
            }

            .modal-content button {
              padding: 10px 20px;
              margin: 0 5px;
              border: none;
              border-radius: 4px;
              color: white;
              font-size: 16px;
              cursor: pointer;
              transition: background 0.3s ease;
            }

            .modal-content button:hover {
              opacity: 0.9;
            }

            .modal-content button:first-of-type {
              background: #ccc;
            }

            .modal-content button:last-of-type {
              background: #4a90e2;
            }

            .modal-content button:disabled {
              background: #ddd;
              cursor: not-allowed;
            }
          `}
      </style>

      <h1
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          marginBottom: "20px",
          color: "#333",
        }}
      >
        Vehicle Service Status
      </h1>

      <div className="tabs">
        {["All", "Due", "Okay", "Booked"].map((filter) => (
          <button
            key={filter}
            className={`tab ${activeFilter === filter ? "active" : ""}`}
            onClick={() => setActiveFilter(filter)}
          >
            {filter}
          </button>
        ))}
      </div>

      {filteredVehicles.map((vehicle) => (
        <div key={vehicle.VehicleID} className="card">
          <div className="card-header">
            <span className="vehicle-number">{vehicle.VregNO}</span>
            <span
              className={`status-badge ${
                vehicle.ServiceStatus ? vehicle.ServiceStatus.toLowerCase() : ""
              }`}
            >
              {vehicle.BookedDate
                ? "Booked"
                : vehicle.ServiceStatus || "Unknown"}
            </span>
          </div>

          <div className="info-row">
            <span className="label">Next Service:</span>
            <span className="value">
              {vehicle.NextService?.toLocaleString()} km
            </span>
          </div>

          <div className="info-row">
            <span className="label">Current Mileage:</span>
            <span className="value">
              {vehicle.CurrentMileage?.toLocaleString()} km
            </span>
          </div>

          <div className="info-row">
            <span className="label">Service Interval:</span>
            <span className="value">
              {vehicle.ServiceKM?.toLocaleString()} km
            </span>
          </div>
          <div className="info-row">
            <span className="label" style={{ fontWeight: "bold" }}>
              KM Remaining:
            </span>
            <span className="value" style={{ fontWeight: "bold" }}>
              {(vehicle.NextService - vehicle.CurrentMileage)?.toLocaleString()}{" "}
              km
            </span>
          </div>
          {vehicle.BookedDate && (
            <div className="info-row">
              <label className="label" style={{ fontWeight: "bold" }}>
                Booked Date:
              </label>
              <span className="value" style={{ fontWeight: "bold" }}>
                {new Date(vehicle.BookedDate).toLocaleString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </div>
          )}
          {vehicle.ServiceStatus === "Due" && !vehicle.BookedDate && (
            <button
              style={{
                marginTop: "10px",
                padding: "8px 16px",
                background: "#4a90e2",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => bookService(vehicle)}
            >
              Book Service
            </button>
          )}
        </div>
      ))}

      {isBookingModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Book Service for {vehicleToBook?.VregNO}</h3>
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              min={new Date().toISOString().split("T")[0]}
            />
            <div>
              <button onClick={() => setIsBookingModalOpen(false)}>
                Cancel
              </button>
              <button
                onClick={confirmBooking}
                disabled={!selectedDate || loading}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleServiceCards;
