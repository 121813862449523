import { useContext, useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./app.scss";
import { ColorContext } from "./ColorContext/darkContext";
import BottomNavigation from "./Components/Navbar/AppBar";
import Navbar from "./Components/Navbar/Navbar";
import Sidebar from "./Components/Sidebar/Sidebar";
import AccidentsPage from "./Pages/Accident/Accident";
import AddNew from "./Pages/AddNew/AddNew";
import Lists from "./Pages/Allocation/AllocationList";
import CustomerTable from "./Pages/Customers/Customers";
import AdminDashboard from "./Pages/DashBoard/AdminDashBoard";
import Detail from "./Pages/Detail/Detail";
import DriverAccidents from "./Pages/DriverApp/Accidents";
import DriverDashboard from "./Pages/DriverApp/DashBoard";
import DriverMaintenaince from "./Pages/DriverApp/Maintenaince";
import DriverOrders from "./Pages/DriverApp/Orders";
import DriverSafety from "./Pages/DriverApp/Safety";
import Home from "./Pages/Home/home";
import Login from "./Pages/Login/Login";
import VehicleChecklistApp from "./Pages/Maintenance/CheckList";
import Maintenance from "./Pages/Maintenance/Maintenance";
import VehicleCard from "./Pages/MechanicApp/CheckList";
import MechanicDashboard from "./Pages/MechanicApp/Dashboard";
import FloatingActionButton from "./Pages/MechanicApp/FloatingButton";
import MechanicMaintenaince from "./Pages/MechanicApp/MainMaintenance";
import VehicleServiceCards from "./Pages/MechanicApp/VehicleServiceStatus";
import SafetyTalksPage from "./Pages/Safety/Safety";
import UserTable from "./Pages/Users/Users";

// Admin Layout Component
function AdminLayout() {
  const { darkMode } = useContext(ColorContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`admin-layout ${darkMode ? "dark" : ""}`}>
      <div className="dashboard">
        {!isMobile && <Sidebar />}

        <div className="content-wrapper">
          <Navbar />
          <ToastContainer />
          <main className="main-content">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
}

// Driver Layout Component
function DriverLayout() {
  const { darkMode } = useContext(ColorContext);
  const [activeTab, setActiveTab] = useState("");

  return (
    <div className={`driver-layout ${darkMode ? "dark" : ""}`}>
      <div className="dashboard driver-dashboard">
        <Navbar isDriverLayout={true} />
        <div className="content-wrapper">
          <ToastContainer />
          <main className="main-content">
            <Outlet activeTab={activeTab} />
          </main>
          <div>
            <BottomNavigation
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

// Mechanic Layout Component
function MechanicLayout() {
  const { darkMode } = useContext(ColorContext);
  const [activeTab, setActiveTab] = useState("");

  return (
    <div className={`mechanic-layout ${darkMode ? "dark" : ""}`}>
      <div className="dashboard driver-dashboard">
        <Navbar isMechanicLayout={true} />
        <div className="content-wrapper">
          <ToastContainer />
          <main className="main-content">
            <Outlet activeTab={activeTab} />
          </main>
          <FloatingActionButton />
          {/* <div>
            <BottomNavigation
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

function App() {
  const { darkMode } = useContext(ColorContext);

  return (
    <div className={darkMode ? "App dark" : "App"}>
      <BrowserRouter>
        <Routes>
          {/* Public home route */}
          <Route path="/" element={<Navigate to="/login" replace />} />

          {/* Login Route */}
          <Route path="login" element={<Login />} />

          {/* Driver Routes */}
          <Route path="driver" element={<DriverLayout />}>
            <Route index element={<DriverDashboard />} />
            <Route path="dashboard" element={<DriverDashboard />} />
            <Route path="accidents" element={<DriverAccidents />} />
            <Route path="maintenance" element={<DriverMaintenaince />} />
            <Route path="orders" element={<DriverOrders />} />
            <Route path="safety" element={<DriverSafety />} />
          </Route>

          {/* Mechanic Routes */}
          <Route path="mechanic" element={<MechanicLayout />}>
            <Route index element={<MechanicDashboard />} />
            <Route path="dashboard" element={<MechanicDashboard />} />
            <Route path="accidents" element={<DriverAccidents />} />
            <Route path="maintenance" element={<MechanicMaintenaince />} />
            <Route path="checklist" element={<VehicleCard />} />
            <Route path="vehicle-service" element={<VehicleServiceCards />} />
          </Route>

          {/* Admin Routes */}
          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<Home />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="users" element={<UserTable />} />
            <Route path="customers" element={<CustomerTable />} />
            <Route path="vehicle" element={<CustomerTable />} />
            <Route path="maintenance" element={<Maintenance />} />
            <Route path="checklist" element={<VehicleChecklistApp />} />
            <Route path="accident" element={<AccidentsPage />} />
            <Route path="safety" element={<SafetyTalksPage />} />
            <Route path="orders" element={<Lists />} />
            {/* Products Routes */}
            <Route path="products">
              <Route index element={<Lists type="product" />} />
              <Route path=":productId" element={<Detail />} />
              <Route
                path="addnew"
                element={<AddNew title="Add New Product" type="PRODUCT" />}
              />
            </Route>
          </Route>

          {/* Catch all route */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
