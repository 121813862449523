const ExecutiveDashboard = () => {
  const styles = {
    container: {
      padding: '32px',
      background: '#ffffff',
      minHeight: '100vh',
      fontFamily: 'Inter, system-ui, sans-serif'
    },
    headerTitle: {
      fontSize: '28px',
      fontWeight: '600',
      color: '#111827',
      marginBottom: '32px'
    },
    metricsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
      gap: '24px'
    },
    card: {
      background: '#ffffff',
      borderRadius: '12px',
      padding: '24px',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)',
      border: '1px solid rgba(0, 0, 0, 0.05)',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      ':hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)'
      }
    },
    cardTitle: {
      color: '#6B7280',
      fontSize: '13px',
      textTransform: 'uppercase',
      letterSpacing: '0.5px',
      fontWeight: '600',
      marginBottom: '20px'
    },
    mainMetric: {
      display: 'flex',
      alignItems: 'baseline',
      marginBottom: '24px'
    },
    mainNumber: {
      fontSize: '32px',
      fontWeight: '700',
      color: '#111827'
    },
    unit: {
      fontSize: '15px',
      color: '#6B7280',
      marginLeft: '4px'
    },
    subMetricsGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '16px'
    },
    subMetric: {
      background: '#F9FAFB',
      padding: '12px',
      borderRadius: '8px'
    },
    subMetricNumber: {
      fontSize: '20px',
      fontWeight: '600',
      color: '#111827',
      marginBottom: '4px'
    },
    subMetricLabel: {
      fontSize: '12px',
      color: '#6B7280',
      fontWeight: '500'
    }
  };

  const metrics = {
    accidents: { total: 15, thisMonth: 2, trend: -5, costImpact: 250000 },
    fleet: { totalVehicles: 45, activeVehicles: 42, utilization: 93.3, fuelEfficiency: 92, maintenanceDue: 3, nextService: '2024-01-15' },
    operations: { activeOrders: 156, onTimeDelivery: 98, totalRevenue: 1250000, fuelCosts: 180000 },
    compliance: { inspectionsDue: 5, nextInspection: '2024-01-15', complianceRate: 96, openIssues: 2 },
    drivers: { total: 52, licenseRenewalDue: 3, newHires: 4, inTraining: 2, nextRenewal: '2024-01-10' },
    maintenance: { monthlyBudget: 100000, currentSpend: 85000, plannedMaintenance: 45000, unplannedRepairs: 40000, nextServices: 8 }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.headerTitle}>Fleet Performance Dashboard</h1>
      
      <div style={styles.metricsGrid}>
        {/* Revenue Card */}
        <div style={styles.card}>
          <div style={styles.cardTitle}>Revenue & Operations</div>
          <div style={styles.mainMetric}>
            <div style={styles.mainNumber}>${(metrics.operations.totalRevenue/1000000).toFixed(2)}M</div>
          </div>
          <div style={styles.subMetricsGrid}>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{metrics.operations.activeOrders}</div>
              <div style={styles.subMetricLabel}>Active Orders</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{metrics.operations.onTimeDelivery}%</div>
              <div style={styles.subMetricLabel}>On-Time Rate</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>${(metrics.operations.fuelCosts/1000).toFixed(0)}k</div>
              <div style={styles.subMetricLabel}>Fuel Costs</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>${((metrics.operations.totalRevenue - metrics.operations.fuelCosts)/1000).toFixed(0)}k</div>
              <div style={styles.subMetricLabel}>Net Revenue</div>
            </div>
          </div>
        </div>

        {/* Fleet Card */}
        <div style={styles.card}>
          <div style={styles.cardTitle}>Fleet Status</div>
          <div style={styles.mainMetric}>
            <div style={styles.mainNumber}>{metrics.fleet.utilization}%</div>
            <div style={styles.unit}>Utilization</div>
          </div>
          <div style={styles.subMetricsGrid}>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{metrics.fleet.activeVehicles}/{metrics.fleet.totalVehicles}</div>
              <div style={styles.subMetricLabel}>Active Vehicles</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{metrics.fleet.fuelEfficiency}%</div>
              <div style={styles.subMetricLabel}>Fuel Efficiency</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{metrics.fleet.maintenanceDue}</div>
              <div style={styles.subMetricLabel}>Due Service</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{metrics.maintenance.nextServices}</div>
              <div style={styles.subMetricLabel}>Scheduled</div>
            </div>
          </div>
        </div>

        {/* Maintenance Card */}
        <div style={styles.card}>
          <div style={styles.cardTitle}>Maintenance & Budget</div>
          <div style={styles.mainMetric}>
            <div style={styles.mainNumber}>${(metrics.maintenance.currentSpend/1000).toFixed(0)}k</div>
            <div style={styles.unit}>/ ${(metrics.maintenance.monthlyBudget/1000).toFixed(0)}k</div>
          </div>
          <div style={styles.subMetricsGrid}>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>${(metrics.maintenance.plannedMaintenance/1000).toFixed(0)}k</div>
              <div style={styles.subMetricLabel}>Planned</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>${(metrics.maintenance.unplannedRepairs/1000).toFixed(0)}k</div>
              <div style={styles.subMetricLabel}>Unplanned</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{((metrics.maintenance.currentSpend/metrics.maintenance.monthlyBudget) * 100).toFixed(0)}%</div>
              <div style={styles.subMetricLabel}>Budget Used</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>${((metrics.maintenance.monthlyBudget - metrics.maintenance.currentSpend)/1000).toFixed(0)}k</div>
              <div style={styles.subMetricLabel}>Remaining</div>
            </div>
          </div>
        </div>

        {/* Safety Card */}
        <div style={styles.card}>
          <div style={styles.cardTitle}>Safety & Compliance</div>
          <div style={styles.mainMetric}>
            <div style={styles.mainNumber}>{metrics.compliance.complianceRate}%</div>
            <div style={styles.unit}>Compliance Rate</div>
          </div>
          <div style={styles.subMetricsGrid}>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{metrics.accidents.thisMonth}</div>
              <div style={styles.subMetricLabel}>Incidents</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{metrics.compliance.openIssues}</div>
              <div style={styles.subMetricLabel}>Open Issues</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{metrics.compliance.inspectionsDue}</div>
              <div style={styles.subMetricLabel}>Due Inspections</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>${(metrics.accidents.costImpact/1000).toFixed(0)}k</div>
              <div style={styles.subMetricLabel}>Incident Cost</div>
            </div>
          </div>
        </div>

        {/* Workforce Card */}
        <div style={styles.card}>
          <div style={styles.cardTitle}>Workforce Status</div>
          <div style={styles.mainMetric}>
            <div style={styles.mainNumber}>{metrics.drivers.total}</div>
            <div style={styles.unit}>Total Drivers</div>
          </div>
          <div style={styles.subMetricsGrid}>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{metrics.drivers.newHires}</div>
              <div style={styles.subMetricLabel}>New Hires</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{metrics.drivers.inTraining}</div>
              <div style={styles.subMetricLabel}>In Training</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{metrics.drivers.licenseRenewalDue}</div>
              <div style={styles.subMetricLabel}>License Renewal</div>
            </div>
            <div style={styles.subMetric}>
              <div style={styles.subMetricNumber}>{(metrics.drivers.total - metrics.drivers.inTraining - metrics.drivers.licenseRenewalDue)}</div>
              <div style={styles.subMetricLabel}>Active Drivers</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutiveDashboard;