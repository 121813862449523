import html2pdf from 'html2pdf.js';
import React, { useEffect, useRef, useState } from "react";
import { getCheckListReport } from "../../api/apiGetrequest";

const styles = {
  container: {
    margin: "0 auto",
    padding: "24px",
    backgroundColor: "#f9fafb",
  },
  header: {
    marginBottom: "32px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#1f2937",
    marginBottom: "8px",
  },
  complianceDashboard: {
    backgroundColor: "#eff6ff",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
  },
  complianceGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gap: "16px",
  },
  complianceCard: {
    textAlign: "center",
  },
  complianceDate: {
    fontSize: "14px",
    color: "#4b5563",
    marginBottom: "4px",
  },
  complianceValue: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "4px",
  },
  complianceLabel: {
    fontSize: "12px",
    color: "#6b7280",
  },
  controls: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
    marginBottom: "24px",
    alignItems: "flex-end",
  },
  dateGroup: {
    display: "flex",
    gap: "16px",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
  },
  inputLabel: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#374151",
    marginBottom: "4px",
  },
  dateInput: {
    padding: "8px 12px",
    border: "1px solid #d1d5db",
    borderRadius: "6px",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
    outline: "none",
  },
  buttonGroup: {
    display: "flex",
    gap: "16px",
  },
  button: {
    padding: "8px 16px",
    borderRadius: "6px",
    fontWeight: "500",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.2s",
    color: "white",
  },
  printButton: {
    backgroundColor: "#2563eb",
  },
  exportButton: {
    backgroundColor: "#059669",
  },
  tableContainer: {
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    backgroundColor: "#f3f4f6",
    padding: "16px 24px",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "600",
    color: "#374151",
    borderBottom: "1px solid #e5e7eb",
  },
  td: {
    padding: "16px 24px",
    borderBottom: "1px solid #e5e7eb",
    fontSize: "14px",
  },
  vehicleCell: {
    fontWeight: "500",
    color: "#111827",
  },
  statusCell: {
    textAlign: "center",
  },
  checkmark: {
    color: "#059669",
    fontSize: "20px",
  },
  xmark: {
    color: "#dc2626",
    fontSize: "20px",
  },
  actionedBy: {
    fontSize: "12px",
    color: "#6b7280",
    marginTop: "4px",
  },
  row: {
    "&:nth-child(even)": {
      backgroundColor: "#f9fafb",
    },
    "&:hover": {
      backgroundColor: "#f3f4f6",
    },
  },
  highCompliance: {
    color: "#059669",
  },
  mediumCompliance: {
    color: "#d97706",
  },
  lowCompliance: {
    color: "#dc2626",
  },
};

const VehicleChecklistTable = () => {
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const componentRef = useRef();

  function formatDate(date) {
    return date.toISOString().split("T")[0];
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getCheckListReport(
          formatDate(startDate),
          formatDate(endDate),
          0
        );
        setData(result);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const dates = [...new Set(data.map((item) => item.Date.split("T")[0]))].sort();
  const vehicles = [
    ...new Set(
      data.flatMap((item) => item.Vehicles.map((v) => v.VehicleRegistration))
    ),
  ].sort();

  const getVehicleStatus = (vehicleReg, date) => {
    const dayData = data.find((d) => d.Date.split("T")[0] === date);
    if (!dayData) return null;

    const vehicle = dayData.Vehicles.find(
      (v) => v.VehicleRegistration === vehicleReg
    );
    return vehicle
      ? { status: vehicle.Status, actionedBy: vehicle.ActionedBy }
      : null;
  };

  const calculateCompliance = (vehicles, date) => {
    if (!vehicles || vehicles.length === 0) return 0;
    const completedVehicles = vehicles.filter((vehicleReg) => {
      const status = getVehicleStatus(vehicleReg, date);
      return status?.status === "done";
    });
    return Math.round((completedVehicles.length / vehicles.length) * 100);
  };

  const getComplianceColor = (compliance) => {
    if (compliance >= 80) return styles.highCompliance;
    if (compliance >= 50) return styles.mediumCompliance;
    return styles.lowCompliance;
  };

  const handleDateChange = (event, setter) => {
    setter(new Date(event.target.value));
  };

  const handlePrint = () => {
    window.print();
  };

  const exportToPdf = async () => {
    try {
      setIsExporting(true);
      
      // Create a new container for the PDF content
      const pdfContainer = document.createElement('div');
      pdfContainer.style.backgroundColor = 'white';
      pdfContainer.style.padding = '20px';
      
      // Add title and date range
      const header = document.createElement('div');
      header.innerHTML = `
        <h1 style="font-size: 24px; margin-bottom: 16px;">Vehicle Checklist Status</h1>
        <p style="margin-bottom: 16px;">Period: ${formatDate(startDate)} to ${formatDate(endDate)}</p>
      `;
      pdfContainer.appendChild(header);

      // Clone the table content
      const tableContent = componentRef.current.querySelector('.table-wrapper').cloneNode(true);
      
      // Adjust table styles for PDF
      const table = tableContent.querySelector('table');
      if (table) {
        table.style.width = '100%';
        table.style.fontSize = '10px';
        table.style.borderCollapse = 'collapse';
        
        // Style table cells
        const cells = table.querySelectorAll('td, th');
        cells.forEach(cell => {
          cell.style.border = '1px solid #e5e7eb';
          cell.style.padding = '8px';
        });

        // Adjust checkmark and cross symbols
        const checkmarks = table.querySelectorAll('.checkmark');
        const xmarks = table.querySelectorAll('.xmark');
        checkmarks.forEach(mark => {
          mark.style.color = '#059669';
          mark.style.fontSize = '14px';
        });
        xmarks.forEach(mark => {
          mark.style.color = '#dc2626';
          mark.style.fontSize = '14px';
        });
      }
      
      pdfContainer.appendChild(tableContent);

      // Configure PDF options
      const options = {
        margin: [15, 10],
        filename: `Vehicle_Checklist_${formatDate(startDate)}_to_${formatDate(endDate)}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { 
          scale: 2,
          logging: false,
          useCORS: true,
          letterRendering: true
        },
        jsPDF: { 
          unit: 'mm', 
          format: 'a3', 
          orientation: 'landscape'
        }
      };

      // Temporarily append the container to the document
      document.body.appendChild(pdfContainer);
      
      // Generate PDF
      await html2pdf()
        .set(options)
        .from(pdfContainer)
        .save();

      // Clean up
      document.body.removeChild(pdfContainer);
      setIsExporting(false);
    } catch (error) {
      console.error('Error generating PDF:', error);
      setIsExporting(false);
      alert('Failed to generate PDF. Please try again.');
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>Vehicle Checklist Status</h1>
      </div>

      <div style={styles.controls}>
        <div style={styles.dateGroup}>
          <div style={styles.inputGroup}>
            <label style={styles.inputLabel}>Start Date</label>
            <input
              type="date"
              value={formatDate(startDate)}
              onChange={(e) => handleDateChange(e, setStartDate)}
              style={styles.dateInput}
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.inputLabel}>End Date</label>
            <input
              type="date"
              value={formatDate(endDate)}
              onChange={(e) => handleDateChange(e, setEndDate)}
              style={styles.dateInput}
            />
          </div>
        </div>
        <div style={styles.buttonGroup}>
          <button
            onClick={handlePrint}
            style={{ ...styles.button, ...styles.printButton }}
          >
            Print Report
          </button>
          <button
            onClick={exportToPdf}
            disabled={isExporting}
            style={{ ...styles.button, ...styles.exportButton }}
          >
            {isExporting ? "Exporting PDF..." : "Export PDF"}
          </button>
        </div>
      </div>

      <div style={styles.tableContainer} ref={componentRef}>
        <div style={styles.tableWrapper} className="table-wrapper">
          <table style={styles.table}>
            <thead>
              <tr>
                <th rowSpan={2} style={styles.th}>
                  Vehicle Reg
                </th>
                {dates.map((date) => (
                  <th key={date} style={{ ...styles.th, textAlign: "center" }}>
                    {date}
                  </th>
                ))}
              </tr>
              <tr>
                {dates.map((date) => {
                  const compliance = calculateCompliance(vehicles, date);
                  return (
                    <th key={date} style={styles.th}>
                      <div style={styles.complianceCard}>
                        <div
                          style={{
                            ...styles.complianceValue,
                            ...getComplianceColor(compliance),
                          }}
                        >
                          {compliance}%
                        </div>
                        <div style={styles.complianceLabel}>Compliance</div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {vehicles.map((vehicleReg) => (
                <tr key={vehicleReg} style={styles.row}>
                  <td style={{ ...styles.td, ...styles.vehicleCell }}>
                    {vehicleReg}
                  </td>
                  {dates.map((date) => {
                    const status = getVehicleStatus(vehicleReg, date);
                    return (
                      <td
                        key={`${vehicleReg}-${date}`}
                        style={{ ...styles.td, ...styles.statusCell }}
                      >
                        {status?.status === "done" ? (
                          <>
                            <div style={styles.checkmark} className="checkmark">✓</div>
                            <div style={styles.actionedBy}>
                              By: {status.actionedBy}
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={styles.xmark} className="xmark">✗</div>
                            <div style={styles.actionedBy}>Not Actioned</div>
                          </>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VehicleChecklistTable;