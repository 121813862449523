import React, { useEffect, useState } from "react";
import { getVehicleChecklist } from "../../api/apiGetrequest";
import TableHeader from "../../Components/DataTable/TableHeader";
import VehicleChecklistTable from "./ChecklistDailyReport";
import VehicleChecklist from "./ChecklistDetail";

// Status color configurations
const statusStyles = {
  Pending: {
    backgroundColor: "#FEF3C7",
    color: "#92400E",
    borderColor: "#FCD34D",
    pillColor: "#F59E0B",
  },
  Done: {
    backgroundColor: "#DCFCE7",
    color: "#166534",
    borderColor: "#86EFAC",
    pillColor: "#22C55E",
  },
  Okay: {
    backgroundColor: "#E0F2FE",
    color: "#075985",
    borderColor: "#7DD3FC",
    pillColor: "#0EA5E9",
  },
  Faulty: {
    backgroundColor: "#FEE2E2",
    color: "#991B1B",
    borderColor: "#FECACA",
    pillColor: "#EF4444",
  },
};

function InputSection({ label, value, onChange, type = "text" }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "10px",
      }}
    >
      <div
        style={{
          backgroundColor: "#f0f0f0",
          padding: "8px",
          fontWeight: "bold",
          textTransform: "uppercase",
          borderBottom: "2px solid #333",
          marginBottom: "5px",
        }}
      >
        {label}
      </div>
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #333",
          borderRadius: "4px",
          backgroundColor: "#fff",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      />
    </div>
  );
}

function VehicleChecklistApp() {
  const [currentVehicle, setCurrentVehicle] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterVehicleStatus, setFilterVehicleStatus] = useState("all");
  const [maintenanceReports, setMaintenanceReports] = useState([]);
  const [showReport, setShowReport] = useState(false);

  function formatDate(date) {
    return date.toISOString().split("T")[0];
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getVehicleChecklist(
          formatDate(new Date()),
          formatDate(new Date()),
          0
        );
        setMaintenanceReports(result);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    };

    fetchData();
  }, []);

  const filteredVehicles = maintenanceReports?.filter((vehicle) => {
    const matchesSearch = vehicle.VregNO?.toLowerCase().includes(
      searchTerm?.toLowerCase()
    );
    const matchesStatus =
      filterStatus === "all" || vehicle.status === filterStatus;
    const matchesVehicleStatus =
      filterVehicleStatus === "all" ||
      vehicle.VehicleStatus === filterVehicleStatus ||
      vehicle?.StatusName === filterVehicleStatus;

    return matchesSearch && matchesStatus && matchesVehicleStatus;
  });

  if (showReport) {
    return (
      <>
        <TableHeader
          title="Vehicle CheckList"
          showAddButton={true}
          buttonName={"Show List"}
          onAdd={() => setShowReport(!showReport)}
          showBackButton={false}
          showPrintButton={true}
          showExcelButton={true}
          showPdfButton={true}
        />
        <VehicleChecklistTable />
      </>
    );
  }

  return (
    <>
      <TableHeader
        title="Vehicle CheckList"
        showAddButton={true}
        buttonName={"Show Report"}
        onAdd={() => setShowReport(!showReport)}
        showBackButton={false}
        showPrintButton={true}
        showExcelButton={true}
        showPdfButton={true}
      />
      <div style={{ display: "flex", fontFamily: "Arial, sans-serif" }}>
        <div
          style={{
            width: "30%",
            padding: "20px",
            borderRight: "1px solid #ccc",
            height: "100vh",
            overflowY: "auto",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <InputSection
              label="Search Vehicles"
              value={searchTerm}
              onChange={setSearchTerm}
            />

            <div style={{ marginBottom: "10px" }}>
              <div>
                Filter Vehicle Status:
                <select
                  value={filterVehicleStatus}
                  onChange={(e) => setFilterVehicleStatus(e.target.value)}
                  style={{ marginLeft: "10px", padding: "5px" }}
                >
                  <option value="all">All</option>
                  <option value="Okay">Okay</option>
                  <option value="Faulty">Faulty</option>
                  <option value="Done">Complete</option>
                  <option value="Pending">Pending</option>
                </select>
              </div>
            </div>
          </div>

          <div>
            {filteredVehicles.length === 0 ? (
              <div>No vehicles found</div>
            ) : (
              filteredVehicles.map((vehicle) => {
                const status = vehicle.StatusName;
                const style = statusStyles[status] || statusStyles.Pending;

                return (
                  <div
                    key={vehicle.VehicleID}
                    style={{
                      border: `1px solid ${style.borderColor}`,
                      padding: "10px",
                      marginBottom: "10px",
                      backgroundColor: style.backgroundColor,
                      borderRadius: "6px",
                    }}
                  >
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                      Reg No: {vehicle.VregNO}
                    </div>
                    <div>Inspector: {vehicle.AddedByFullName}</div>
                    <div>
                      Date: {new Date(vehicle.DateAdded).toLocaleString()}
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        padding: "4px 12px",
                        borderRadius: "20px",
                        backgroundColor: style.pillColor,
                        color: "white",
                        fontWeight: "bold",
                        marginTop: "8px",
                      }}
                    >
                      {status}
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      {vehicle?.StatusName !== "Pending" && (
                        <button
                          type="submit"
                          onClick={() => setCurrentVehicle(vehicle)}
                          style={{
                            backgroundColor: "#2563EB",
                            color: "white",
                            padding: "8px 16px",
                            borderRadius: "4px",
                            border: "none",
                            cursor: "pointer",
                            fontWeight: "500",
                          }}
                        >
                          View
                        </button>
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>

        {currentVehicle ? (
          <VehicleChecklist initialData={currentVehicle} />
        ) : (
          <div style={{ padding: "20px" }}>No vehicle selected</div>
        )}
      </div>
    </>
  );
}

export default VehicleChecklistApp;
