import { useState } from 'react';

const Base64ImageModal = ({ base64Image, name, open, onClose }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  if (!open) return null; // Don't render modal if not open

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div style={styles.overlay}>
      <div style={{ ...styles.modal, ...(isFullscreen ? styles.fullscreenModal : {}) }}>
        <button style={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        <button style={styles.fullscreenButton} onClick={toggleFullscreen}>
          {isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
        </button>
        {base64Image && (
          <iframe
            src={`https://drive.google.com/file/d/${base64Image}/preview`}
            width="100%"
            height={isFullscreen ? "100%" : "400px"}
            title={name}
            style={styles.image}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    position: "relative",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    maxWidth: "90%",
    width: "90%",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    transition: "all 0.3s ease",
  },
  fullscreenModal: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: 0,
    borderRadius: 0,
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "1.5rem",
    cursor: "pointer",
    zIndex: 1001,
  },
  fullscreenButton: {
    position: "absolute",
    top: "10px",
    left: "10px",
    background: "none",
    border: "none",
    fontSize: "1rem",
    cursor: "pointer",
    color: "#007bff",
    zIndex: 1001,
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "8px",
    transition: "all 0.3s ease",
  },
};

export default Base64ImageModal;
