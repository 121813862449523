import React, { useState } from "react";
import { toast } from "react-toastify";
import { UpdateVehicleRepair } from "../../api/apirequest";

const RepairCardsView = ({ repairs, mechanics, fetchRepairs }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRepair, setSelectedRepair] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateForm, setUpdateForm] = useState({
    mechNotes: "",
    repairCost: 0,
    assignedTo: "",
    statusID: "",
    BookedDate: "",
    ReceivedDate: "",
    ReleaseDate: "",
    ReleaseNote: "",
    DateClosed: "",
  });

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleRepairAction = (repair, action) => {
    setSelectedRepair(repair);
    switch (action) {
      case "assign":
        setUpdateForm({
          ...updateForm,
          statusID: 2,
          assignedTo: "",
        });
        break;
      case "review":
        setUpdateForm({
          ...updateForm,
          statusID: 3,
        });
        break;
      case "approve":
        setUpdateForm({
          ...updateForm,
          statusID: 4,
        });
        break;
      case "book":
        setUpdateForm({
          ...updateForm,
          statusID: 5,
        });
        break;
      case "receive":
        setUpdateForm({
          ...updateForm,
          statusID: 6,
          ReceivedDate: new Date().toISOString().split("T")[0],
        });
        break;
      case "release":
        setUpdateForm({
          ...updateForm,
          statusID: 7,
          ReleaseDate: new Date().toISOString().split("T")[0],
        });
        break;
      case "close":
        setUpdateForm({
          ...updateForm,
          statusID: 8,
          DateClosed: new Date().toISOString().split("T")[0],
        });
        break;
    }
    setShowModal(true);
  };

  const handleUpdateSubmit = async () => {
    setLoading(true);
    try {
      const result = await UpdateVehicleRepair({
        repairID: selectedRepair.RepairID,
        assignedTo: updateForm.assignedTo || selectedRepair.AssignedTo,
        mechNotes: updateForm.mechNotes,
        repairCost: updateForm.repairCost,
        statusID: updateForm.statusID || selectedRepair.StatusID,
        BookedDate: updateForm.BookedDate || selectedRepair.BookedDate,
        ReleaseDate: updateForm.ReleaseDate || selectedRepair.ReleaseDate,
        DateClosed: updateForm.DateClosed || selectedRepair.DateClosed,
        ReceivedDate: updateForm.ReceivedDate || selectedRepair.ReceivedDate,
        ReleaseNote: updateForm.ReleaseNote || selectedRepair.ReleaseNote,
      });

      if (result.success) {
        setShowModal(false);
        fetchRepairs();
        toast.success("Added successfully");
      } else {
        toast.error(result.message || "Update failed");
      }
    } catch (error) {
      toast.error("Failed to update repair");
    }
    setLoading(false);
  };

  const styles = {
    container: {
      // padding: "24px",
      backgroundColor: "#f8f9fa",
    },
    cardsContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
      gap: "20px",
      padding: "20px",
    },
    card: {
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    regNo: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#2d3748",
    },
    description: {
      whiteSpace: "pre-line",
      color: "#4a5568",
      fontSize: "14px",
      marginBottom: "12px",
    },
    statusOpen: {
      backgroundColor: "#fef3c7",
      color: "#92400e",
      padding: "4px 8px",
      borderRadius: "12px",
      fontSize: "12px",
      fontWeight: "600",
    },
    statusClosed: {
      backgroundColor: "#dcfce7",
      color: "#166534",
      padding: "4px 8px",
      borderRadius: "12px",
      fontSize: "12px",
      fontWeight: "600",
    },
    info: {
      fontSize: "14px",
      color: "#718096",
    },
    actionButton: {
      padding: "8px 16px",
      backgroundColor: "#3182ce",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "14px",
      marginTop: "8px",
    },
    modal: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      padding: "24px",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
      width: "90%",
      maxWidth: "500px",
      maxHeight: "90vh",
      overflow: "auto",
      zIndex: 1000,
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 999,
    },
    formGroup: {
      marginBottom: "16px",
    },
    label: {
      display: "block",
      marginBottom: "8px",
      fontWeight: "500",
    },
    input: {
      width: "100%",
      padding: "8px 12px",
      border: "1px solid #e2e8f0",
      borderRadius: "4px",
      fontSize: "14px",
    },
    modalButtons: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "12px",
      marginTop: "24px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.cardsContainer}>
        {repairs?.map((repair, index) => (
          <div key={index} style={styles.card}>
            <div style={styles.header}>
              <span style={styles.regNo}>{repair.VregNO}</span>
              <span
                style={
                  repair.StatusName === "Closed"
                    ? styles.statusClosed
                    : styles.statusOpen
                }
              >
                {repair.StatusName}
              </span>
            </div>

            <div style={styles.description}>{repair.RepairDescription}</div>

            <div style={styles.info}>
              <div>Assigned to: {repair.AssignedToName || "Unassigned"}</div>
              <div>Added by: {repair.AddedByName}</div>
              <div>Added on: {formatDate(repair.DateAdded)}</div>
              {repair.RepairCost > 0 && (
                <div>Cost: KES {repair.RepairCost.toLocaleString()}</div>
              )}
            </div>

            {repair.ReleaseNote && (
              <div style={styles.info}>Release Note: {repair.ReleaseNote}</div>
            )}

            {/* Action Buttons based on status */}
            {repair.StatusID === 1 && (
              <button
                style={styles.actionButton}
                onClick={() => handleRepairAction(repair, "assign")}
              >
                Assign
              </button>
            )}
            {repair.StatusID === 2 && (
              <button
                style={styles.actionButton}
                onClick={() => handleRepairAction(repair, "review")}
              >
                Review
              </button>
            )}
            {repair.StatusID === 3 && (
              <button
                style={styles.actionButton}
                onClick={() => handleRepairAction(repair, "approve")}
              >
                Approve
              </button>
            )}
            {repair.StatusID === 4 && (
              <button
                style={styles.actionButton}
                onClick={() => handleRepairAction(repair, "book")}
              >
                Book
              </button>
            )}
            {repair.StatusID === 5 && (
              <button
                style={styles.actionButton}
                onClick={() => handleRepairAction(repair, "receive")}
              >
                Receive
              </button>
            )}
            {repair.StatusID === 6 && (
              <button
                style={styles.actionButton}
                onClick={() => handleRepairAction(repair, "release")}
              >
                Release
              </button>
            )}
            {repair.StatusID === 7 && (
              <button
                style={styles.actionButton}
                onClick={() => handleRepairAction(repair, "close")}
              >
                Close
              </button>
            )}
          </div>
        ))}
      </div>

      {/* Modal for actions */}
      {showModal && (
        <>
          <div
            style={styles.modalOverlay}
            onClick={() => setShowModal(false)}
          />
          <div style={styles.modal}>
            <h2 style={{ marginBottom: "20px", fontSize: "20px" }}>
              Update Repair
            </h2>

            {updateForm.statusID === 2 && (
              <div style={styles.formGroup}>
                <label style={styles.label}>Assign To:</label>
                <select
                  style={styles.input}
                  value={updateForm.assignedTo}
                  onChange={(e) =>
                    setUpdateForm({ ...updateForm, assignedTo: e.target.value })
                  }
                >
                  <option value="">Select Mechanic</option>
                  {mechanics.map((mechanic) => (
                    <option key={mechanic.UserID} value={mechanic.UserID}>
                      {mechanic.FullName}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {updateForm.statusID === 3 && (
              <div style={styles.formGroup}>
                <label style={styles.label}>Mechanic Notes:</label>
                <textarea
                  style={{ ...styles.input, height: "100px" }}
                  value={updateForm.mechNotes}
                  onChange={(e) =>
                    setUpdateForm({ ...updateForm, mechNotes: e.target.value })
                  }
                />
              </div>
            )}

            {updateForm.statusID === 5 && (
              <div style={styles.formGroup}>
                <label style={styles.label}>Booked Date:</label>
                <input
                  type="date"
                  style={styles.input}
                  value={updateForm.BookedDate}
                  onChange={(e) =>
                    setUpdateForm({ ...updateForm, BookedDate: e.target.value })
                  }
                />
              </div>
            )}

            {updateForm.statusID === 7 && (
              <div style={styles.formGroup}>
                <label style={styles.label}>Release Notes:</label>
                <textarea
                  style={{ ...styles.input, height: "100px" }}
                  value={updateForm.ReleaseNote}
                  onChange={(e) =>
                    setUpdateForm({
                      ...updateForm,
                      ReleaseNote: e.target.value,
                    })
                  }
                />
              </div>
            )}

            {updateForm.statusID === 8 && (
              <div style={styles.formGroup}>
                <label style={styles.label}>Repair Cost:</label>
                <input
                  type="number"
                  style={styles.input}
                  value={updateForm.repairCost}
                  onChange={(e) =>
                    setUpdateForm({ ...updateForm, repairCost: e.target.value })
                  }
                />
              </div>
            )}

            <div style={styles.modalButtons}>
              <button
                style={{ ...styles.actionButton, backgroundColor: "#4a5568" }}
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button style={styles.actionButton} onClick={handleUpdateSubmit}>
                Submit
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RepairCardsView;
