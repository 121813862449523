import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { AddUser } from "../../api/apirequest";

function UserForm({ setNewpo }) {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    fullName: "",
    phoneNumber: "",
    role: "admin",
    isActive: true,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const roles = [
    { value: "admin", label: "Admin" },
    { value: "driver", label: "Driver" },
    { value: "mechanic", label: "Mechanic" },
    { value: "assist", label: "Assist" },
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    if (name === "fullName") {
      const suggestedUsername = value.toLowerCase().replace(/\s+/g, "");
      setFormData((prevData) => ({ ...prevData, username: suggestedUsername }));
    }
  };

  const handleRoleChange = (selectedOption) => {
    setFormData((prevData) => ({ ...prevData, role: selectedOption.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const generatedPassword = Math.floor(
      1000 + Math.random() * 9000
    ).toString();

    try {
      const result = await AddUser({
        ...formData,
        passwordHash: generatedPassword,
      });

      if (result && result.success) {
        toast.success(result.message || "User successfully created!");
      } else {
        toast.error(
          result.message || "An error occurred during user creation."
        );
      }
    } catch (error) {
      console.error(error);
      toast.error("There was an error processing your request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>User Form</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.row}>
          <label style={styles.label}>
            Full Name:
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
          <label style={styles.label}>
            Username:
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
          <label style={styles.label}>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
        </div>
        <div style={styles.row}>
          <label style={styles.label}>
            Phone Number:
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
          <label style={styles.label}>
            Role:
            <Select
              options={roles}
              value={roles.find((role) => role.value === formData.role)}
              onChange={handleRoleChange}
              styles={customSelectStyles}
            />
          </label>
        </div>
        <button
          disabled={loading}
          type="submit"
          style={{
            ...styles.button,
            backgroundColor: loading ? "grey" : "#007BFF",
          }}
        >
          {loading ? "Submitting" : "Submit User"}
        </button>
      </form>
    </div>
  );
}

const styles = {
  container: {
    margin: "20px auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  label: {
    flex: "1",
    marginRight: "10px",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    padding: "8px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    marginTop: "5px",
  },
  button: {
    padding: "10px 15px",
    fontSize: "16px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

const customSelectStyles = {
  control: (base) => ({
    ...base,
    borderRadius: "4px",
    borderColor: "#ccc",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#888",
    },
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "#007BFF" : "#fff",
    color: state.isSelected ? "#fff" : "#333",
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
  }),
};

export default UserForm;
