import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  UpdateLoadingCheckIN,
  UpdateLoadingCheckOUT,
  UpdateLoadingPointCordinates,
  UpdateOffloadingCheckIN,
  UpdateOffloadingCheckOUT,
  UpdateOutletCheckIN,
  UpdateOutletCheckOUT,
} from "../../api/apirequest";
import { isWithinLocation } from "./LocationChecker";

const ActionModal = ({
  isOpen,
  onClose,
  status,
  shipment,
  onSubmit,
  fetchOrders,
}) => {
  const [formData, setFormData] = useState({
    dateTime: new Date().toISOString().slice(0, 16),
    mileage: "",
    emptyReturn: "",
    fullReturn: "",
    fuelIssuedLtrs: "",
    fuelIssuedValue: "",
    allowance: "",
    otherExpense: "",
  });
  const [isWithin, setIsWithin] = useState(false);
  const [currentCoordinates, setCurrentCoordinates] = useState({
    latitude: null,
    longitude: null,
    distance: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "dateTime") {
      if (validateDateTime(value)) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getDateTimeConstraints = () => {
    const now = new Date();
    const maxDateTime = now.toISOString().slice(0, 16);
    const thirtyMinutesAgo = new Date(now - 30 * 60000);
    const minDateTime = thirtyMinutesAgo.toISOString().slice(0, 16);

    return {
      max: maxDateTime,
      min: minDateTime,
    };
  };

  const HandleSaving = async () => {
    try {
      let result;

      switch (status) {
        case "OPEN":
          result = await UpdateLoadingCheckIN({
            allocationID: shipment.AllocationID,
            PlantEntryTime: formData.dateTime,
          });
          break;
        case "LOADING":
          result = await UpdateLoadingCheckOUT({
            allocationID: shipment.AllocationID,
            plantDepartureTime: formData.dateTime,
            mileage: formData.mileage,
          });
          break;
        case "ENROUTE":
          result = await UpdateOutletCheckIN({
            allocationID: shipment.AllocationID,
            outletArrivalTime: formData.dateTime,
            outletArrivalMileage: formData.mileage,
          });
          break;
        case "DELIVERING":
          result = await UpdateOutletCheckOUT({
            allocationID: shipment.AllocationID,
            outletDepartureTime: formData.dateTime,
            emptyReturn: formData.emptyReturn,
            fullReturn: formData.fullReturn,
          });
          break;
        case "RETURN":
          result = await UpdateOffloadingCheckIN({
            allocationID: shipment.AllocationID,
            offloadingArrivalTime: formData.dateTime,
            offloadingMileage: formData.mileage,
          });
          break;
        case "OFFLOADING":
          result = await UpdateOffloadingCheckOUT({
            allocationID: shipment.AllocationID,
            dateTime: formData.dateTime,
            fuelIssuedLtrs: formData.fuelIssuedLtrs,
            fuelIssuedValue: formData.fuelIssuedValue,
            claim: formData.allowance,
            otherExpense: formData.otherExpense,
            InvoiceImage: "",
            OffloadingExitTime: new Date(),
          });
          break;
        default:
          toast.error("Unknown status for saving action.");
          return;
      }

      if (result && result.success) {
        toast.success(result.message || "Action successfully saved!");
        fetchOrders();
        onClose();
      } else {
        toast.error(result.message || "Failed to save action.");
      }
    } catch (error) {
      console.error("Error during saving:", error);
      toast.error("There was an error saving your data.");
    }
  };

  const handleCheckLocation = async (targetLat, targetLong) => {
    try {
      const result = await isWithinLocation(targetLat, targetLong, 0.01); // 100m radius

      setCurrentCoordinates({
        latitude: result.currentLocation.latitude,
        longitude: result.currentLocation.longitude,
        distance: result.distance,
      });

      if (!result.isWithin) {
        setIsWithin(false);
        toast.error(
          `You must be within 10 meters of the location to perform this action. Current distance: ${(
            result.distance * 1000
          ).toFixed(0)}m`
        );
        return false;
      } else {
        setIsWithin(true);
      }
    } catch (error) {
      toast.info(
        "Unable to verify location. Please enable location services and try again."
      );
      return false;
    }
  };

  React.useEffect(() => {
    handleCheckLocation(
      shipment?.LoadingPointLatitude,
      shipment?.LoadingPointLongitude
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditLocation = async (e) => {
    // e.preventDefault();
    setLoading(true);
    setError("");

    try {
      // Call the API with the form data
      const result = await UpdateLoadingPointCordinates({
        loadingPointID: shipment?.LoadingPointID,
        latitude: currentCoordinates?.latitude,
        longitude: currentCoordinates?.longitude,
      });

      if (result && result.success) {
        toast.success(result.message || "Loaction successfully updated!");
        fetchOrders();
        // onClose();
        handleCheckLocation(
          currentCoordinates?.latitude,
          currentCoordinates?.longitude
        );
      } else {
        toast.error(
          result.message || "An error occurred while adding allocation."
        );
      }
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error("There was an error processing your request.");
    } finally {
      setLoading(false); // Always reset loading state
    }
  };

  const validateDateTime = (dateTimeStr) => {
    const localDate = new Date(dateTimeStr); // This already converts to local time

    const now = new Date();
    const thirtyMinutesAgo = new Date(now - 30 * 60000); // 30 minutes in milliseconds

    if (localDate > now) {
      toast.error("Cannot select a future time");
      return false;
    }

    if (localDate < thirtyMinutesAgo) {
      toast.error("Cannot select a time more than 30 minutes in the past");
      return false;
    }

    return true;
  };

  const renderLocationStatus = () => {
    if (!currentCoordinates.latitude) {
      return (
        <div style={styles.locationWarning}>
          <p>Checking your location...</p>
        </div>
      );
    }

    return (
      <div style={styles.locationInfo}>
        <div style={styles.coordinatesContainer}>
          <div style={styles.coordinateItem}>
            <span style={styles.coordinateLabel}>Your Location:</span>
            <span style={styles.coordinateValue}>
              {currentCoordinates.latitude?.toFixed(6)}°,{" "}
              {currentCoordinates.longitude?.toFixed(6)}°
            </span>
          </div>
        </div>
        <div style={styles.locationButtons}>
          <button
            type="button"
            onClick={() => handleEditLocation()}
            style={styles.updateButton}
          >
            Update Location
          </button>
        </div>
      </div>
    );
  };

  const renderFormFields = () => {
    const dateConstraints = getDateTimeConstraints();
    switch (status) {
      case "OPEN":
        return (
          <>
            {isWithin ? (
              <div style={styles.formGroup}>
                <label style={styles.label}>
                  Loading Check-In Time
                  <span style={styles.timeConstraint}>
                    (within last 30 minutes)
                  </span>
                </label>
                <input
                  type="datetime-local"
                  name="dateTime"
                  value={formData.dateTime}
                  onChange={handleChange}
                  style={styles.input}
                  max={dateConstraints.max}
                  min={dateConstraints.min}
                />
              </div>
            ) : (
              renderLocationStatus()
            )}
          </>
        );

      case "LOADING":
        return (
          <>
            <div style={styles.formGroup}>
              <label style={styles.label}>Loading Check-Out Time</label>
              <input
                type="datetime-local"
                name="dateTime"
                value={formData.dateTime}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Departure Mileage</label>
              <input
                type="number"
                name="mileage"
                value={formData.mileage}
                onChange={handleChange}
                placeholder="Enter mileage"
                style={styles.input}
              />
            </div>
          </>
        );

      case "ENROUTE":
        return (
          <>
            <div style={styles.formGroup}>
              <label style={styles.label}>Outlet Check-In Time</label>
              <input
                type="datetime-local"
                name="dateTime"
                value={formData.dateTime}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Arrival Mileage</label>
              <input
                type="number"
                name="mileage"
                value={formData.mileage}
                onChange={handleChange}
                placeholder="Enter mileage"
                style={styles.input}
              />
            </div>
          </>
        );

      case "DELIVERING":
        return (
          <>
            <div style={styles.formGroup}>
              <label style={styles.label}>Outlet Check-Out Time</label>
              <input
                type="datetime-local"
                name="dateTime"
                value={formData.dateTime}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Empty Returns</label>
              <input
                type="number"
                name="emptyReturn"
                value={formData.emptyReturn}
                onChange={handleChange}
                placeholder="Enter empty returns"
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Full Returns</label>
              <input
                type="number"
                name="fullReturn"
                value={formData.fullReturn}
                onChange={handleChange}
                placeholder="Enter full returns"
                style={styles.input}
              />
            </div>
          </>
        );

      case "RETURN":
        return (
          <>
            <div style={styles.formGroup}>
              <label style={styles.label}>Offloading Check-In Time</label>
              <input
                type="datetime-local"
                name="dateTime"
                value={formData.dateTime}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Return Mileage</label>
              <input
                type="number"
                name="mileage"
                value={formData.mileage}
                onChange={handleChange}
                placeholder="Enter mileage"
                style={styles.input}
              />
            </div>
          </>
        );

      case "OFFLOADING":
        return (
          <>
            <div style={styles.formGroup}>
              <label style={styles.label}>Fuel Issued (Ltrs)</label>
              <input
                type="number"
                name="fuelIssuedLtrs"
                value={formData.fuelIssuedLtrs}
                onChange={handleChange}
                placeholder="Enter fuel issued in liters"
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Fuel Value</label>
              <input
                type="number"
                name="fuelIssuedValue"
                value={formData.fuelIssuedValue}
                onChange={handleChange}
                placeholder="Enter fuel value"
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Allowance</label>
              <input
                type="number"
                name="allowance"
                value={formData.allowance}
                onChange={handleChange}
                placeholder="Enter allowance"
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Other Expenses</label>
              <input
                type="number"
                name="otherExpense"
                value={formData.otherExpense}
                onChange={handleChange}
                placeholder="Enter other expenses"
                style={styles.input}
              />
            </div>
          </>
        );

      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <div style={styles.modalHeader}>
          <h3 style={styles.modalTitle}>
            {status} - Shipment {shipment.ShipmentNo}
          </h3>
          <button onClick={onClose} style={styles.closeButton}>
            ×
          </button>
        </div>
        <div style={styles.form}>
          {renderFormFields()}
          <div style={styles.buttonGroup}>
            <button type="button" onClick={onClose} style={styles.cancelButton}>
              Cancel
            </button>
            <button
              type="submit"
              style={styles.submitButton}
              disabled={status === "OPEN" && !isWithin}
              onClick={() => HandleSaving()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: "white",
    borderRadius: "12px",
    width: "90%",
    maxWidth: "500px",
    maxHeight: "90vh",
    overflow: "auto",
    position: "relative",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  modalHeader: {
    padding: "20px",
    borderBottom: "1px solid #eee",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f8f9fa",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
  },
  modalTitle: {
    margin: 0,
    fontSize: "20px",
    fontWeight: "600",
    color: "#333",
  },
  closeButton: {
    border: "none",
    background: "none",
    fontSize: "24px",
    cursor: "pointer",
    padding: "4px",
    color: "#666",
  },
  form: {
    padding: "24px",
  },
  formGroup: {
    marginBottom: "20px",
  },
  label: {
    display: "block",
    marginBottom: "8px",
    fontWeight: "500",
    fontSize: "14px",
    color: "#444",
  },
  input: {
    width: "100%",
    padding: "10px 12px",
    borderRadius: "6px",
    border: "1px solid #ddd",
    fontSize: "14px",
  },
  buttonGroup: {
    display: "flex",
    gap: "12px",
    justifyContent: "flex-end",
    marginTop: "24px",
  },
  cancelButton: {
    padding: "10px 20px",
    borderRadius: "6px",
    border: "1px solid #ddd",
    backgroundColor: "white",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "500",
  },
  submitButton: {
    padding: "10px 20px",
    borderRadius: "6px",
    border: "none",
    backgroundColor: "#1565c0",
    color: "white",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "500",
  },
  locationInfo: {
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    padding: "16px",
    marginBottom: "20px",
  },
  coordinatesContainer: {
    marginBottom: "12px",
  },
  coordinateItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
  },
  coordinateLabel: {
    fontWeight: "500",
    color: "#666",
    fontSize: "14px",
  },
  coordinateValue: {
    fontSize: "14px",
    color: "#333",
    fontFamily: "monospace",
  },
  updateButton: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#4caf50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
  },
  locationWarning: {
    backgroundColor: "#fff3cd",
    color: "#856404",
    padding: "12px",
    borderRadius: "4px",
    marginBottom: "16px",
    textAlign: "center",
  },
};

export default ActionModal;
