import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const FloatingActionButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const styles = {
    container: {
      position: "fixed",
      bottom: "2rem",
      right: "2rem",
      zIndex: 1000,
    },
    menuContainer: {
      display: "flex",
      flexDirection: "column-reverse",
      gap: "1rem",
      marginBottom: "1rem",
    },
    menuItem: {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      padding: "0.5rem 1rem",
      backgroundColor: "#2563eb",
      color: "white",
      border: "none",
      borderRadius: "0.5rem",
      cursor: "pointer",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      transition: "all 0.2s ease",
    },
    menuItemHover: {
      backgroundColor: "#1d4ed8",
      transform: "scale(1.05)",
    },
    mainButton: {
      width: "3.5rem",
      height: "3.5rem",
      backgroundColor: "#2563eb",
      color: "white",
      border: "none",
      borderRadius: "50%",
      cursor: "pointer",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      transition: "all 0.2s ease",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transform: isOpen ? "rotate(45deg)" : "rotate(0)",
    },
    icon: {
      width: "1.5rem",
      height: "1.5rem",
    },
  };

  const menuItems = [
    {
      label: "Maintenance",
      path: "/mechanic/maintenance",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          style={styles.icon}
        >
          <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
        </svg>
      ),
    },
    {
      label: "Checklist",
      path: "/mechanic/checklist",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          style={styles.icon}
        >
          <path d="M9 11l3 3L22 4" />
          <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
        </svg>
      ),
    },
    {
      label: "Vehicle Service",
      path: "/mechanic/vehicle-service",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          style={styles.icon}
        >
          <path d="M18 10h-3V7h-2v3H9l3 3 3-3zm1-8H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" />
          <path d="M12 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
        </svg>
      ),
    },
    {
      label: "Accidents",
      path: "/mechanic/accidents",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          style={styles.icon}
        >
          <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
          <line x1="12" y1="9" x2="12" y2="13" />
          <line x1="12" y1="17" x2="12.01" y2="17" />
        </svg>
      ),
    },
    {
      label: "Dashboard",
      path: "/mechanic/dashboard",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          style={styles.icon}
        >
          <rect x="3" y="3" width="7" height="7" />
          <rect x="14" y="3" width="7" height="7" />
          <rect x="14" y="14" width="7" height="7" />
          <rect x="3" y="14" width="7" height="7" />
        </svg>
      ),
    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div style={styles.container}>
      {isOpen && (
        <div style={styles.menuContainer}>
          {menuItems.map((item, index) => (
            <button
              key={index}
              onClick={() => {
                if (item.path) {
                  navigate(item.path);
                  setIsOpen(false); // Close the menu after navigating
                }
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{
                ...styles.menuItem,
                ...(hoveredIndex === index ? styles.menuItemHover : {}),
              }}
            >
              {item.icon}
              <span>{item.label}</span>
            </button>
          ))}
        </div>
      )}
      <button
        onClick={() => setIsOpen(!isOpen)}
        style={styles.mainButton}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = "#1d4ed8";
          e.currentTarget.style.transform = `${
            isOpen ? "rotate(45deg)" : "rotate(0)"
          } scale(1.05)`;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = "#2563eb";
          e.currentTarget.style.transform = isOpen
            ? "rotate(45deg)"
            : "rotate(0)";
        }}
      >
        <svg
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          style={styles.icon}
        >
          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
          <polyline points="9 22 9 12 15 12 15 22"></polyline>
        </svg>
      </button>
    </div>
  );
};

export default FloatingActionButton;
