import React, { useEffect, useState } from "react";
import { getCustomers, getVehicles } from "../../api/apiGetrequest";
import TableHeader from "../../Components/DataTable/TableHeader";
import CustomerForm from "./AddCustomers";

const styles = {
  tableContainer: {
    margin: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  thTd: {
    padding: "8px",
    border: "1px solid #ddd",
    textAlign: "left",
  },
  activeStatus: {
    color: "green",
  },
  inactiveStatus: {
    color: "red",
  },
  vehicleDropdown: {
    padding: "8px",
    border: "1px solid #ddd",
    width: "100%",
    marginBottom: "10px",
  },
};

const CustomerTable = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [newpo, setNewpo] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const result = await getCustomers();
        setCustomers(result);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getVehicles();
        setVehicles(result);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    };

    fetchData();
  }, []);

  const handleRowClick = (user) => {
    setSelectedUser(user);
  };

  if (newpo) {
    return (
      <div>
        <TableHeader
          title="Add Customer"
          showAddButton={false}
          showBackButton={true}
          onBack={() => setNewpo(false)}
          showPrintButton={false}
          showExcelButton={false}
          showPdfButton={false}
        />
        <CustomerForm setNewpo={() => setNewpo(false)} />
      </div>
    );
  }



  return (
    <div style={styles.tableContainer}>
      <TableHeader
        title="Customer List"
        showAddButton={true}
        showBackButton={false}
        onAdd={() => setNewpo(true)}
        showPrintButton={true}
        showExcelButton={true}
        showPdfButton={true}
      />
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.thTd}>Customer Name</th>
            <th style={styles.thTd}>Address</th>
            <th style={styles.thTd}>Location</th>
            <th style={styles.thTd}>Date Added</th>
            <th style={styles.thTd}>Status</th>
          </tr>
        </thead>
        <tbody>
          {customers?.map((customer) => (
            <tr
              key={customer.CustomerID}
              onClick={() => handleRowClick(customer)}
              style={{ cursor: "pointer" }}
            >
              <td style={styles.thTd}>{customer.CustomerName || "N/A"}</td>
              <td style={styles.thTd}>{customer.Address || "N/A"}</td>
              <td style={styles.thTd}>{customer.Location || "N/A"}</td>
              <td style={styles.thTd}>
                {new Date(customer.DateAdded).toLocaleString()}
              </td>
              <td style={styles.thTd}>
                <span
                  style={
                    customer.StatusID === 1
                      ? styles.activeStatus
                      : styles.inactiveStatus
                  }
                >
                  {customer.StatusID === 1 ? "Active" : "Inactive"}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerTable;
