import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { getCustomers, getUsers } from "../../api/apiGetrequest";
import { AddAllocation } from "../../api/apirequest";

const styles = {
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "32px",
    width: "100%",
    maxWidth: "800px",
    maxHeight: "90vh",
    overflowY: "auto",
    position: "relative",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    margin: 0,
  },
  closeButton: {
    background: "none",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
    color: "#666",
  },
  form: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "20px",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "8px",
    fontSize: "14px",
    fontWeight: "500",
  },
  input: {
    padding: "8px 12px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontSize: "14px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
    marginTop: "24px",
    gridColumn: "1 / -1",
  },
  button: {
    padding: "8px 16px",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    border: "none",
  },
  cancelButton: {
    backgroundColor: "#f0f0f0",
    color: "#333",
  },
  submitButton: {
    backgroundColor: "#007bff",
    color: "white",
  },
  selectStyles: {
    control: (base) => ({
      ...base,
      minHeight: "36px",
    }),
  },
};

const ShipmentFormModal = ({ isOpen, onClose, fetchOrders }) => {
  const [formData, setFormData] = useState({
    driverID: null,
    vehicleID: null,
    customerID: null,
    trip: "1",
    tripDate: "",
    shipmentNO: "",
    outletName: "",
    casesFull: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);

  // Set default date to tomorrow
  useEffect(() => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const tomorrowDate = today.toISOString().slice(0, 16);
    setFormData((prevData) => ({
      ...prevData,
      tripDate: tomorrowDate,
    }));
    fetchMechanics();
    fetchCustomers();
  }, []);

  const fetchMechanics = async () => {
    try {
      const result = await getUsers();
      setUsers(result?.filter((m) => m.Role === "driver"));
    } catch (error) {
      toast.error("Failed to fetch mechanics");
    }
  };

  const fetchCustomers = async () => {
    try {
      const result = await getCustomers();
      setUsers(result);
    } catch (error) {
      toast.error("Failed to fetch customers");
    }
  };

  // Validation function
  const validateFormData = (data) => {
    if (!data.driverID) return "Driver ID is required.";
    if (!data.vehicleID) return "Vehicle ID is required.";
    if (!data.customerID) return "Customer ID is required.";
    if (!data.trip) return "Trip description is required.";
    if (!data.tripDate) return "Trip date is required.";
    if (!data.shipmentNO) return "Shipment number is required.";
    if (!data.casesFull) return "Number of full cases is required.";
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const validationError = validateFormData(formData);
    if (validationError) {
      toast.error(validationError);
      setLoading(false);
      return;
    }

    try {
      // Call the API with the form data
      const result = await AddAllocation({ ...formData });

      if (result && result.success) {
        toast.success(result.message || "Alocation successfully added!");
        fetchOrders();
        onClose();
      } else {
        toast.error(
          result.message || "An error occurred while adding allocation."
        );
      }
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error("There was an error processing your request.");
    } finally {
      setLoading(false); // Always reset loading state
    }
  };

  const driverOptions = users.map((item) => ({
    value: item.UserID,
    label: item.FullName,
    vehicle: {
      value: item.VehicleID,
      label: item.VehicleRegistrationNumber,
    },
  }));

  const customerOptions = [{ value: 2, label: "jowi jowi" }];

  const handleDriverChange = (selectedOption) => {
    setFormData({
      ...formData,
      driverID: selectedOption.value,
      vehicleID: selectedOption.vehicle.value,
    });
  };

  if (!isOpen) return null;

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <div style={styles.header}>
          <h2 style={styles.title}>Add New Shipment</h2>
          <button style={styles.closeButton} onClick={onClose}>
            ×
          </button>
        </div>

        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.formGroup}>
            <label style={styles.label}>Driver*</label>
            <Select
              options={driverOptions}
              onChange={handleDriverChange}
              styles={styles.selectStyles}
              value={driverOptions.find(
                (option) => option.value === formData.driverID
              )}
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Vehicle*</label>
            <Select
              value={driverOptions}
              isDisabled={formData.driverID === null}
              options={[
                driverOptions.find((d) => d.value === formData.DriverID)
                  ?.vehicle || { value: null, label: "Select a Driver First" },
              ]}
              styles={styles.selectStyles}
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Customer*</label>
            <Select
              options={customerOptions}
              onChange={(option) =>
                setFormData({ ...formData, customerID: option.value })
              }
              styles={styles.selectStyles}
              value={customerOptions.find(
                (option) => option.value === formData.customerID
              )}
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Trip*</label>
            <input
              type="text"
              value={formData.trip}
              onChange={(e) =>
                setFormData({ ...formData, trip: e.target.value })
              }
              style={styles.input}
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Trip Date*</label>
            <input
              type="datetime-local"
              value={formData.tripDate}
              onChange={(e) =>
                setFormData({ ...formData, tripDate: e.target.value })
              }
              style={styles.input}
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Shipment No*</label>
            <input
              type="text"
              value={formData.shipmentNO}
              onChange={(e) =>
                setFormData({ ...formData, shipmentNO: e.target.value })
              }
              style={styles.input}
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Cases Full*</label>
            <input
              type="number"
              value={formData.casesFull}
              onChange={(e) =>
                setFormData({ ...formData, casesFull: e.target.value })
              }
              style={styles.input}
            />
          </div>

          <div style={styles.buttonContainer}>
            <button
              type="button"
              onClick={onClose}
              style={{ ...styles.button, ...styles.cancelButton }}
            >
              Cancel
            </button>
            <button
              type="submit"
              style={{ ...styles.button, ...styles.submitButton }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShipmentFormModal;
