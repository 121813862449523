import React, { useState } from "react";
import AddMaintenanceReport from "./AddMaintenance";
import MaintenanceTable from "./MaintenanceList";
import "./orders.scss";

function Maintenence() {
  const [newpo, setNewPO] = useState(null);
  


  return (
    <div className="orders">
      {newpo ? (
        <AddMaintenanceReport />
      ) : (
        <MaintenanceTable
        //   maintenanceReports={maintenanceReports}
          addNewReport={() => setNewPO(!newpo)}
        />
      )}
    </div>
  );
}

export default Maintenence;
