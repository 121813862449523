import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { getUsers, getVehicles } from "../../api/apiGetrequest";
import { AddUserVehicleAssignment } from "../../api/apirequest";
import TableHeader from "../../Components/DataTable/TableHeader";
import UserForm from "./AddUser";

const styles = {
  tableContainer: {
    margin: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  thTd: {
    padding: "8px",
    border: "1px solid #ddd",
  },
  activeStatus: {
    color: "green",
  },
  inactiveStatus: {
    color: "red",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  submitButton: {
    padding: "8px 16px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  cancelButton: {
    padding: "8px 16px",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft: "10px",
  },
};

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [newpo, setNewpo] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [unassignedVehicles, setUnassignedVehicles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const result = await getUsers();
        setUsers(result);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const fetchVehicles = async () => {
      try {
        const result = await getVehicles();
        setVehicles(result);

        // Filter unassigned vehicles
        const unassigned = result.filter((vehicle) => !vehicle.AssignedToUser);
        setUnassignedVehicles(
          unassigned.map((vehicle) => ({
            value: vehicle.VehicleID,
            label: `${vehicle.VregNO} `,
          }))
        );
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    };

    fetchUsers();
    fetchVehicles();
  }, []);

  const handleRowClick = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleVehicleAssignment = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await AddUserVehicleAssignment({
        vehicleID: selectedVehicle.value,
        userID: selectedUser?.UserID,
        dateAdded: new Date(),
        dateUpdated: new Date(),
        statusID: 1,
        addedby: 1,
      });

      if (result && result.success) {
        toast.success(result.message || "Vehicle assigned successfully!");
        setIsModalOpen(false);

        // Update unassigned vehicles
        setUnassignedVehicles((prev) =>
          prev.filter((vehicle) => vehicle.value !== selectedVehicle.value)
        );
      } else {
        toast.error(result.message || "An error occurred during assignment.");
      }
    } catch (error) {
      console.error(error);
      toast.error("There was an error processing your request.");
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVehicle(null);
    setSelectedUser(null);
  };

  if (newpo) {
    return (
      <div>
        <TableHeader
          title="Users List"
          showAddButton={false}
          showBackButton={true}
          onBack={() => setNewpo(false)}
          showPrintButton={false}
          showExcelButton={false}
          showPdfButton={false}
        />
        <UserForm setNewpo={() => setNewpo(false)} />
      </div>
    );
  }

  return (
    <div style={styles.tableContainer}>
      <TableHeader
        title="Users List"
        showAddButton={true}
        showBackButton={false}
        onAdd={() => setNewpo(true)}
        showPrintButton={true}
        showExcelButton={true}
        showPdfButton={true}
      />
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.thTd}>Username</th>
            <th style={styles.thTd}>Email</th>
            <th style={styles.thTd}>Full Name</th>
            <th style={styles.thTd}>Phone Number</th>
            <th style={styles.thTd}>Role</th>
            <th style={styles.thTd}>Status</th>
            <th style={styles.thTd}>Created At</th>
            <th style={styles.thTd}>Last Login</th>
            <th style={styles.thTd}>Vehicle Registration</th>
          </tr>
        </thead>
        <tbody>
          {users?.map((user, index) => (
            <tr
              key={index}
              onClick={() => handleRowClick(user)}
              style={{ cursor: "pointer" }}
            >
              <td style={styles.thTd}>{user.Username}</td>
              <td style={styles.thTd}>{user.Email}</td>
              <td style={styles.thTd}>{user.FullName}</td>
              <td style={styles.thTd}>{user.PhoneNumber}</td>
              <td style={styles.thTd}>{user.Role}</td>
              <td style={styles.thTd}>
                <span
                  style={
                    user.IsActive ? styles.activeStatus : styles.inactiveStatus
                  }
                >
                  {user.IsActive ? "Active" : "Inactive"}
                </span>
              </td>
              <td style={styles.thTd}>
                {new Date(user.CreatedAt).toLocaleString()}
              </td>
              <td style={styles.thTd}>
                {user.LastLogin
                  ? new Date(user.LastLogin).toLocaleString()
                  : "N/A"}
              </td>
              <td style={styles.thTd}>
                {user.VehicleRegistrationNumber || "N/A"}
                {isModalOpen && selectedUser?.Username === user.Username && (
                  <div>
                    <Select
                      options={unassignedVehicles}
                      onChange={(option) => setSelectedVehicle(option)}
                      value={selectedVehicle}
                      placeholder="Search and select a vehicle"
                    />
                    <div style={styles.buttonContainer}>
                      <button
                        style={styles.submitButton}
                        onClick={handleVehicleAssignment}
                        disabled={loading || !selectedVehicle}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </button>
                      <button
                        style={styles.cancelButton}
                        onClick={closeModal}
                        disabled={loading}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
