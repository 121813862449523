import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../api/apirequest";

// Import background images
import landscapeBackground from "./landscape.avif";
import portraitBackground from "./potrait.jpeg";

const LoginPage = () => {
  const styles = {
    pageContainer: {
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
      backgroundImage: `url(${landscapeBackground})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      "@media (orientation: portrait)": {
        backgroundImage: `url(${portraitBackground})`,
      },
    },
    card: {
      width: "100%",
      maxWidth: "400px",
      backgroundColor: "white",
      borderRadius: "12px",
      boxShadow:
        "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
      overflow: "hidden",
      backdropFilter: "blur(8px)",
      backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
    header: {
      padding: "24px 24px 0 24px",
      textAlign: "center",
    },
    title: {
      fontSize: "24px",
      fontWeight: "700",
      color: "#1a2b4b",
      marginBottom: "8px",
    },
    subtitle: {
      fontSize: "14px",
      color: "#64748b",
      marginBottom: "24px",
    },
    form: {
      padding: "0 24px 24px 24px",
    },
    inputGroup: {
      marginBottom: "20px",
    },
    label: {
      display: "block",
      fontSize: "14px",
      fontWeight: "500",
      color: "#374151",
      marginBottom: "8px",
    },
    input: {
      width: "100%",
      padding: "10px 12px",
      fontSize: "16px",
      lineHeight: "1.5",
      border: "1px solid #e5e7eb",
      borderRadius: "8px",
      transition: "border-color 0.15s ease-in-out",
      outline: "none",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
    },
    inputFocus: {
      borderColor: "#2563eb",
      boxShadow: "0 0 0 3px rgba(37, 99, 235, 0.1)",
    },
    button: {
      width: "100%",
      padding: "12px",
      fontSize: "16px",
      fontWeight: "500",
      color: "white",
      backgroundColor: "#2563eb",
      border: "none",
      borderRadius: "8px",
      cursor: "pointer",
      transition: "background-color 0.15s ease-in-out",
    },
    buttonHover: {
      backgroundColor: "#1d4ed8",
    },
    buttonDisabled: {
      backgroundColor: "#93c5fd",
      cursor: "not-allowed",
    },
    errorContainer: {
      padding: "12px",
      marginBottom: "20px",
      backgroundColor: "#fee2e2",
      border: "1px solid #fecaca",
      borderRadius: "8px",
    },
    errorText: {
      color: "#dc2626",
      fontSize: "14px",
      textAlign: "center",
    },
    loadingSpinner: {
      display: "inline-block",
      width: "16px",
      height: "16px",
      marginRight: "8px",
      border: "2px solid rgba(255, 255, 255, 0.3)",
      borderRadius: "50%",
      borderTopColor: "white",
      animation: "spin 1s linear infinite",
    },
    "@keyframes spin": {
      to: { transform: "rotate(360deg)" },
    },
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputFocus, setInputFocus] = useState({
    username: false,
    password: false,
  });

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const result = await login(username, password);
      localStorage.setItem("user", JSON.stringify(result));

      const role = result.data.role;

      if (role === "admin") {
        navigate("/admin/dashboard");
      } else if (role === "driver") {
        navigate("/driver/dashboard");
      } else if (role === "mechanic") {
        navigate("/mechanic/dashboard");
      } else {
        setError("Unexpected user type.");
      }
    } catch (error) {
      setError("Invalid username or password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.card}>
        <div style={styles.header}>
          <h1 style={styles.title}>Welcome Back</h1>
          <p style={styles.subtitle}>
            Enter your credentials to access your account
          </p>
        </div>

        <form onSubmit={handleLogin} style={styles.form}>
          {error && (
            <div style={styles.errorContainer}>
              <p style={styles.errorText}>{error}</p>
            </div>
          )}

          <div style={styles.inputGroup}>
            <label htmlFor="username" style={styles.label}>
              Username
            </label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onFocus={() => setInputFocus({ ...inputFocus, username: true })}
              onBlur={() => setInputFocus({ ...inputFocus, username: false })}
              style={{
                ...styles.input,
                ...(inputFocus.username ? styles.inputFocus : {}),
              }}
              placeholder="Enter your username"
              required
            />
          </div>

          <div style={styles.inputGroup}>
            <label htmlFor="password" style={styles.label}>
              Password
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setInputFocus({ ...inputFocus, password: true })}
              onBlur={() => setInputFocus({ ...inputFocus, password: false })}
              style={{
                ...styles.input,
                ...(inputFocus.password ? styles.inputFocus : {}),
              }}
              placeholder="Enter your password"
              required
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            style={{
              ...styles.button,
              ...(loading ? styles.buttonDisabled : {}),
            }}
            onMouseOver={(e) => {
              if (!loading)
                e.currentTarget.style.backgroundColor =
                  styles.buttonHover.backgroundColor;
            }}
            onMouseOut={(e) => {
              if (!loading)
                e.currentTarget.style.backgroundColor =
                  styles.button.backgroundColor;
            }}
          >
            {loading ? (
              <>
                <div style={styles.loadingSpinner} />
                Signing in...
              </>
            ) : (
              "Sign in"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
