import React, { useState } from "react";
import { getAllocations } from "../../api/apiGetrequest";
import ActionModal from "./OrderActionModal";

const DeliveryCards = () => {
  const [activeStatus, setActiveStatus] = useState("ALL");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);

  const handleAction = (action, item) => {
    setSelectedShipment(item);
    setIsModalOpen(true);
  };

  const statusTypes = [
    { id: "ALL", text: "ALL" },
    { id: 1, text: "OPEN" },
    { id: 2, text: "LOADING" },
    { id: 3, text: "ENROUTE" },
    { id: 4, text: "DELIVERING" },
    { id: 5, text: "RETURN" },
    { id: 6, text: "OFFLOADING" },
    { id: 7, text: "COMPLETE" },
  ];

  const getDeliveryStatus = (item) => {
    if (!item?.LoadingArrivalTime) return { id: 1, text: "OPEN" };
    if (item?.LoadingArrivalTime && !item?.PlantDepartureTime)
      return { id: 2, text: "LOADING" };
    if (item?.PlantDepartureTime && !item?.OutletArrivalTime)
      return { id: 3, text: "ENROUTE" };
    if (item?.OutletArrivalTime && !item?.OutletDepartureTime)
      return { id: 4, text: "DELIVERING" };
    if (item?.OutletDepartureTime && !item?.OffloadingArrivalTime)
      return { id: 5, text: "RETURN" };
    if (item?.OutletDepartureTime && !item?.OffloadingExitTime)
      return { id: 6, text: "OFFLOADING" };
    return { id: 7, text: "COMPLETE" };
  };

  const getActionButton = (item) => {
    if (!item.LoadingArrivalTime) {
      return { text: "Loading Check In", action: "LOADING_CHECKIN" };
    }
    if (item.LoadingArrivalTime && !item.PlantDepartureTime) {
      return { text: "Loading Check Out", action: "LOADING_CHECKOUT" };
    }
    if (item.PlantDepartureTime && !item.OutletArrivalTime) {
      return { text: "Outlet Check In", action: "OUTLET_CHECKIN" };
    }
    if (item.OutletArrivalTime && !item.OutletDepartureTime) {
      return { text: "Outlet Check Out", action: "OUTLET_CHECKOUT" };
    }
    if (item.OutletDepartureTime && !item.OffloadingArrivalTime) {
      return { text: "Return Check In", action: "RETURN_CHECKIN" };
    }
    if (item.OffloadingArrivalTime && !item.OffloadingExitTime) {
      return { text: "Complete Order", action: "RETURN_CHECKOUT" };
    }
    return { text: "Complete Order", action: "COMPLETE" };
  };

  const filteredData = data?.filter((item) => {
    if (activeStatus === "ALL") return true;
    const status = getDeliveryStatus(item);
    return status.id === activeStatus;
  });

  const fetchData = async () => {
    try {
      const { result } = await getAllocations({
        UserID: 0,
        startDate: startDate,
        enddate: endDate,
      });
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.dateContainer}>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            style={styles.dateInput}
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            style={styles.dateInput}
          />
        </div>
        <div style={styles.statusTabs}>
          {statusTypes.map((status) => (
            <button
              key={status.id}
              style={{
                ...styles.statusTab,
                ...(activeStatus === status.id ? styles.activeTab : {}),
              }}
              onClick={() => setActiveStatus(status.id)}
            >
              {status.text}
            </button>
          ))}
        </div>
      </div>

      <div style={styles.cardContainer}>
        {filteredData.map((item, index) => {
          const status = getDeliveryStatus(item);
          const action = getActionButton(item);

          return (
            <div key={index} style={styles.card}>
              <div style={styles.cardHeader}>
                <span
                  style={{
                    ...styles.statusBadge,
                    backgroundColor: statusColors[status.id].bg,
                    color: statusColors[status.id].text,
                  }}
                >
                  {status.text}
                </span>
                <span style={styles.shipmentNo}>{item.ShipmentNo}</span>
              </div>

              <div style={styles.cardBody}>
                <div style={styles.row}>
                  <strong>Driver:</strong> {item.DriverName.toUpperCase()}
                </div>
                <div style={styles.row}>
                  <strong>Vehicle:</strong> {item.VregNO.toUpperCase()}
                </div>
                <div style={styles.row}>
                  <strong>Customer:</strong> {item.CustomerName.toUpperCase()}
                </div>
                <div style={styles.row}>
                  <strong>Date:</strong>{" "}
                  {new Date(item.TripDate).toLocaleDateString()}
                </div>
                <div style={styles.row}>
                  <strong>Cases:</strong> Full:{" "}
                  {item.CasesFull?.toLocaleString()} | Empty:{" "}
                  {item.EmptyReturn?.toLocaleString()} | Returned:{" "}
                  {item.FullReturn?.toLocaleString()}
                </div>
              </div>

              {getDeliveryStatus(selectedShipment).text !== "COMPLETE" && (
                <button
                  style={styles.actionButton}
                  onClick={() => handleAction(action.action, item)}
                >
                  {action.text}
                </button>
              )}
            </div>
          );
        })}
      </div>
      {selectedShipment && (
        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          status={getDeliveryStatus(selectedShipment).text}
          shipment={selectedShipment}
          fetchOrders={fetchData}
          onSubmit={(formData) => {
            // Handle the form submission here
            console.log(formData);
          }}
        />
      )}
    </div>
  );
};

const statusColors = {
  1: { bg: "#e3f2fd", text: "#1565c0" },
  2: { bg: "#fff3e0", text: "#e65100" },
  3: { bg: "#e8f5e9", text: "#2e7d32" },
  4: { bg: "#fff3e0", text: "#e65100" },
  5: { bg: "#e8f5e9", text: "#2e7d32" },
  6: { bg: "#e0e0e0", text: "#424242" },
  7: { bg: "#f5f5f5", text: "#616161" },
};

const styles = {
  container: {
    padding: "16px",
    maxWidth: "100%",
    margin: "0 auto",
  },
  header: {
    marginBottom: "16px",
  },
  dateContainer: {
    display: "flex",
    gap: "8px",
    marginBottom: "16px",
  },
  dateInput: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    flex: 1,
  },
  statusTabs: {
    display: "flex",
    gap: "8px",
    overflowX: "auto",
    padding: "4px 0",
  },
  statusTab: {
    padding: "8px 16px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: "#f4f4f4",
    color: "#666",
    whiteSpace: "nowrap",
    fontSize: "14px",
  },
  activeTab: {
    backgroundColor: "#1565c0",
    color: "white",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  card: {
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "16px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },
  statusBadge: {
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  shipmentNo: {
    fontWeight: "bold",
    fontSize: "14px",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  row: {
    fontSize: "14px",
    lineHeight: "1.4",
  },
  actionButton: {
    width: "100%",
    padding: "12px",
    backgroundColor: "#1565c0",
    color: "white",
    border: "none",
    borderRadius: "4px",
    marginTop: "16px",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: "14px",
  },
};

export default DeliveryCards;
