import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getVehicleRepairImage,
  getVehicleRepairs,
} from "../../api/apiGetrequest";
import { AddDriveImage, AddVehicleRepairRequest } from "../../api/apirequest";
import TableHeader from "../../Components/DataTable/TableHeader";
import Base64ImageModal from "../../Components/ImageViewer/ImageViewer";

const RepairsPage = ({ activeTab }) => {
  const [repairs, setRepairs] = useState(() => {
    const savedRepairs = localStorage.getItem("repairs");
    return savedRepairs ? JSON.parse(savedRepairs) : [];
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const [selected, setSelected] = useState(null);

  const details = JSON.parse(localStorage.getItem("user"));

  const [newRepair, setNewRepair] = useState({
    repairID: 0,
    vehicleID: details?.data?.vehicleID,
    repairDescription: "",
    repairImageID: "",
    repairSource: "Driver",
    assignedTo: 0,
    mechNotes: "",
    repairCost: 0,
    dateAdded: new Date().toISOString(),
    dateClosed: "",
    statusID: 0,
    addedBy: details?.data?.userID,
  });

  const [isAddingRepair, setIsAddingRepair] = useState(false);

  useEffect(() => {
    localStorage.setItem("repairs", JSON.stringify(repairs));
  }, [repairs]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      // Step 1: Submit the image to the drive
      if (newRepair.repairImageID) {
        const imageResult = await AddDriveImage({
          base64Image: newRepair.repairImageID,
          fileName: new Date().toISOString(), // Use ISO format for file name
        });

        if (imageResult.ViewLink) {
          const { FileId } = imageResult;

          // Step 2: Add repair with the FileId as the repairImageID
          const result = await AddVehicleRepairRequest({
            ...newRepair,
            repairImageID: FileId, // Use FileId as the image ID
          });

          if (result && result.success) {
            toast.success(result.message || "Repair successfully added!");
            setIsAddingRepair(false);
          } else {
            toast.error(result.message || "An error occurred while adding repair.");
          }
        } else {
          toast.error(imageResult.message || "Failed to upload the image to the drive.");
        }
      } else {
        toast.error("Please upload an image for the repair.");
      }
    } catch (error) {
      console.error(error);
      toast.error("There was an error processing your request.");
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewRepair((prev) => ({ ...prev, repairImageID: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  function formatDate(date) {
    return date.toISOString().split("T")[0]; // Converts to "YYYY-MM-DD"
  }

  const today = new Date();
  const last7Days = new Date(today);
  last7Days.setDate(today.getDate() - 7);
  const startdate = formatDate(last7Days);
  const enddate = formatDate(today);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getVehicleRepairs({
          vehicleID: details?.data?.vehicleID,
          assignedTo: 0,
          startdate: startdate,
          enddate: enddate,
        });
        setRepairs(result);
      } catch (error) {
        console.error("Error fetching repairs:", error);
      }
    };

    fetchData();
  }, [activeTab]);

  useEffect(() => {
    if (!selected) return;

    const fetchImage = async () => {
      try {
        const result = await getVehicleRepairImage(selected.RepairID);
        setImage(result[0]?.RepairImageID);
      } catch (error) {
        console.error("Error fetching repair image:", error);
      }
    };

    fetchImage();
  }, [selected]);

  const getStatusStyle = (statusName) => {
    switch (statusName) {
      case "Open":
        return { borderBottom: "5px solid #f97316" };
      case "WIP":
        return { borderBottom: "5px solid #3b82f6" };
      case "Closed":
        return { borderBottom: "5px solid #10b981" };
      default:
        return { borderBottom: "5px solid #d1d5db" };
    }
  };

  const styles = {
    pageContainer: {  
      fontFamily: "Arial, sans-serif",
      backgroundColor: "#f4f6f8",
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      margin: "0",
      padding: "20px",
      
    },
    container: {
      flex: "1",
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      marginBottom: "100px"
    },
    header: {
      textAlign: "center",
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#374151",
      borderBottom: "2px solid #e5e7eb",
      paddingBottom: "10px",
    },
    repairCardContainer: {
      display: "grid",
      gap: "20px",
      marginTop: "20px",
    },
    repairCard: {
      backgroundColor: "#ffffff",
      border: "1px solid #e5e7eb",
      borderRadius: "8px",
      padding: "15px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.3s ease",
    },
    repairCardHover: {
      transform: "translateY(-5px)",
    },
    cardHeader: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      marginBottom: "10px",
      color: "#1f2937",
    },
    cardDetails: {
      marginBottom: "10px",
      color: "#6b7280",
    },
    addRepairForm: {
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "20px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
      display: "flex",
      flexDirection: "column",
      gap: "15px",
      width: "100%",
    },
    inputField: {
      padding: "10px",
      border: "1px solid #e5e7eb",
      borderRadius: "5px",
      fontSize: "1rem",
      width: "100%",
    },
    addButton: {
      backgroundColor: loading ? "#ccc" : "#007bff",
      color: "#fff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "4px",
      cursor: loading ? "not-allowed" : "pointer",
      opacity: loading ? 0.6 : 1,
      alignSelf: "flex-start",
      width: "100%",
    },
    imagePreview: {
      marginTop: "10px",
      maxWidth: "100%",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    viewImageButton: {
      backgroundColor: "#38bdf8",
      color: "#fff",
      width: "100%",
      padding: "8px 15px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      marginTop: "10px",
    },
  };

  return (
    <div style={{borderBottom:'80px'}}>
      <TableHeader
        title="Repair Tracker"
        showAddButton={!isAddingRepair}
        showBackButton={isAddingRepair}
        onBack={() => setIsAddingRepair(!isAddingRepair)}
        onAdd={() => setIsAddingRepair(!isAddingRepair)}
        showPrintButton={false}
        showExcelButton={false}
        showPdfButton={false}
      />
      {isAddingRepair ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <textarea
            placeholder="Repair Description"
            style={styles.inputField}
            rows="3"
            value={newRepair.repairDescription}
            onChange={(e) =>
              setNewRepair({ ...newRepair, repairDescription: e.target.value })
            }
          />
          <input
            type="file"
            accept="image/*"
            style={styles.inputField}
            onChange={handleImageChange}
          />
          {/* {newRepair.repairImageID && (
            <img
              src={newRepair.repairImageID}
              alt="Repair Area"
              style={styles.imagePreview}
            />
          )} */}
          <button disabled={loading} type="submit" style={styles.addButton}>
            {loading ? "Repair submitting..." : "Add Repair"}
          </button>
        </form>
      ) : (
        <div style={styles.repairCardContainer}>
          {repairs.map((repair) => (
            <div
              key={repair.repairID}
              style={{
                ...styles.repairCard,
                ...styles.repairCardHover,
                ...getStatusStyle(repair.StatusName),
              }}
            >
              <div style={styles.cardHeader}>{repair.RepairDescription}</div>

              <div style={styles.cardDetails}>
                Mechanic Notes: {repair.MechNotes}
              </div>
              <div style={styles.cardDetails}>
                Date Added: {new Date(repair?.DateAdded)?.toLocaleString()}
              </div>
              <button
                style={styles.viewImageButton}
                onClick={() => {
                  setImage(null);
                  setSelected(repair);
                }}
              >
                View Image
              </button>
            </div>
          ))}
        </div>
      )}
      <Base64ImageModal
        base64Image={image}
        open={selected}
        onClose={() => setSelected(null)}
      />
    </div>
  );
};

export default RepairsPage;
