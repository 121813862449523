import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getUsers, getVehicleRepairs } from "../../api/apiGetrequest";
import { UpdateVehicleRepair } from "../../api/apirequest";

const RepairTable = () => {
  const [repairs, setRepairs] = useState([]);
  const [mechanics, setMechanics] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRepair, setSelectedRepair] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0], // 7 days ago
    endDate: new Date().toISOString().split("T")[0], // today
  });
  const [updateForm, setUpdateForm] = useState({
    mechNotes: "",
    repairCost: 0,
    assignedTo: "",
    statusID: "",
  });

  // Fetch repairs and mechanics on component mount and when date range changes
  useEffect(() => {
    fetchRepairs();
    fetchMechanics();
  }, [dateRange]);

  const fetchRepairs = async () => {
    try {
      const result = await getVehicleRepairs({
        vehicleID: 0,
        assignedTo: 0,
        startdate: dateRange.startDate,
        enddate: dateRange.endDate,
      });
      setRepairs(result);
    } catch (error) {
      toast.error("Failed to fetch repairs");
    }
  };

  const fetchMechanics = async () => {
    try {
      const result = await getUsers();
      setMechanics(result?.filter((m) => m.Role === "mechanic"));
    } catch (error) {
      toast.error("Failed to fetch mechanics");
    }
  };

  const handleDateChange = (field, value) => {
    setDateRange((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

 
  const handleUpdateSubmit = async () => {
    setLoading(true);
    try {
      const result = await UpdateVehicleRepair({
        repairID: selectedRepair.RepairID,
        assignedTo: updateForm.assignedTo || selectedRepair.AssignedTo,
        mechNotes: updateForm.mechNotes,
        repairCost: updateForm.repairCost,
        statusID: updateForm.statusID || selectedRepair.StatusID,
        BookedDate: updateForm.BookedDate || selectedRepair.BookedDate,
        ReleaseDate: updateForm.ReleaseDate || selectedRepair.ReleaseDate,
        DateClosed: updateForm.DateClosed || selectedRepair.DateClosed,
        ReceivedDate: updateForm.ReceivedDate || selectedRepair.ReceivedDate,
        ReleaseNote: updateForm.ReleaseNote || selectedRepair.ReleaseNote,
      });

      if (result.success) {
        setShowModal(false);
        fetchRepairs();
        toast.success("Added successfully");
      } else {
        toast.error(result.message || "Update failed");
      }
    } catch (error) {
      toast.error("Failed to update repair");
    }
    setLoading(false);
  };

  const handleRepairAction = (repair, action) => {
    setSelectedRepair(repair);
    switch (action) {
      case "assign":
        setUpdateForm({
          ...updateForm,
          statusID: 2,
          assignedTo: "",
        });
        break;
      case "review":
        setUpdateForm({
          ...updateForm,
          statusID: 3,
        });
        break;
      case "approve":
        setUpdateForm({
          ...updateForm,
          statusID: 4,
        });
        break;
      case "book":
        setUpdateForm({
          ...updateForm,
          statusID: 5,
        });
        break;
      case "receive":
        setUpdateForm({
          ...updateForm,
          statusID: 6,
          ReceivedDate: new Date(),
        });
        break;
      case "release":
        setUpdateForm({
          ...updateForm,
          statusID: 7,
          ReleaseDate: new Date(),
        });
        break;
        case "close":
        setUpdateForm({
          ...updateForm,
          statusID: 8,
          DateClosed: new Date(),
        });
        break;
      default:
        // Set a default action or state if no specific action is provided
        setUpdateForm({
          ...updateForm,
          statusID: repair.StatusID || "1", // Default to the current status or "1" for new
          assignedTo: repair.AssignedTo || "", // Default to current assigned mechanic or empty
        });
        break;
    }
    setShowModal(true);
  };

  return (
    <div style={styles.container}>
      <div style={styles.dateRangeContainer}>
        <div style={styles.dateInputGroup}>
          <label style={styles.dateLabel}>Start Date:</label>
          <input
            type="date"
            style={styles.dateInput}
            value={dateRange.startDate}
            onChange={(e) => handleDateChange("startDate", e.target.value)}
            max={dateRange.endDate}
          />
        </div>
        <div style={styles.dateInputGroup}>
          <label style={styles.dateLabel}>End Date:</label>
          <input
            type="date"
            style={styles.dateInput}
            value={dateRange.endDate}
            onChange={(e) => handleDateChange("endDate", e.target.value)}
            min={dateRange.startDate}
          />
        </div>
      </div>

      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Reg No</th>
            <th style={styles.th}>Issue</th>
            <th style={styles.th}>Assigned To</th>
            <th style={styles.th}>Mech Notes</th>
            <th style={styles.th}>Cost</th>
            <th style={styles.th}>Status</th>
            <th style={styles.th}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {repairs.map((repair) => (
            <tr key={repair.RepairID} style={styles.tr}>
              <td style={styles.td}>{repair.VregNO}</td>
              <td style={styles.td}>{repair.RepairDescription}</td>
              <td style={styles.td}>{repair.AssignedToName || "Unassigned"}</td>
              <td style={styles.td}>{repair.MechNotes}</td>
              <td style={styles.td}>
                {repair.RepairCost
                  ? `KES ${repair.RepairCost.toLocaleString()}`
                  : "-"}
              </td>
              <td style={styles.td}>{repair.StatusName}</td>
              <td style={styles.td}>
                {repair.StatusID === 1 && (
                  <button
                    style={styles.actionButton}
                    onClick={() => handleRepairAction(repair, "assign")}
                  >
                    Assign
                  </button>
                )}
                {repair.StatusID === 2 && repair.AssignedTo && (
                  <button
                    style={{
                      ...styles.actionButton,
                      backgroundColor: "#28a745",
                    }}
                    onClick={() => handleRepairAction(repair, "review")}
                  >
                    Review
                  </button>
                )}
                {repair.StatusID === 3 && (
                  <button
                    style={{
                      ...styles.actionButton,
                      backgroundColor: "#007bff",
                    }}
                    onClick={() => handleRepairAction(repair, "approve")}
                  >
                    Approve
                  </button>
                )}
                {repair.StatusID === 4 && (
                  <button
                    style={{
                      ...styles.actionButton,
                      backgroundColor: "#007bff",
                    }}
                    onClick={() => handleRepairAction(repair, "book")}
                  >
                    Book
                  </button>
                )}
                {repair.StatusID === 5 && (
                  <button
                    style={{
                      ...styles.actionButton,
                      backgroundColor: "#007bff",
                    }}
                    onClick={() => handleRepairAction(repair, "receive")}
                  >
                    Receive
                  </button>
                )}
                {repair.StatusID === 6 && (
                  <button
                    style={{
                      ...styles.actionButton,
                      backgroundColor: "#007bff",
                    }}
                    onClick={() => handleRepairAction(repair, "release")}
                  >
                    Release
                  </button>
                )}

                {repair.StatusID === 7 && (
                  <button
                    style={{
                      ...styles.actionButton,
                      backgroundColor: "#007bff",
                    }}
                    onClick={() => handleRepairAction(repair, "close")}
                  >
                    Close
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <h2 style={styles.modalTitle}>Update Repair</h2>

            {updateForm.statusID === 2 && (
              <div style={styles.formGroup}>
                <label style={styles.label}>Assign To:</label>
                <select
                  style={styles.input}
                  value={updateForm.assignedTo}
                  onChange={(e) =>
                    setUpdateForm({ ...updateForm, assignedTo: e.target.value })
                  }
                >
                  <option value="">Select Mechanic</option>
                  {mechanics.map((mechanic) => (
                    <option key={mechanic.UserID} value={mechanic.UserID}>
                      {mechanic.FullName}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {updateForm.statusID === 3 && (
              <div style={styles.formGroup}>
                <label style={styles.label}>Mechanic Notes:</label>
                <textarea
                  style={{ ...styles.input, height: "100px" }}
                  value={updateForm.mechNotes}
                  onChange={(e) =>
                    setUpdateForm({ ...updateForm, mechNotes: e.target.value })
                  }
                />
              </div>
            )}

            {(updateForm.statusID === 4 || updateForm.statusID === 99) && (
              <div style={styles.formGroup}>
                <div>Please approve or reject repairs:</div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    border: "1px solid #e0e0e0",
                    borderRadius: "5px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <label
                    style={{
                      marginRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="approveRepair"
                      checked={updateForm.statusID === 4} // Assuming 5 is for approve
                      onChange={(e) => {
                        setUpdateForm({
                          ...updateForm,
                          statusID: e.target.checked ? 4 : null, // Set to 5 if checked, null if unchecked
                        });
                      }}
                      style={{ marginRight: "5px" }}
                    />
                    Approve
                  </label>

                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="rejectRepair"
                      checked={updateForm.statusID === 99} // Assuming 6 is for reject
                      onChange={(e) => {
                        setUpdateForm({
                          ...updateForm,
                          statusID: e.target.checked ? 99 : null, // Set to 6 if checked, null if unchecked
                        });
                      }}
                      style={{ marginRight: "5px" }}
                    />
                    Reject
                  </label>
                </div>
              </div>
            )}

            {updateForm.statusID === 5 && (
              <div style={styles.formGroup}>
                <label style={styles.label}>Booked Date:</label>
                <input
                  type="date"
                  style={styles.input}
                  value={updateForm.BookedDate}
                  onChange={(e) =>
                    setUpdateForm({ ...updateForm, BookedDate: e.target.value })
                  }
                />
              </div>
            )}

            {updateForm.statusID === 6 && (
              <div style={styles.formGroup}>
                <label style={styles.label}>Date Received:</label>
                <input
                  type="date"
                  style={styles.input}
                  value={updateForm.ReceivedDate}
                  onChange={(e) =>
                    setUpdateForm({
                      ...updateForm,
                      ReceivedDate: e.target.value,
                    })
                  }
                />
              </div>
            )}

            {updateForm.statusID === 7 && (
              <div style={styles.formGroup}>
                <label style={styles.label}>Release Notes:</label>
                <textarea
                  style={{ ...styles.input, height: "100px" }}
                  value={updateForm.ReleaseNote}
                  onChange={(e) =>
                    setUpdateForm({
                      ...updateForm,
                      ReleaseNote: e.target.value,
                    })
                  }
                />
              </div>
            )}

            {updateForm.statusID === 8 && (
              <div style={styles.formGroup}>
                <label style={styles.label}>Repair Cost:</label>
                <input
                  type="number"
                  style={styles.input}
                  value={updateForm.repairCost}
                  onChange={(e) =>
                    setUpdateForm({ ...updateForm, repairCost: e.target.value })
                  }
                />
              </div>
            )}

            <div style={styles.modalButtons}>
              <button
                style={{ ...styles.button, backgroundColor: "#007bff" }}
                onClick={handleUpdateSubmit}
                disabled={loading}
              >
                {loading ? "Updating..." : "Submit"}
              </button>
              <button
                style={{ ...styles.button, backgroundColor: "#6c757d" }}
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    background: "white",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    fontSize: "14px",
    textAlign: "left",
  },
  th: {
    padding: "12px",
    backgroundColor: "#f8f9fa",
    borderBottom: "2px solid #dee2e6",
    fontWeight: "600",
  },
  td: {
    padding: "12px",
    borderBottom: "1px solid #dee2e6",
  },
  tr: {
    ":hover": {
      backgroundColor: "#f8f9fa",
    },
  },
  actionButton: {
    padding: "6px 12px",
    backgroundColor: "#6c757d",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: "8px",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    background: "white",
    padding: "24px",
    borderRadius: "8px",
    width: "90%",
    maxWidth: "500px",
    maxHeight: "90vh",
    overflow: "auto",
  },
  modalTitle: {
    margin: "0 0 20px",
    fontSize: "20px",
    fontWeight: "600",
  },
  formGroup: {
    marginBottom: "16px",
  },
  label: {
    display: "block",
    marginBottom: "8px",
    fontWeight: "500",
  },
  input: {
    width: "100%",
    padding: "8px 12px",
    border: "1px solid #ced4da",
    borderRadius: "4px",
    fontSize: "14px",
  },
  modalButtons: {
    display: "flex",
    gap: "12px",
    justifyContent: "flex-end",
    marginTop: "24px",
  },
  button: {
    padding: "8px 16px",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
  },
  dateRangeContainer: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
  },
  dateInputGroup: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  dateLabel: {
    fontWeight: "500",
    fontSize: "14px",
  },
  dateInput: {
    padding: "8px 12px",
    border: "1px solid #ced4da",
    borderRadius: "4px",
    fontSize: "14px",
  },
};

export default RepairTable;
