/* eslint-disable jsx-a11y/no-static-element-interactions */
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BarChartIcon from "@mui/icons-material/BarChart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import TableChartIcon from "@mui/icons-material/TableChart";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ColorContext } from "../../ColorContext/darkContext";
import waveline from "../../Images/waveline.jpg";
import "./Sidebar.scss";

function Sidebar() {
  // color state management using react context
  const { darkMode, dispatch } = useContext(ColorContext);

  return (
    <div className="sidebar">
      <div className="logo">
        <Link to="/driver/dashboard" style={{ textDecoration: "none" }}>
          {/* <h3 className="text_none">Waveline Commercial</h3> */}
          <img className="logo-image" src={waveline} alt="logo" />
        </Link>
      </div>

      <div className="links">
        <ul>
          <p className="spann">Main</p>
          <Link to="/admin/dashboard" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" /> Dashboard
            </li>
          </Link>

          <p className="spann">lists</p>
          <Link to="/admin/orders" style={{ textDecoration: "none" }}>
            <li>
              <PersonIcon className="icon" /> Allocations
            </li>
          </Link>

          <Link to="/admin/safety" style={{ textDecoration: "none" }}>
            <li>
              <TableChartIcon className="icon" /> Safety
            </li>
          </Link>
          <Link to="/admin/maintenance" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" /> Maintenence
            </li>
          </Link>
          <Link to="/admin/checklist" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" /> Checklist
            </li>
          </Link>
          <li>
            <CreditCardIcon className="icon" /> Fueling
          </li>
          <Link to="/admin/accident" style={{ textDecoration: "none" }}>
            <li>
              <BarChartIcon className="icon" /> Accidents
            </li>
          </Link>
          <p className="spann">Seetings</p>
          <Link to="/admin/users" style={{ textDecoration: "none" }}>
            <li>
              <AccountCircleIcon className="icon" /> Users
            </li>
          </Link>
          <Link to="/admin/vehicle" style={{ textDecoration: "none" }}>
            <li>
              <SettingsRoundedIcon className="icon" /> Vehicles
            </li>
          </Link>
          <Link to="/admin/customers" style={{ textDecoration: "none" }}>
            <li>
              <SettingsRoundedIcon className="icon" /> Customers
            </li>
          </Link>
          <Link to="/logout" style={{ textDecoration: "none" }}>
            <li>
              <LogoutIcon className="icon" /> Log Out
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
