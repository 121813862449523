import React, { useState } from "react";
import { toast } from "react-toastify";
import { AddAccidentRecord, AddDriveImage } from "../../api/apirequest";

const AddAccidentForm = ({ setShowForm, setShowList }) => {
  const details = JSON.parse(localStorage.getItem("user"));
  const [newAccident, setNewAccident] = useState({
    accidentID: 0,
    vehicleID: details?.data?.vehicleID,
    accidentDescription: "",
    thirdPartyVehicle: "",
    thirdPartyInsurance: "",
    accidentLocation: "",
    dateAdded: new Date().toISOString(),
    statusID: 0,
    addedBy: details?.data?.userID,
    images: [],
  });

  const [imageInputs, setImageInputs] = useState(
    [
      "Police Abstract",
      "3rd Party DL",
      "3rd Party Insurance",
      "Driver DL",
      ...Array(4)
        .fill()
        .map((_, i) => `Scene Photo ${i + 1}`),
    ].map((description) => ({ description, file: null }))
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      setImageInputs((prevInputs) =>
        prevInputs.map((input, i) => (i === index ? { ...input, file } : input))
      );
    }
  };

  const uploadImages = async () => {
    const uploadedImages = [];
    for (const input of imageInputs) {
      if (input.file) {
        const reader = new FileReader();
        const fileName = new Date().toISOString();
        const base64Image = await new Promise((resolve, reject) => {
          reader.onload = () => resolve(reader.result);
          reader.onerror = () => reject(reader.error);
          reader.readAsDataURL(input.file);
        });

        try {
          const imageResult = await AddDriveImage({ base64Image, fileName });
          if (imageResult?.FileId) {
            uploadedImages.push({
              accidentImageID: 0,
              accidentID: 0,
              imageType: 0,
              imageID: imageResult.FileId,
              Description: input.description,
              dateAdded: new Date().toISOString(),
            });

            // Show a toast message for the uploaded file
            toast.success(`${input.description} added!`);
          } else {
            throw new Error(`Failed to upload ${input.description}`);
          }
        } catch (error) {
          // Show an error message if upload fails
          toast.error(`Error uploading ${input.description}: ${error.message}`);
        }
      }
    }
    return uploadedImages;
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");

    try {
      const uploadedImages = await uploadImages();
      const accidentData = { ...newAccident, images: uploadedImages };
      const result = await AddAccidentRecord(accidentData);

      if (result?.success) {
        toast.success(result.message || "Accident successfully recorded!");
        setShowForm(false);
        setShowList(true);
      } else {
        throw new Error(result?.message || "Error adding accident.");
      }
    } catch (error) {
      console.error(error);
      setError(
        error.message || "Error processing your request. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const addAccident = () => {
    if (
      newAccident.accidentDescription &&
      newAccident.accidentLocation &&
      newAccident.thirdPartyVehicle &&
      newAccident.thirdPartyInsurance
    ) {
      handleSubmit();
    } else {
      setError("Please fill in all required fields.");
    }
  };

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
        {error && <div style={styles.error}>{error}</div>}

        <div style={styles.formGroup}>
          <label style={styles.formLabel}>Accident Description</label>
          <textarea
            value={newAccident.accidentDescription}
            onChange={(e) =>
              setNewAccident((prev) => ({
                ...prev,
                accidentDescription: e.target.value,
              }))
            }
            style={styles.textArea}
            placeholder="Describe the accident..."
          ></textarea>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.formLabel}>Accident Location</label>
          <input
            type="text"
            value={newAccident.accidentLocation}
            onChange={(e) =>
              setNewAccident((prev) => ({
                ...prev,
                accidentLocation: e.target.value,
              }))
            }
            style={styles.input}
            placeholder="Enter accident location"
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.formLabel}>Third Party Vehicle</label>
          <input
            type="text"
            value={newAccident.thirdPartyVehicle}
            onChange={(e) =>
              setNewAccident((prev) => ({
                ...prev,
                thirdPartyVehicle: e.target.value,
              }))
            }
            style={styles.input}
            placeholder="Enter third-party vehicle details"
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.formLabel}>Third Party Insurance</label>
          <input
            type="text"
            value={newAccident.thirdPartyInsurance}
            onChange={(e) =>
              setNewAccident((prev) => ({
                ...prev,
                thirdPartyInsurance: e.target.value,
              }))
            }
            style={styles.input}
            placeholder="Enter third-party insurance details"
          />
        </div>

        {imageInputs.map((input, index) => (
          <div key={index} style={styles.formGroup}>
            <label style={styles.formLabel}>{input.description}</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, index)}
            />
          </div>
        ))}

        <button
          disabled={loading}
          onClick={addAccident}
          style={{
            ...styles.button,
            backgroundColor: loading ? "grey" : "#007BFF",
          }}
        >
          {loading ? "Submitting..." : "Add Accident"}
        </button>
      </form>
    </div>
  );
};

const styles = {
  container: {
    margin: "0 auto",
    padding: "5px",
    fontFamily: "Arial, sans-serif",
    marginBottom: "80px",
  },
  form: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "20px",
    backgroundColor: "#f9f9f9",
  },
  formTitle: {
    marginBottom: "20px",
    fontSize: "24px",
    fontWeight: "bold",
  },
  formGroup: {
    marginBottom: "15px",
  },
  formLabel: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  input: {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  textArea: {
    width: "100%",
    height: "60px",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  fileInput: {
    width: "100%",
  },
  button: {
    padding: "10px 20px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007BFF",
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
  },
  error: {
    color: "red",
    marginBottom: "10px",
    fontWeight: "bold",
    fontSize: "14px",
  },
};

export default AddAccidentForm;
