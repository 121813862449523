import React, { useEffect, useState } from "react";
import TableHeader from "../../Components/DataTable/TableHeader";
import MultiImageViewer from "../../Components/ImageViewer/MultiImageViewer";
import { getAccidentImages, getAccidentRecords } from "../../api/apiGetrequest";
import AddAccidentForm from "./AddAccidentForm";

const AccidentsPage = () => {
  const [accidents, setAccidents] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showList, setShowList] = useState(true);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [selected, setSelected] = useState(null);

  const handleViewImages = (row) => {
    setSelected(row);
    setOpen(true);
  };

  function formatDate(date) {
    return date.toISOString().split("T")[0];
  }

  const details = JSON.parse(localStorage.getItem("user"));

  const today = new Date();
  const last7Days = new Date(today);
  last7Days.setDate(today.getDate() - 14);
  const startdate = formatDate(last7Days);
  const enddate = formatDate(today);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAccidentRecords({
          UserID: details?.data?.userID || 0,
          startDate: startdate,
          enddate: enddate,
        });
        setAccidents(result);
      } catch (error) {
        console.error("Error fetching accidents:", error);
      }
    };

    fetchData();
  }, [details?.data?.userID, enddate, startdate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAccidentImages(selected?.AccidentID);
        setImages(result);
      } catch (error) {
        console.error("Error fetching accidents:", error);
      }
    };

    fetchData();
  }, [selected]);



  return (
    <div style={styles.container}>
      <TableHeader
        title={showList ? "Accident Records" : "Add Accident Record"}
        showAddButton={showList}
        showBackButton={!showList}
        onBack={() => {
          setShowForm(false);
          setShowList(true);
        }}
        onAdd={() => {
          setShowForm(true);
          setShowList(false);
        }}
        showPrintButton={false}
        showExcelButton={false}
        showPdfButton={false}
      />
      {showList && (
        <div>
          <div style={styles.cardContainer}>
            {accidents.map((accident) => (
              <div key={accident.AccidentID} style={styles.card}>
                <div style={styles.cardHeader}>
                  <h2 style={styles.vehicleNumber}>{accident.VregNO}</h2>
                  <div style={styles.headerActions}>
                    <button
                      style={styles.imageButton}
                      onClick={() => handleViewImages(accident)}
                    >
                      View Images
                    </button>
                  </div>
                </div>

                <div style={styles.cardBody}>
                  <div style={styles.descriptionSection}>
                    <p style={styles.description}>
                      {accident.AccidentDescription}
                    </p>
                  </div>

                  <div style={styles.infoGrid}>
                    <div style={styles.infoItem}>
                      <span style={styles.iconWrapper}>📅</span>
                      <div>
                        <span style={styles.label}>Date</span>
                        <p style={styles.value}>
                          {new Date(accident.DateAdded).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                    <div style={styles.infoItem}>
                      <span style={styles.iconWrapper}>📍</span>
                      <div>
                        <span style={styles.label}>Location</span>
                        <p style={styles.value}>{accident.AccidentLocation}</p>
                      </div>
                    </div>
                  </div>

                  <div style={styles.thirdPartySection}>
                    <h3 style={styles.sectionTitle}>Third Party Details</h3>
                    <div style={styles.infoGrid}>
                      <div style={styles.infoItem}>
                        <span style={styles.iconWrapper}>🚗</span>
                        <div>
                          <span style={styles.label}>Number Plate</span>
                          <p style={styles.value}>
                            {accident.ThirdPartyVehicle}
                          </p>
                        </div>
                      </div>
                      <div style={styles.infoItem}>
                        <span style={styles.iconWrapper}>🛡️</span>
                        <div>
                          <span style={styles.label}>Insurance</span>
                          <p style={styles.value}>
                            {accident.ThirdPartyInsurance}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span style={{ ...styles.badge, marginTop: "7px" }}>
                  {accident.AddedByName}
                </span>
              </div>
            ))}
          </div>
          <MultiImageViewer
            open={open}
            onClose={() => setOpen(false)}
            images={images}
          />
        </div>
      )}

      {showForm && <AddAccidentForm />}
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#f8fafc",
    width: "100%",
    padding: "5px",
  },
  cardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    gap: "20px",
    width: "100%",
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: "16px",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    overflow: "hidden",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: "20px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  vehicleNumber: {
    fontSize: "1.5rem",
    fontWeight: "600",
    color: "#1a202c",
  },
  headerActions: {
    display: "flex",
    gap: "10px",
  },
  imageButton: {
    backgroundColor: "#3182ce",
    color: "#ffffff",
    border: "none",
    padding: "5px 10px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  badge: {
    backgroundColor: "#e2e8f0",
    padding: "5px 10px",
    borderRadius: "16px",
    fontSize: "0.875rem",
    color: "#4a5568",
  },
  cardBody: {
    flex: "1",
  },
  descriptionSection: {
    marginBottom: "10px",
  },
  description: {
    fontSize: "1rem",
    color: "#4a5568",
  },
  infoGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "10px",
  },
  infoItem: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  iconWrapper: {
    fontSize: "1.5rem",
    color: "#4a5568",
  },
  label: {
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "#4a5568",
  },
  value: {
    fontSize: "1rem",
    color: "#2d3748",
  },
  thirdPartySection: {
    marginTop: "20px",
  },
  sectionTitle: {
    fontSize: "1.25rem",
    fontWeight: "600",
    marginBottom: "10px",
    color: "#1a202c",
  },
  formWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#edf2f7",
    padding: "20px",
  },
  form: {
    backgroundColor: "#ffffff",
    borderRadius: "16px",
    padding: "8px",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    width: "100%",
    maxWidth: "500px",
  },
  formTitle: {
    fontSize: "1.5rem",
    fontWeight: "600",
    marginBottom: "20px",
    color: "#1a202c",
  },
  formGroup: {
    marginBottom: "15px",
  },
  formLabel: {
    fontSize: "0.875rem",
    fontWeight: "500",
    marginBottom: "5px",
    display: "block",
    color: "#4a5568",
  },
  textArea: {
    width: "100%",
    borderRadius: "8px",
    padding: "10px",
    fontSize: "1rem",
    border: "1px solid #e2e8f0",
    color: "#2d3748",
  },
  inputField: {
    width: "100%",
    borderRadius: "8px",
    padding: "10px",
    fontSize: "1rem",
    border: "1px solid #e2e8f0",
    color: "#2d3748",
  },
  fileInput: {
    display: "block",
    fontSize: "1rem",
    margin: "10px 0",
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  },
  submitButton: {
    backgroundColor: "#3182ce",
    color: "#ffffff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "1rem",
    transition: "background-color 0.3s ease",
  },
  cancelButton: {
    backgroundColor: "#e2e8f0",
    color: "#4a5568",
    padding: "10px 20px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "1rem",
  },
  modal: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1000",
  },
  modalContent: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    padding: "20px",
    position: "relative",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
  },
  imageGallery: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
    gap: "10px",
  },
  galleryImage: {
    width: "100%",
    height: "auto",
    borderRadius: "8px",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "#e2e8f0",
    border: "none",
    borderRadius: "50%",
    padding: "5px",
    cursor: "pointer",
    fontSize: "1.5rem",
  },
};

export default AccidentsPage;
