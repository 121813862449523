import React from "react";

const VehicleChecklist = ({ initialData }) => {
  // Parse the CheckListDetail string immediately
  const checklistDetails = JSON.parse(initialData.CheckListDetail);

  const containerStyle = {
    width: "70%",
    padding: "20px",
  };

  const headerStyle = {
    textAlign: "center",
    textDecoration: "underline",
    marginBottom: "20px",
  };

  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "20px",
    marginBottom: "20px",
  };

  const inputStyle = {
    width: "100%",
    border: "1px solid #ccc",
    padding: "5px",
    borderRadius: "4px",
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  };

  const thStyle = {
    border: "1px solid #000",
    padding: "10px",
    backgroundColor: "#f0f0f0",
  };

  const tdStyle = {
    border: "1px solid #000",
    padding: "10px",
  };

  const checkboxCellStyle = {
    border: "1px solid #000",
    textAlign: "center",
  };

  const checkboxStyle = {
    transform: "scale(1.5)",
  };

  const InputSection = ({ label, value }) => (
    <div>
      <label style={{ display: "block", marginBottom: "5px" }}>{label}</label>
      <input type="text" value={value} readOnly style={inputStyle} />
    </div>
  );

  return (
    <div style={containerStyle}>
      <h1 style={headerStyle}>DAILY VEHICLE CHECKLIST</h1>

      <div style={gridContainerStyle}>
        <InputSection
          label="INSPECTOR NAME"
          value={initialData.AddedByFullName}
        />
        <InputSection
          label="DATE"
          value={initialData.DateAdded.split("T")[0]}
        />
        <InputSection label="REG. NO." value={initialData.VregNO} />
        <InputSection label="STATUS" value={initialData.StatusName} />
      </div>

      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>ITEM</th>
            <th style={{ ...thStyle, width: "10%" }}>OK</th>
            <th style={{ ...thStyle, width: "10%" }}>NOT OK</th>
            <th style={thStyle}>REMARKS</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(checklistDetails).map(([section, items]) =>
            items.map((item) => (
              <tr key={item.CheckListItemID}>
                <td style={{ ...tdStyle, textTransform: "uppercase" }}>
                  {item.ItemName.replace(/([A-Z])/g, " $1").trim()}
                </td>
                <td style={checkboxCellStyle}>
                  <input
                    type="checkbox"
                    checked={item.status}
                    readOnly
                    style={checkboxStyle}
                  />
                </td>
                <td style={checkboxCellStyle}>
                  <input
                    type="checkbox"
                    checked={!item.status}
                    readOnly
                    style={checkboxStyle}
                  />
                </td>
                <td style={tdStyle}>
                  <input
                    type="text"
                    value={item.comment}
                    readOnly
                    style={inputStyle}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default VehicleChecklist;
