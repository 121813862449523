import React, { useEffect, useState } from "react";

const ElegantClock = () => {
  const [time, setTime] = useState(new Date());
  const username = "John Doe";
  const vehicle = "Vehicle A";

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const seconds = time.getSeconds();
  const minutes = time.getMinutes();
  const hours = time.getHours();
  const dayOfWeek = time.toLocaleString("en-US", { weekday: "long" });
  const date = time.toLocaleString("en-US", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  const secondAngle = (seconds / 60) * 360;
  const minuteAngle = (minutes / 60) * 360 + (seconds / 60) * 6;
  const hourAngle = (hours % 12) * 30 + (minutes / 60) * 30;

  // Define all radius measurements
  const CLOCK_SIZE = 300;
  const OUTER_RADIUS = CLOCK_SIZE / 2;
  const NUMBER_RADIUS = OUTER_RADIUS * 0.75;
  const MARKER_START_RADIUS = OUTER_RADIUS * 0.95;

  const details = JSON.parse(localStorage.getItem("user"));

  // Generate hour numbers
  const hourNumbers = [];
  for (let i = 1; i <= 12; i++) {
    const angle = ((i / 12) * 360 - 90) * (Math.PI / 180);
    const x = Math.cos(angle) * NUMBER_RADIUS;
    const y = Math.sin(angle) * NUMBER_RADIUS;

    hourNumbers.push(
      <div
        key={i}
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
          color: "#94a3b8",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {i}
      </div>
    );
  }

  // Generate minute markers
  const minuteMarkers = [];
  for (let i = 0; i < 60; i++) {
    const isHourMark = i % 5 === 0;
    const angle = i * 6;
    const markerHeight = isHourMark ? 15 : 8;

    minuteMarkers.push(
      <div
        key={i}
        style={{
          position: "absolute",
          width: isHourMark ? "2px" : "1px",
          height: `${markerHeight}px`,
          backgroundColor: isHourMark ? "#94a3b8" : "#64748b",
          left: "50%",
          top: "50%",
          transformOrigin: "50% 50%",
          transform: `translate(-50%, -50%) rotate(${angle}deg) translateY(-${MARKER_START_RADIUS}px)`,
        }}
      />
    );
  }

  // Base styles
  const containerStyles = {
    minHeight: "100vh",
    background: "linear-gradient(to bottom, #0f172a, #1e293b)",
    color: "white",
    padding: "20px",
  };

  const clockFaceStyles = {
    position: "relative",
    width: `${CLOCK_SIZE}px`,
    height: `${CLOCK_SIZE}px`,
    margin: "0 auto",
  };

  return (
    <div style={containerStyles}>
      {/* Clock Face */}
      <div style={clockFaceStyles}>
        {/* Outer ring */}
        <div
          style={{
            position: "absolute",
            inset: 0,
            borderRadius: "50%",
            background:
              "linear-gradient(135deg, rgba(59, 130, 246, 0.2), rgba(167, 139, 250, 0.2))",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(12px)",
          }}
        >
          {/* Inner clock face */}
          <div
            style={{
              position: "absolute",
              inset: "16px",
              borderRadius: "50%",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(8px)",
            }}
          >
            {minuteMarkers}
            {hourNumbers}

            {/* Hour Hand */}
            <div
              style={{
                position: "absolute",
                width: "6px",
                height: `${OUTER_RADIUS * 0.5}px`,
                background: "linear-gradient(to top, #3b82f6, #60a5fa)",
                left: "50%",
                top: "50%",
                borderRadius: "9999px",
                transformOrigin: "50% calc(100% - 2px)",
                transform: `translate(-50%, -100%) rotate(${hourAngle}deg)`,
                boxShadow: "0 0 8px rgba(59, 130, 246, 0.5)",
              }}
            />

            {/* Minute Hand */}
            <div
              style={{
                position: "absolute",
                width: "4px",
                height: `${OUTER_RADIUS * 0.65}px`,
                background: "linear-gradient(to top, #8b5cf6, #a78bfa)",
                left: "50%",
                top: "50%",
                borderRadius: "9999px",
                transformOrigin: "50% calc(100% - 2px)",
                transform: `translate(-50%, -100%) rotate(${minuteAngle}deg)`,
                boxShadow: "0 0 8px rgba(139, 92, 246, 0.5)",
              }}
            />

            {/* Second Hand */}
            <div
              style={{
                position: "absolute",
                width: "2px",
                height: `${OUTER_RADIUS * 0.75}px`,
                backgroundColor: "#f87171",
                left: "50%",
                top: "50%",
                borderRadius: "9999px",
                transformOrigin: "50% calc(100% - 2px)",
                transform: `translate(-50%, -100%) rotate(${secondAngle}deg)`,
                boxShadow: "0 0 8px rgba(248, 113, 113, 0.5)",
              }}
            />

            {/* Center Pin */}
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                width: "16px",
                height: "16px",
                background: "linear-gradient(135deg, #3b82f6, #8b5cf6)",
                borderRadius: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 10,
                boxShadow: "0 0 8px rgba(59, 130, 246, 0.5)",
              }}
            />
          </div>
        </div>
      </div>

      {/* Digital Time */}
      <div style={{ textAlign: "center", marginTop: "32px" }}>
        <div
          style={{
            fontSize: "2.25rem",
            fontWeight: "300",
            letterSpacing: "0.05em",
          }}
        >
          {time.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          })}
        </div>
      </div>

      {/* Date and Day */}
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        <div
          style={{
            fontSize: "1.25rem",
            fontWeight: "300",
            letterSpacing: "0.05em",
            color: "#94a3b8",
          }}
        >
          {dayOfWeek}, {date}
        </div>
      </div>

      {/* Name and Vehicle */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold", // Bold and capitalized
            letterSpacing: "0.05em",
            color: "#94a3b8",
            textTransform: "uppercase", // Capitalize
          }}
        >
          {details?.data?.fullName}
        </div>
        <div
          style={{
            fontSize: "1.25rem",
            fontWeight: "bold", // Make the vehicle bold
            letterSpacing: "0.05em",
            textTransform: "uppercase", // Make the vehicle capitalized
            color: "#fbbf24", // Give the vehicle a standout color
            marginTop: "8px",
          }}
        >
          {details?.data?.vehicleRegNo}
        </div>
      </div>
    </div>
  );
};

export default ElegantClock;
