import React, { useState } from "react";
import { toast } from "react-toastify";
import { AddCustomer } from "../../api/apirequest";

function CustomerForm({ setNewpo }) {
  const details = JSON.parse(localStorage.getItem("user"));
  const [formData, setFormData] = useState({
    customerID: 0,
    customerName: "",
    address: "",
    location: "",
    addedBy: details?.data?.userID,
    dateAdded: new Date().toISOString(),
    statusID: 1,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await AddCustomer(formData);

      if (result && result.success) {
        toast.success(result.message || "Customer successfully added!");
        setNewpo(); // Reset the form state or perform any other action
      } else {
        toast.error(result.message || "An error occurred while adding the customer.");
      }
    } catch (error) {
      console.error(error);
      toast.error("There was an error processing your request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>     
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.row}>
          <label style={styles.label}>
            Customer Name:
            <input
              type="text"
              name="customerName"
              value={formData.customerName}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
          <label style={styles.label}>
            Address:
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              style={styles.input}
            />
          </label>
        </div>
        <div style={styles.row}>
          <label style={styles.label}>
            Location:
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
              style={styles.input}
            />
          </label>          
        </div>
        
        <button disabled={loading} type="submit" style={styles.button}>
          {loading ? "Submitting..." : "Submit Customer"}
        </button>
      </form>
    </div>
  );
}

const styles = {
  container: {
    margin: "20px auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  label: {
    flex: "1",
    marginRight: "10px",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    padding: "8px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    marginTop: "5px",
  },
  button: {
    padding: "10px 15px",
    fontSize: "16px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default CustomerForm;