import React, { useState } from "react";
import { toast } from "react-toastify";
import { getUsers, getVehicleRepairs } from "../../api/apiGetrequest";
import TableHeader from "../../Components/DataTable/TableHeader";
import RepairCards from "./Maintenance";

const styles = {
  dashboard: `
    .dashboard-container {
      background: #1a1a1a;
      min-height: 100vh;
      color: #e0e0e0;
      font-family: -apple-system, system-ui, sans-serif;
      padding: 2rem;
    }
    .header {
      background: linear-gradient(90deg, #2d2d2d 0%, #1a1a1a 100%);
      border-bottom: 1px solid #3d3d3d;
      padding: 1rem 0.5rem;
      text-align: center;
    }
    .stats-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      margin-top: 2rem;
    }
    .stat-card {
      background: linear-gradient(145deg, #2a2a2a, #232323);
      border: 1px solid #3d3d3d;
      border-radius: 8px;
      padding: 1rem;
      text-align: center;
      transition: all 0.3s ease;
    }
    .stat-card:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0,0,0,0.2);
    }
    .stat-title {
      color: #ffffff; /* Changed to white for better visibility */
      font-size: 1rem; /* Increased font size */
      margin-bottom: 0.5rem;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.5); /* Added text shadow for contrast */
    }
    .stat-value {
      font-size: 2.5rem; /* Increased font size */
      font-weight: 700;
      color: #ffffff; /* Changed to white for better visibility */
      text-shadow: 1px 1px 2px rgba(0,0,0,0.5); /* Added text shadow for contrast */
    }
    .info-card {
      background: linear-gradient(145deg, #2a2a2a, #232323);
      border: 1px solid #3d3d3d;
      border-radius: 8px;
      padding: 1rem;
      margin-top: 1rem;
      text-align: center;
    }
    .info-title {
      color: #ffffff; /* Changed to white for better visibility */
      font-size: 1.25rem; /* Increased font size */
      text-shadow: 1px 1px 2px rgba(0,0,0,0.5); /* Added text shadow for contrast */
    }
    .info-value {
      font-size: 2rem; /* Increased font size */
      color: #ffffff; /* Changed to white for better visibility */
      margin-top: 0.25rem;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.5); /* Added text shadow for contrast */
    }
    .chart-container {
      background: #232323;
      border-radius: 8px;
      border: 1px solid #3d3d3d;
      margin-top: 1rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .bar-chart {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 1rem;
    }
    .bar-group {
      width: 40px;
      text-align: center;
    }
    .bar {
      width: 100%;
      background: linear-gradient(180deg, #007AFF, #00F2FE);
      border-radius: 4px 4px 0 0;
      transition: height 0.3s ease;
    }
    .bar-label {
      margin-top: 0.25rem;
      color: #ffffff; /* Changed to white for better visibility */
      font-size: 1rem; /* Increased font size */
      text-shadow: 1px 1px 2px rgba(0,0,0,0.5); /* Added text shadow for contrast */
    }
  `,
};

const MechanicDashboard = () => {
  const [maintenance, setMaintenece] = useState();
  const [repairs, setRepairs] = useState([]);
  const [mechanics, setMechanics] = useState([]);
  const [Status, setStatus] = useState(1);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0], // 7 days ago
    endDate: new Date().toISOString().split("T")[0], // today
  });

  React.useEffect(() => {
    fetchRepairs();
    fetchMechanics();
  }, [dateRange]);

  const fetchRepairs = async () => {
    try {
      const result = await getVehicleRepairs({
        vehicleID: 0,
        assignedTo: 0,
        startdate: dateRange.startDate,
        enddate: dateRange.endDate,
      });
      setRepairs(result);
    } catch (error) {
      toast.error("Failed to fetch repairs");
    }
  };

  const fetchMechanics = async () => {
    try {
      const result = await getUsers();
      setMechanics(result?.filter((m) => m.Role === "mechanic"));
    } catch (error) {
      toast.error("Failed to fetch mechanics");
    }
  };

  const data = {
    stats: [
      {
        title: "New Orders",
        value: repairs?.filter((r) => r.StatusID < 6).length,
      },
      {
        title: "Work in Progress",
        value: repairs?.filter((r) => r.StatusID === 6).length,
      },
      {
        title: "Completed",
        value: repairs?.filter((r) => r.StatusID === 8).length,
      },
    ],
    averageJobTime: {
      title: "Average Job Time",
      value: "80%",
      description: "vs plan",
    },
    rework: {
      title: "Rework",
      value: "7%",
    },
    weeklyPerformance: [
      { day: "M", value: 20 },
      { day: "T", value: 40 },
      { day: "W", value: 60 },
      { day: "T", value: 80 },
      { day: "F", value: 100 },
    ],
  };

  if (maintenance) {
    const filteredRepairs =
      Status === 1
        ? repairs?.filter((r) => r.StatusID < 6)
        : Status === 6
        ? repairs?.filter((r) => r.StatusID === 6 || r.StatusID === 7)
        : Status === 8
        ? repairs?.filter((r) => r.StatusID === 8)
        : [];

    return (
      <>
        <TableHeader
          title={"Repair List"}
          showAddButton={false}
          showBackButton={true}
          onBack={() => setMaintenece(null)}
          showPrintButton={false}
          showExcelButton={false}
          showPdfButton={false}
        />
        <RepairCards
          repairs={filteredRepairs}
          mechanics={mechanics}
          fetchRepairs={fetchRepairs}
        />
      </>
    );
  }

  return (
    <>
      <style>{styles.dashboard}</style>
      <div className="dashboard-container">
        <header className="header">
          <h1
            style={{
              fontSize: "1.5rem",
              fontWeight: "700",
              margin: 0,
              color: "#ffffff",
              textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
            }}
          >
            Mechanic Performance Hub
          </h1>
        </header>

        <div className="stats-grid">
          {data.stats.map((stat, i) => (
            <div
              key={i}
              className="stat-card"
              onClick={() => {
                setMaintenece(true);
                setStatus(i === 0 ? 1 : i === 1 ? 6 : i === 2 ? 8 : 0);
              }}
            >
              <div className="stat-title">{stat.title}</div>
              <div className="stat-value">{stat.value}</div>
            </div>
          ))}
        </div>

        <div className="info-card">
          <div className="info-title">{data.averageJobTime.title}</div>
          <div className="info-value">
            {data.averageJobTime.value} {data.averageJobTime.description}
          </div>
        </div>

        <div className="info-card">
          <div className="info-title">{data.rework.title}</div>
          <div className="info-value">{data.rework.value}</div>
        </div>

        <div className="chart-container">
          <h2
            style={{
              margin: "0 0 1rem",
              fontSize: "1.25rem",
              color: "#ffffff",
              textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
            }}
          >
            Weekly Performance
          </h2>
          <div className="bar-chart">
            {data.weeklyPerformance.map((day, i) => (
              <div key={i} className="bar-group">
                <div className="bar" style={{ height: `${day.value}px` }} />
                <div className="bar-label">{day.day}</div>
              </div>
            ))}
          </div>
        </div>
        {/* <FloatingActionButton /> */}
      </div>
    </>
  );
};

export default MechanicDashboard;
