import React, { useEffect, useState } from "react";
import { getVehicleChecklist } from "../../api/apiGetrequest";
import TableHeader from "../../Components/DataTable/TableHeader";
import InspectionChecklist from "./CheckListItem";

const VehicleCard = () => {
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [filterStatus, setFilterStatus] = useState("all");
  const [vehicles, setVehicles] = useState([]);

  const formatDate = (date) => date.toISOString().split("T")[0];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getVehicleChecklist(
          formatDate(new Date()),
          formatDate(new Date()),
          0
        );
        setVehicles(result?.filter((v) => v.StatusName !== "Pending") || []);
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    };

    fetchData();
  }, []);

  if (selectedVehicle) {
    return (
      <div style={styles.selectedVehicleContainer}>
        <TableHeader
          title={selectedVehicle?.VregNO}
          showAddButton={false}
          showBackButton={true}
          onBack={() => setSelectedVehicle(null)}
          showPrintButton={false}
          showExcelButton={false}
          showPdfButton={false}
        />
        <InspectionChecklist data={selectedVehicle} />
      </div>
    );
  }

  const getChecklistItems = (vehicle) => {
    if (!vehicle.CheckListDetail) return [];

    const details =
      typeof vehicle.CheckListDetail === "string"
        ? JSON.parse(vehicle.CheckListDetail)
        : vehicle.CheckListDetail;

    return Object.keys(details).flatMap((category) =>
      details[category].map((item) => ({ category, ...item }))
    );
  };

  const formatItemName = (name) =>
    name.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());

  return (
    <div style={styles.container}>
      <div style={styles.filterContainer}>
        {["all", "okay", "notOkay"].map((status) => (
          <button
            key={status}
            onClick={() => setFilterStatus(status)}
            style={{
              ...styles.filterButton,
              backgroundColor:
                filterStatus === status
                  ? {
                      all: "#3498db",
                      okay: "#2ecc71",
                      notOkay: "#e74c3c",
                    }[status]
                  : "#f1f1f1",
              color: filterStatus === status ? "#fff" : "#333",
            }}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </button>
        ))}
      </div>

      {vehicles
        .filter((vehicle) => {
          if (filterStatus === "all") return true;
          if (filterStatus === "okay") return vehicle.VehicleStatus === "Okay";
          if (filterStatus === "notOkay")
            return vehicle.VehicleStatus !== "Okay";
          return true;
        })
        .map((vehicle) => (
          <div key={vehicle.VehicleID} style={styles.card}>
            <div
              style={styles.cardHeader}
              onClick={() =>
                setSelectedVehicle(
                  vehicle.VehicleID === selectedVehicle?.VehicleID
                    ? null
                    : vehicle
                )
              }
            >
              <div>
                <h3 style={styles.vehicleNumber}>{vehicle.VregNO}</h3>
                <p style={styles.status}>
                  Status:{" "}
                  <span style={styles.statusSpan(vehicle.VehicleStatus)}>
                    {vehicle.VehicleStatus}
                  </span>
                </p>
              </div>
              <span style={styles.expandIcon}>
                {selectedVehicle === vehicle ? "▼" : "▶"}
              </span>
            </div>

            {selectedVehicle === vehicle && (
              <div style={styles.cardContent}>
                {getChecklistItems(vehicle).map((item, index) => (
                  <div key={index} style={styles.checklistItem}>
                    <div style={styles.itemHeader}>
                      <span style={styles.category}>{item.category}</span>
                      <span style={styles.itemStatus(item.status)}>
                        {item.status ? "OK" : "Issue"}
                      </span>
                    </div>
                    <h4 style={styles.itemName}>
                      {formatItemName(item.ItemName)}
                    </h4>
                    <p style={styles.comment}>{item.comment}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

const styles = {
  container: {
    padding: "16px",
    maxWidth: "800px",
    margin: "0 auto",
    fontFamily: "'Roboto', sans-serif",
    background: "#f5f5f5",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
  filterContainer: {
    display: "flex",
    gap: "8px",
    marginBottom: "20px",
  },
  filterButton: {
    padding: "10px 20px",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
    flex: 1,
    fontSize: "14px",
    transition: "background-color 0.3s, color 0.3s",
  },
  card: {
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
    marginBottom: "20px",
    overflow: "hidden",
    backgroundColor: "#fff",
    boxShadow: "0 2px 5px rgba(0,0,0,0.07)",
  },
  cardHeader: {
    padding: "16px",
    backgroundColor: "#f8f9fa",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  vehicleNumber: {
    margin: "0 0 8px 0",
    fontSize: "20px",
    color: "#333",
  },
  status: {
    margin: 0,
    fontSize: "14px",
    color: "#666",
  },
  statusSpan: (status) => ({
    color: status === "Okay" ? "#27ae60" : "#c0392b",
    fontWeight: "bold",
  }),
  expandIcon: {
    fontSize: "24px",
    color: "#7f8c8d",
  },
  cardContent: {
    padding: "16px",
    backgroundColor: "#fafafa",
  },
  checklistItem: {
    marginBottom: "16px",
    padding: "12px",
    backgroundColor: "#f8f9fa",
    borderRadius: "5px",
  },
  itemHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
  },
  category: {
    fontSize: "12px",
    color: "#7f8c8d",
    textTransform: "uppercase",
  },
  itemStatus: (status) => ({
    padding: "4px 8px",
    borderRadius: "4px",
    color: "#fff",
    fontSize: "12px",
    backgroundColor: status ? "#27ae60" : "#e74c3c",
  }),
  itemName: {
    margin: "0 0 8px 0",
    fontSize: "16px",
  },
  comment: {
    margin: 0,
    fontSize: "14px",
    color: "#7f8c8d",
  },
  selectedVehicleContainer: {
    padding: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
};

export default VehicleCard;
